import React from "react";
import Moment from "react-moment";
import {QRCodeSVG} from 'qrcode.react';

const TicketPrintableTop = ({
  event = {},
  data = {},
  attendee = {},
  style = {},
}) => {
  //# Org / Event
  const orgLogo = event?.card?.url || event?.organization?.logo?.url;
  const orgName = event?.organization?.libele;
  const eventTitle = event?.title;
  const hasQrCode = data?.showQrcode;

  //# Values
  let holder0 = attendee?.[data?.heading] || data.text || "";
  if (holder0 === "") holder0 = data.text;
  let holder1 = attendee?.[data?.subHeading1] || data.subText1 || "";
  if (holder1 === "") holder1 = data.subText1;
  let holder2 = attendee?.[data?.subHeading2] || data.subText2 || "";
  if (holder2 === "") holder2 = data.subText2;
  let holder3 =
    data.subHeading3 === "ticket-name" ? data?.title : data.subText3;
  // ...
  const ticketNumber =  String((attendee?.index+1)).padStart(7, '0')
  const hash = attendee?.hash;
  //console.log("hash: ", hash)
  return (
    <div className="TicketPrintableTop" style={{ ...style }}>
      <div className="__logo">
        <img src={orgLogo} alt="" />
      </div>
      <div className="__title">{eventTitle}</div>

      <div className="__details">
        <div className="__details__body">
          <div className="__info">
            <div className="__info__num">
              <span className="__label">Ticket </span>
              <span className="__value">#{ticketNumber}</span>
            </div>
            <div className="__info__name">{holder0 || ""}</div>
            <div className="__info__pos">{holder1 || ""}</div>
            <div className="__info__org">{holder2 || ""}</div>
          </div>
          <div className="__qr">
            {hasQrCode && <QRCodeSVG renderAs="svg" height={30} width={30} value={hash} />}
          </div>
        </div>
        <div className="__details__type">{holder3 || ""}</div>
      </div>

      <div className="__date">
        <Moment format="dddd, MMMM Do YYYY [at] HH:mm a">
          {event?.startDate}
        </Moment>
        <br />
        <Moment format="dddd, MMMM Do YYYY [at] HH:mm a">
          {event?.endDate}
        </Moment>
        {/* Monday , December 27th 2021 at 8:00 AM Friday 31st December 2021 at 3PM */}
      </div>
      <div className="__who">{event?.address || ""}</div>
    </div>
  );
};

export default TicketPrintableTop;
