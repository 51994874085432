import "./style.scss";

import { Button, Card, Grid } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ModalValidation from "../../../components/ModalValidate";
import { getDefaults } from "../../../utils/defaults";

const CardMediaStyle = styled("div")(() => ({
  display: "flex",
  position: "relative",
  justifyContent: "center",
  paddingTop: "160px",
}));
const CoverImgStyle = styled("img")({
  top: 0,
  zIndex: 9,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const StageCard = ({ isLoading, data = {}, onDelete }) => {
  const history = useHistory();
  const [delModal, setDelModal] = useState(false);
  // ...
  const dSrc = getDefaults()?.stageCard?.url;
  const bannerSrc = data?.card?.url || dSrc;
  //...
  const onEditStage = () => {
    history.push(`/stages-schedules/${data?.id}`);
  };
  // ...
  const onDeleteStage = async () => {
    await onDelete(data?.id);
    setDelModal(false);
  };
  // ...
  return (
    <Card
      className="StageCard"
      sx={{ width: { md: "252px", xs: "100%", borderRadius: "7px" } }}
    >
      <CardMediaStyle>
        <CoverImgStyle alt="cover" src={bannerSrc} />
      </CardMediaStyle>
      <p className="StageCard__label">{data.label || "Unnamed stage"}</p>
      <Grid
        container
        className="StageCard__btns"
        sx={{
          py: 3,
          display: "flex",
          flexDirection: { md: "row", xs: "column-reverse" },
          justifyContent: "flex-end",
          padding: "15px",
        }}
      >
        <div>
          <Button
            className="__btn __del"
            variant="contained"
            onClick={() => setDelModal(true)}
          >
            <span style={{ fontFamily: "Poppins" }}>Delete</span>
          </Button>
          <Button
            className="__btn __edit"
            variant="contained"
            onClick={() => onEditStage()}
          >
            <span style={{ fontFamily: "Poppins" }}>Update</span>
          </Button>
        </div>

        <div>
          {/* <Button
            className="__btn __studio"
            variant="contained"
            onClick={() => setDelModal(true)}
          >
            <Box component="img" alt="studio" src="/static/icons/studio.svg" />
          </Button> */}
        </div>
      </Grid>

      <ModalValidation
        open={delModal}
        title="Delete virtual space"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteStage(),
        }}
        no={{ label: "cancel", handler: () => setDelModal(false) }}
      >
        Are you sure to delete {data?.label || "virtual space"}?
      </ModalValidation>
    </Card>
  );
};

export default StageCard;
