import "./style.scss";

import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { Icon } from "@iconify/react";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@material-ui/core";
// ...
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Logo from "../components/Logo";
import useEvent from "../hooks/useEvent";
// ...
import AccountPopover from "./AccountPopover";

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const AppNav = styled(AppBar)(({ theme }) => ({
  position: "relative",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  boxShadow: "0px 0.5px 0px #c4c5c7",
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const LogoStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  minHeight: APPBAR_MOBILE,
  boxShadow: "0px 0.5px 0px #c4c5c7",
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    // padding: theme.spacing(0, 5),
  },
}));

const StudioNav = ({ onNavHandler }) => {
  return (
    <Tooltip title="Go Live" arrow>
      <Box
        onClick={onNavHandler}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "52px",
          width: "52px",
          backgroundColor: "#E6273E",
          borderRadius: "8px",
          margin: "0 30px 0 0",
          cursor: "pointer",
        }}
      >
        <Box component="img" alt="studio" src="/static/icons/studio.svg" />
      </Box>
    </Tooltip>
  );
};

const DashboardNavbar = ({ onOpenSidebar }) => {
  const { data } = useEvent();
  // ...
  const onSwitchToStudio = () => {
    const THE_ID = data?.id;
    const THE_ORG = data?.organization?.id;
    const THE_JWT = window.localStorage.getItem("THE_JWT");
    const THE_URI = process.env.REACT_APP_BACK_URL;
    // ...
    const link = `${THE_URI}/?token=${THE_JWT}&eventId=${THE_ID}&orgId=${THE_ORG}`;
    window.open(link, "backstage");
  };
  // ...
  return (
    <AppNav className="DashboardNavbar">
      <ToolbarStyle>
        <Hidden lgUp>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        <LogoStyle>
          <RouterLink to="/" style={{ textDecoration: "none" }}>
            <Logo isFull />
          </RouterLink>
        </LogoStyle>

        <Box sx={{ flexGrow: 1 }} />

        <StudioNav onNavHandler={onSwitchToStudio} />

        <AccountPopover
          orgLabel={data?.organization?.libele}
          eventName={data?.title}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "44px",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                width: "44px",
                height: "44px",
                overflow: "hidden",
                // margin: "0 20px 0 0",
              }}
            >
              {data?.organization?.logo?.url && (
                <Box
                  component="img"
                  src={data.organization.logo.url}
                  alt="Event Banner"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "22px",
                color: "#363636",
                padding: "0 0 0 10px",
              }}
            >
              {data?.organization?.libele}
            </Box>
          </Box>
        </AccountPopover>
      </ToolbarStyle>
    </AppNav>
  );
};

export default DashboardNavbar;
