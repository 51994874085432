import "./style.scss";

import { Button } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import React from "react";

import LoadingDots from "../LoadingDots";

const ModalValidation = ({
  open,
  title = "",
  isLoading,
  // ...
  onClose,
  yes = {},
  no = {},
  children,
  style = {}
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="ModalValidation"
      // ...
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="ModalValidation__modal" style={{ ...style }}>
          <div className="ModalValidation__header">
            <h2 className="__title">{title}</h2>
          </div>
          <div className="ModalValidation__body">
            <p className="ModalValidation__content">{children}</p>
          </div>
          <div className="ModalValidation__footer">
            <Button className="__no-btn" isLoading={no?.isLoading} onClick={() => no?.handler()}>
              {no?.label}
            </Button>
            <Button className="__yes-btn" isLoading={yes?.isLoading} onClick={() => yes?.handler()}>
              {isLoading ? <LoadingDots color="white" /> : yes?.label}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalValidation;
