import { Grid } from "@material-ui/core";
import cx from "classnames";
import { useField } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import LocationPicker from "react-leaflet-location-picker";

const defaultLocation = { lat: 36.8242, lng: 10.1785 };
const zoom = 10;
let userLocation;
navigator.geolocation.getCurrentPosition((position) => {
  let lat = position.coords.latitude;
  let lng = position.coords.longitude;
  userLocation = {lat, lng};
});

const EntryMap = ({
  topLabel,
  name,
  // ...
  xs = 12,
  md = 6,
  // ...
}) => {
  const [field, meta, helpers] = useField({ name });
  /* const [location, setLocation] = useState(null);
  const [startPort, setStartPort] = useState("auto"); */
  // ...
  let location, startPort="auto";
  if(field?.value?.lat && field?.value?.lng){
    startPort = {
      center: [field.value.lat, field.value.lng],
      zoom
    };
    location=field?.value;
  }else{
    const initalLocation = userLocation || defaultLocation;
    startPort = {
      center: [initalLocation.lat, initalLocation.lng],
      zoom
    };
    location = initalLocation;
  }
  // ...
  const pointMode = {
    banner: true,
    control: {
      values: (location?.lat && location?.lng) ? [[location?.lat, location?.lng]] : [],
      onClick: point => {
        //setLocation(l => ({...l, lat:point[0], lng:point[1]}));
        helpers.setValue({...location, lat:point[0], lng:point[1]});
        console.log("I've just been clicked on the map!", point)
      },
      onRemove: point => {
        helpers.setValue({});
        console.log("I've just been clicked for removal :(", point);
      }
    }
  };
  // ...
  /* useMemo(() => {
    console.log("field location :", field)
    if(field?.value?.lat && field?.value?.lng){
      setStartPort( {
        center: [field.value.lat, field.value.lng],
        zoom
      });
      setLocation(field?.value);
    }else{
      const initalLocation = userLocation || defaultLocation;
      setStartPort( {
        center: [initalLocation.lat, initalLocation.lng],
        zoom
      });
      setLocation(initalLocation);
    }
    
  }, [field]); */
  //...
  return (
    <Grid item xs={xs} md={md}>
      {topLabel && (
        <label
          style={{
            display: "block",
            color: "#333",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13px",
            lineHeight: "20px",
            fontWeight: "500",
            margin: "0 0 5px 0"
          }}>
          {topLabel}
        </label>
      )}
          {!!location && <LocationPicker bindMap={false} startPort={startPort} pointMode={pointMode}  showInputs={false}/>}

    </Grid>
  );
};

export default EntryMap;
