import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { atom, useRecoilCallback, useRecoilStateLoadable, useRecoilValue } from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "ATTENDEES", default: [] });
const ATOM_PAGE = atom({ key: "ATTENDEES_PAGE", default: 0 });
const ATOM_COUNT = atom({ key: "ATTENDEES_COUNT", default: 0 });
const ATOM_PAGE_SIZE = atom({ key: "ATTENDEES_PAGE_SIZE", default: 10 });
const ATOM_SEARCH_TEXT = atom({ key: "ATTENDEES_SEARCH_TEXT", default: "" });
const ATOM_SORT = atom({ key: "ATTENDEES_SORT", default: "id:DESC" });

export default function useAttendees() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const [page, setPage] = useState(useRecoilValue(ATOM_PAGE));
  const [count, setCount] = useState(useRecoilValue(ATOM_COUNT));
  const [sort, setSort] = useState(useRecoilValue(ATOM_SORT));
  const [pageSize, setPageSize] = useState(useRecoilValue(ATOM_PAGE_SIZE));
  const [searchText, setSearchText] = useState(useRecoilValue(ATOM_SEARCH_TEXT));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async (pageForced, pageSizeForced, textForced, sortForced) => {
    try {
      const limit = (pageSizeForced||pageSize);
      const p = (pageForced==0 ? 0 : (pageForced||page))
      const start = p * limit;
      const text  = textForced || searchText;
      const s = sort || sortForced;
      const attendees = await HTTP.GET(`/organizations/events/${THE_ID}/getAttendees?start=${start}&limit=${limit}&sort=${s}&search=${text}`);
      // ...
      setAtom(attendees?.participants || []);
      setCount(attendees?.count || 0);
      setPageSize(limit);
      setPage(p);
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onUpdate = useRecoilCallback(() => async (raw) => {
    setIsLoading(true);
    setHasError(false);
    // ...
    const attendees = raw.map((a) => {
      let att = { ...a };
      if (a.id) att.id = a.id;
      // ...
      return att;
    });
    // ...
    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/createInvitations`, {
        invitations: attendees
      });
      // ...
      enqueueSnackbar("Attendee added", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  const onRemove = useRecoilCallback(() => async (ids = []) => {
    setIsLoading(true);
    setHasError(false);

    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/deleteInvitations`, {
        ids
      });
      // ...
      enqueueSnackbar("Attendee deleted", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  // ...
  const onUpdatePageSize = (size) => {
    //console.log("onUpdatePageSize: ", size);
    setPageSize(size);
    setPage(0)
    getAll(0, size);
  }
  const onUpdatePage = (p) => {
    //console.log("onUpdatePage: ", p);
    setPage(p);
    getAll(p, pageSize);
  }
  const onUpdateSort = (s) => {
    //console.log("onUpdatePage: ", p);
    setSort(s);
    getAll(page, pageSize, searchText, s);
  }
  const onUpdateSearchText = (text) => {
    //console.log("onUpdatePageSize: ", size);
    setSearchText(text);
    //setPage(0)
    //getAll(0, pageSize, text);
  }
  // ...
  useEffect(() => {
    //if (!searchText) return;
    //console.log("searchText change: ", searchText);
    const timeOutId = setTimeout(() => {      
      //console.log("exec setTimeout : ", searchText);
      setPage(0);
      getAll(0, pageSize, searchText);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchText]);
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    count,
    page,
    pageSize,
    searchText,
    onUpdate,
    onRemove,
    onUpdatePage,
    onUpdatePageSize,
    onUpdateSearchText,
    onUpdateSort
  };
}
