import "./style.scss";

import { Box } from "@material-ui/core";
import React from "react";

const EventSectionSubTitle = ({ title, subTitle, children, sx }) => (
  <Box className="EventSectionSubTitle" sx={{ ...sx }}>
    <Box className="__content">
      <Box className="__content__title">{title}</Box>
      <Box className="__content__sub">{subTitle}</Box>
    </Box>
    <Box className="__extra">{children}</Box>
  </Box>
);

export default EventSectionSubTitle;
