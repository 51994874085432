import { FormControl, FormHelperText, Grid, InputLabel, Switch } from "@material-ui/core";
import cx from "classnames";
import { useField } from "formik";
import React, { useRef } from "react";
import { useState } from "react";
import StyleEditor from "react-style-editor/lib/components/StyleEditor";
import BaseMarkdown from "../BaseMarkdown";

const EntryStyleEditor = ({
  required,
  maxLength,
  // ...
  label,
  name,
  placeholder,
  // ...
  topLabel,
  // ...
  error,
  // ...
  sx = {},
  // ...
  xs = 12,
  md = 6,
  // ...
  isEmbedCode,
  disabled = false,
  heightStatic = "__height3"
}) => {
  const [field, meta, helpers] = useField({ name });
  // ...
  let helperText = meta.touched ? meta.error : "";
  const maxLn = maxLength ? 500 : 50;
  const hasError = meta.touched && (!!meta.error || error);
  // ...
  if (error) {
    helperText = error;
  }
  // ...
  // ...
  return (
    <Grid item xs={xs} md={md} sx={{ ...sx }}>
      {topLabel && (
        <label
          style={{
            display: "block",
            color: "#333",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13px",
            lineHeight: "20px",
            fontWeight: "500",
            margin: "0 0 5px 0"
          }}>
          {topLabel}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
      )}

      <FormControl className={cx("EntryEditor", { __embeded: isEmbedCode, ...({[heightStatic]: heightStatic}) })} variant="outlined" margin="dense" fullWidth>
        
        <StyleEditor 
            value={field?.value||""} 
            onChange={helpers.setValue}
            readOnly={disabled}
            style={{maxLength: "800px"}}
          />
        <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default EntryStyleEditor;
