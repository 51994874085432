import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import xlsExport from "xlsexport";

import Scrollbar from "../Scrollbar";
import TableAttendeesCellActions from "./TableAttendeesCellActions";
import TableHeader from "./TableHeader";
import TableToolbar from "./TableToolbar";
import _ from "underscore";

const sortMap = {
  inviteDateLabel: "created_at",
  statusLabel: "registrationStatus",
  invLabel: "invitationStatus",
  remLabel: "reminderStatus",
  null: "email",
  undefined: "email"
}

const sortableProperties = ["email", "inviteDateLabel", "statusLabel", "invLabel", "remLabel"];

const EventEntityTableAttendees = ({
  label = "",
  head,
  data,
  count,
  page,
  pageSize,
  searchText,
  onUpdatePage,
  onUpdatePageSize,
  onUpdateSearchText,
  onUpdateSort,
  // ...
  searchLabel,
  exportName,
  exportHead,
  // ...
  noEdit,
  // ...
  onResend,
  onEditHandler,
  onDeleteHandler,
  disabledOptions = false
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("email");
  const [selected, setSelected] = useState([]);
  // ...
  const isSelected = (id) => selected.indexOf(id) !== -1;
  // ...
  const onResendHanlder = async (row) => {
    const speakerId = row?.id;
    const sessionIds = row?.sessionsBase || [];
    // ...
    if (!!speakerId && sessionIds.length > 0) {
      await onResend({ speakerId, sessionIds });
    }
  };
  // ...
  const handleRequestSort = (event, property) => {
    //console.log("property: ", property)
    if(!sortableProperties.includes(property)) return;
    const isAsc = orderBy === property && order === "asc";
    const o = isAsc ? "DESC" : "ASC"
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    onUpdateSort(`${sortMap[property]||property}:${o}`);
  };
  // ...
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  // ...
  const onExportXlsx = () => {
    if (!exportName) return;
    // ...
    let customHead = [];
    const newData = [];
    data.map((d) => {
      customHead = [...customHead, ...Object.keys(d.custom || {})];
      customHead = _.uniq(customHead);
      const newCustom = {...d.custom};
      //console.log("newCustom : ", newCustom);
      const newD = {...d};
      delete newD.custom;
      newData.push({...newCustom, ...newD })
    });

    //console.log("newData export : ", newData);
    const exportData = newData.map((d) => {
      let out = {};
      [...exportHead, ...customHead].forEach((n) => (out[n] = d[n]));
      // ...
      return out;
    });
    // ...
    const xls = new xlsExport(exportData, exportName);
    xls.exportToXLS("export.xls");
  };
  // ...
  const emptyRows = !count ;
  // ...
  return (
    <>
      <Scrollbar>
        <TableToolbar
          searchFor={searchText}
          setSearchFor={onUpdateSearchText}
          // ...
          hasExport={!!exportName}
          exportHandler={onExportXlsx}
          children={<>Total: {count}</> }
        />
        <TableContainer sx={{ minWidth: 800 }}>
          <Table size="medium">
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={head}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {data.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // ...
                  return (
                    <TableRow hover tabIndex={-1} key={row.id} selected={isItemSelected}>
                      {head
                        .map((i) => i.id)
                        .map((d) => (
                          <TableCell key={d} id={labelId} component="th" scope="row" padding="none">
                            {row[d]}
                          </TableCell>
                        ))}

                      <TableAttendeesCellActions
                        idx={index}
                        data={row}
                        label={label}
                        // ...
                        disabledOptions={disabledOptions}
                        // ...
                        onEditHandler={onEditHandler}
                        onDeleteHandler={onDeleteHandler}
                        onResendHanlder={() => onResendHanlder(row)}
                      />
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(e, newPage) => onUpdatePage(newPage)}
          onRowsPerPageChange={(e) => {
            onUpdatePageSize(parseInt(e.target.value, 10));
          }}
        />
      </Box>
    </>
  );
};

export default EventEntityTableAttendees;
