import "react-markdown-editor-lite/lib/index.css";

import MarkdownIt from "markdown-it";
import React from "react";
import MdEditor, { Plugins } from "react-markdown-editor-lite";

MdEditor.unuse(Plugins.Clear);

const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
});

const BaseMarkdown = ({
  name,
  placeholder,
  chars,
  value,
  onChange,
  // ...
  readOnly,
  isEmbedCode
}) => {
  const onChangeHandler = ({ html, text }) => {
    onChange(text);
  };
  // ...
  const renderHTML = (text) => {
    return mdParser.render(text);
  };
  // ...
  return (
    <MdEditor
      className="BaseMarkdown"
      // ...
      name={name}
      placeholder={placeholder}
      value={value || ""}
      onChange={onChangeHandler}
      readOnly={readOnly}
      // ...
      isFullScreen={true}
      view={{ menu: !readOnly, md: true, html: false }}
      // ...
      renderHTML={renderHTML}
    />
  );
};

export default BaseMarkdown;
