import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { atom, useRecoilCallback, useRecoilStateLoadable, useRecoilValue } from "recoil";

import HTTP from "../api/http";
import { sanatizeEditorData } from "../utils/editor";
import useId from "./useId";

const ATOM = atom({ key: "STAGE_SLOTS", default: [] });

const getSlotData = (s) => ({
  slug: s.slug,
  title: s.title,
  description: sanatizeEditorData(s.description),
  embedCode: sanatizeEditorData(s.embedCode),
  start: s.start,
  duration: s.duration,
  card: s.card,
  cover: s.cover,
  streamConf: s.streamConf,
  // ...
  speakers: s?.speakers.map((si) => ({
    firstName: si.firstName,
    lastName: si.lastName,
    description: si.description,
    company: si.company,
    email: si.email,
    image: si.image,
    isModerator: si.isModerator,
    linkedin: si.linkedin,
    phone: si.phone,
    position: si.position,
    sendEmailNotification: si.sendEmailNotification,
    status: si.status,
    user: si.user
  })),
  sponsors: s?.sponsors?.map((si) => si?.value) || [],
  // ...
  disableVirtualSpace: !!s.disableVirtualSpace,
  enableMobileNotification: !!s.enableMobileNotification
});

export default function useSlots(stageId) {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ..
  const getAll = async () => {
    try {
      const slots = await HTTP.GET(`/organizations/events/${THE_ID}/getStageSessions/${stageId}`);
      // ...
      setAtom([...slots]);
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Unable to fetch sessions", { variant: "error" });
    }
  };
  // ...
  const onUpdateDates = useRecoilCallback(() => async (slotsList) => {
    try {
      setIsLoading(true);
      setHasError(false);
      // # Save slot
      for (const slot of slotsList) {
        await HTTP.POST(`/organizations/events/${THE_ID}/updateSession`, {
          ...slot,
          stage: parseInt(stageId)
        });
      }
      // ...
      enqueueSnackbar("Sessions updated", { variant: "success" });
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Unable to update sessions", { variant: "error" });
    }
  });
  // ...
  const onUpdate = useRecoilCallback(() => async (raw) => {
    setIsLoading(true);
    setHasError(false);

    // # Save slot
    let slot = getSlotData(raw);
    if (raw.id) slot.id = raw.id;
    // ...
    if (raw?.sponsors?.length > 0) slot.sponsors = raw.sponsors.map((s) => s.value);

    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/updateSession`, {
        ...slot,
        stage: parseInt(stageId)
      });
      // ...
      enqueueSnackbar(raw.id ? "Session updated" : "Session added", {
        variant: "success"
      });
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar(raw.id ? "Unable to update session" : "Unable to add session", {
        variant: "error"
      });
    }
  });
  // ...
  const onRemove = useRecoilCallback(() => async (slot) => {
    setIsLoading(true);
    setHasError(false);

    try {
      // # Save slot
      await HTTP.POST(`/organizations/events/${THE_ID}/deleteSessions`, {
        stage: parseInt(stageId),
        ids: [slot.id]
      });
      // ...
      enqueueSnackbar("Session deleted", { variant: "success" });
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Unable to delete session", { variant: "error" });
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onUpdate,
    onRemove,
    onUpdateDates
  };
}
