import { Box, Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import EventEmptyView from "../../components/EventEmptyView";
import EventSectionTitle from "../../components/EventSectionTitle";
import Page from "../../components/Page";
import useEvent from "../../hooks/useEvent";

import HTTP from "../../api/http";
import _ from "lodash";
import EventPrediction from "../../components/EventPrediction";

const EventLive = () => {
  const { data } = useEvent();
  const [reportLink, setReportLink] = useState(null);
  const [participantsNumberMin, setParticipantsNumberMin] = useState(null);
  const [participantsNumberMax, setParticipantsNumberMax] = useState(null);
  const isPubEvent = data?.published;
  const demoDashLink = process.env.REACT_APP_REPORT_BASE_LINK || "";
  // ...
  useEffect(() => {
    if (data?.id && data?.slug && data?.organization?.slug) {
      setReportLink(
        demoDashLink
          .replace("___org_slug___", data?.organization?.slug)
          .replace("___event_slug___", data?.slug)
          .replace("___event_hash___", data?.hash)
      );
    }
  }, [data]);
  useEffect(() => {
    if(!data || !data.id) return;
    const doPrediction = async () => {
      //console.log("call prediction . . .");
      const prediction = await HTTP.GET(`/organizations/events/${data.id}/predictParticipants`);
      //console.log("prediction: ", prediction);
      if(prediction){
        const min = prediction.lower_bound;        
        const max = prediction.upper_bound; 
        setParticipantsNumberMin(Math.round(min));
        setParticipantsNumberMax(Math.round(max));
      }
    }
    try {
      doPrediction();
    } catch (error) {
      console.error("doPrediction error : ", error);
    }
    
  }, [data.id]);
  return (
    <Page title="Live insights">
      <Container>
        <Box>
          <EventSectionTitle
            title="Live insights"
            subTitle="Explore real time event analytics"
            src="/static/setup/title/dashboard.svg"
          />

          {!isPubEvent && (
            <EventEmptyView
              src="/static/setup/empty/dashboard.svg"
              title="Get realtime event insights"
              desc="Publish your event to get  real-time insights"
            />
          )}

          {isPubEvent && reportLink && (
            <>
              
              <EventPrediction event={data||{}}/>
              <Box>
                <iframe
                  display="initial"
                  src={reportLink}
                  frameBorder="0"
                  style={{ border: "none", width: "100%", height: "2000px" }}
                />
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default EventLive;
