import { Autocomplete, Box, Button, Checkbox, Chip, Grid, MenuItem, TextField } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useField } from "formik";
import React, { useRef } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EntryAutocompleteCheckbox = ({
  required,
  label,
  name,
  placeholder,
  helper,
  // ...
  freeSolo,
  options = [],
  disabled,
  // ...
  sx = {},
  // ...
  xs = 12,
  md = 6
}) => {
  const [field, meta, helpers] = useField({ name });
  const inpRef = useRef();
  // ...
  const iVals = field?.value;
  const ini = Array.isArray(iVals) ? { values: [...iVals] } : iVals || { values: [] };
  const values = ini?.values || [];
  // ...
  const handleChange = (e, values) => {
    const tmp = values.map((v) => {
      return typeof v === "string" ? { label: v, value: v } : { ...v };
    });
    // ...
    helpers.setValue({ values: tmp });
  };
  // ...
  const onAddByCheckbox = (value) => {
    const ivs = iVals?.values || [];
    helpers.setValue({
      values: [...ivs, { label: value, value }]
    });
  };
  // ...
  const onAddFreeTag = () => {
    const value = inpRef?.current.value;
    // ...
    if (!value) return;
    // ...
    const ivs = iVals?.values || [];
    helpers.setValue({
      values: [...ivs, { label: value, value }]
    });
  };
  // ...
  const handleDelete = (opt) => {
    const tmp = values.filter((v) => v.value !== opt.value) || [];
    helpers.setValue({ values: tmp });
  };
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <Box className="EntryAutocomplete __with-checkbox">
        {!!helper && (
          <div className="EntryAutocomplete__helper">
            <span>{helper}</span>
            {required && <span style={{ color: "red" }}> *</span>}
          </div>
        )}
        <Autocomplete
          multiple
          // filterSelectedOptions
          freeSolo={freeSolo}
          sx={{ ...sx }}
          // ...
          name={name}
          value={values}
          onChange={handleChange}
          onBlur={field.onBlur}
          options={options}
          disabled={disabled}
          // ...
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option) => {
            const _exist = values?.filter((opt) => opt.value === option.value);
            return !!_exist.length;
          }}
          // ...
          renderTags={(value) => {
            return value?.map((option, idx) => <div key={idx} />);
          }}
          renderOption={(option, { label, value }) => {
            const isSelected = (values || []).map((o) => o.value).indexOf(value) > -1;
            // ...
            return (
              <MenuItem
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  if (!isSelected) {
                    onAddByCheckbox(value);
                  } else {
                    handleDelete({ label, value });
                  }
                }}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelected} style={{ padding: "0", margin: "0 10px 0 0" }} />
                {label}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label={label}
                name={name}
                placeholder={placeholder}
                // ...
                inputRef={inpRef}
              />

              {!disabled && <Box className="__action">
                <Button className="__btn" onClick={onAddFreeTag} >
                  Add
                </Button>
              </Box>}
            </>
          )}
        />
        <Box className="EntryAutocomplete__chips">
          {values?.map((option, idx) => (
            <Chip key={idx} label={option.label} onDelete={() => handleDelete(option)} />
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default EntryAutocompleteCheckbox;
