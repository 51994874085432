import { experimentalStyled as styled } from "@material-ui/core/styles";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import _ from "underscore";

import useEmails from "../../hooks/useEmails";
import { EntryDate, EntrySelect, EntryTime } from "../Entry";
import EventButton from "../EventButton";
import EventForm from "../EventForm";
import { validationSchema } from "./entries";
const iniValues = validationSchema.cast();

const LabelStyle = styled("div")(() => ({
  color: "#333",
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "400",
  margin: "60px 0 30px 0"
}));

const AddEmailSettings = ({ open, onClose, ...props }) => {
  const [currEmail, setCurrEmail] = useState({});
  // ...
  const { isLoading, data: emails, onUpdate } = useEmails();
  const inviteTemplateList = emails
    ?.filter((email) => email.category === "invitation")
    ?.map((email) => ({
      label: email.subject,
      value: email.id
    }));
  // ...
  const onSetEmailSettings = async (data) => {
    const inviteTemplateListActive = emails?.filter((email) => email.category === "invitation" && email.active);
    if(inviteTemplateListActive?.length){
      await onUpdate({ ...inviteTemplateListActive[0], active: false });
    }
    await onUpdate({ ...currEmail, ...data, active: true });
    // ...
    setCurrEmail({});
    onClose();
  };
  // ...
  useEffect(() => { //console.log("props?.values: ", props?.values)
    const curIdx = _.indexOf(_.pluck(emails, "id"), props?.values?.id);
    if (!props?.values?.id || curIdx === -1) return;
    // ...
    const currEmail = emails[curIdx];
    setCurrEmail(currEmail);
    // ...
    props.resetForm({
      values: {
        id: props?.values?.id,
        plannedAt: currEmail?.plannedAt || null,
        reminder: currEmail?.reminder || null
      }
    });
  }, [props?.values?.id]);
  // ...
  useEffect(() => { //console.log("open: ", open)
    if (open) {
      const inviteTemplateListActive = emails?.filter((email) => email.category === "invitation" && email.active);
      if(inviteTemplateListActive?.length){
        props.resetForm({
          values: {
            id: inviteTemplateListActive[0].id,
            plannedAt: inviteTemplateListActive[0].plannedAt || null,
            reminder: inviteTemplateListActive[0].reminder || null
          }
        });
      }
    }else{
      props.resetForm({
        values: { id: 0, plannedAt: null, reminder: null }
      });
    };
    // ...
    
  }, [open]);
  // ...
  return (
    <>
      <LabelStyle>Choose an email template:</LabelStyle>
      <EventForm>
        <EntrySelect label="Email template" name="id" onChange={props.handleChange} options={inviteTemplateList} xs={12} md={12} />
      </EventForm>

      <LabelStyle>Send invitation on:</LabelStyle>
      <EventForm>
        <EntryDate label="Invitation Date" name="plannedAt" disabled={!props?.values?.id} minDate={new Date()}/>
        <EntryTime label="Invitation Time" name="plannedAt" disabled={!props?.values?.id} />
      </EventForm>

      <LabelStyle>Send Reminders on:</LabelStyle>
      <EventForm>
        <EntryDate label="Reminder Date" name="reminder" disabled={!props?.values?.id} minDate={new Date()}/>
        <EntryTime label="Reminder Time" name="reminder" disabled={!props?.values?.id} />
      </EventForm>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "50px 0 0 0"
        }}>
        <EventButton
          isLoading={isLoading}
          disabled={!props?.values?.id || !props?.values?.plannedAt || !props?.values?.reminder}
          handler={() => onSetEmailSettings(props.values)}>
          Save
        </EventButton>
      </div>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(AddEmailSettings);
