import * as yup from "yup";

export const validationSchema = yup.object({
  firstName: yup.string().required().default(""),
  lastName: yup.string().required().default(""),
  description: yup.string().notRequired().nullable().default(""),
  position: yup.string().notRequired().nullable().default(""),
  company: yup.string().notRequired().nullable().default(""),
  email: yup.string().email("Must be a valid email").required().default(""),
  phoneNumber: yup.string().notRequired().nullable().default(""),
  linkedin: yup.string().notRequired().nullable().default(""),
  facebook: yup.string().notRequired().nullable().default(""),
  instagram: yup.string().notRequired().nullable().default(""),
  // ...
  isModerator: yup.boolean().notRequired().nullable().default(false),
  sendEmailNotification: yup.boolean().notRequired().nullable().default(false),
  // ...
  image: yup.object().required().nullable().default(null)
});
