import "./style.scss";

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import EventButton from "../../components/EventButton";
import Logo from "../../components/Logo";
import Page from "../../components/Page";
import useEvent from "../../hooks/useEvent";
import useTicket from "../../hooks/useTicket";
// ...
// import PreviewBottom from "./components/Bottom";
import PreviewTop from "./components/Top";

const EventTicketPreview = ({ bTicketId }) => {
  const { id: uTicketId } = useParams();
  const ticketId = uTicketId || bTicketId;
  // ...
  const { isLoading: isLE, data: event } = useEvent();
  const { isLoading: isLT, data } = useTicket(ticketId);
  const isLoading = isLE || isLT;
  // ...
  const contentRef = useRef(null);
  const [size, setSize] = useState(null);
  let sizeStyle = {};
  if (size) {
    sizeStyle = {
      width: size.width,
      minWidth: size.width,
      mawWidth: size.width,
      height: size.height,
      minHeight: size.height,
      maxHeight: size.height
    };
  }
  // ...
  const onExitPreview = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };
  // ...
  useEffect(() => {
    if (isLoading || !contentRef?.current) return;
    // ...
    const width = contentRef?.current?.clientWidth;
    const height = width * 1.4142;
    // ...
    if (width > 0 && height > 0) setSize({ width, height });
  }, [isLoading, contentRef]);
  // ...
  return (
    <Page title="Ticketing">
      <div className="EventTicketPreview">
        <div className="EventTicketPreview__header">
          <p className="EventTicketPreview__header__logo">
            <Logo isFull />
          </p>
          <EventButton handler={() => onExitPreview()} sx={{ backgroundColor: "red" }}>
            Exit Preview
          </EventButton>
        </div>

        {!isLoading && (
          <div ref={contentRef} className="EventTicketPreview__content">
            <div
              className="__inner"
              style={{
                ...sizeStyle,
                color: data?.textColor,
                backgroundColor: data?.backgroundColor,
                backgroundImage: `url(${data?.backgroundImage?.url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat"
              }}>
              <div className="__group __top">
                <PreviewTop event={event} data={data} style={{}} />
                <PreviewTop event={event} data={data} style={{}} />
              </div>

              {/* <div className="__group __bottom">
              <PreviewBottom event={event} data={data} style={{}} />
              <PreviewBottom event={event} data={data} style={{}} />
            </div> */}
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default EventTicketPreview;
