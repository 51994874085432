import { Grid, MenuItem, TextField } from "@material-ui/core";
import { useField } from "formik";
import React from "react";

const EntrySelect = ({
  label,
  name,
  required,
  disabled,
  // ...
  options = [],
  // ...
  topLabel,
  // ...
  xs = 12,
  md = 6
}) => {
  const [field, meta] = useField({ name });
  // ...
  return (
    <Grid item xs={xs} md={md}>
      {topLabel && (
        <label
          className="topLabel"
          style={{
            display: "block",
            color: "#333",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13px",
            lineHeight: "20px",
            fontWeight: "500",
            margin: "0 0 18px 0"
          }}>
          {topLabel}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
      )}
      <TextField
        className="Entry"
        select
        fullWidth
        // ...
        label={label}
        name={name}
        value={field?.value || ""}
        onChange={field.onChange}
        onBlur={field.onBlur}
        disabled={disabled}
        // ...
        error={meta.touched && !!meta.error}
        helperText={meta.touched ? meta.error : ""}>
        {options.map((opt, idx) => (
          <MenuItem key={idx} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default EntrySelect;
