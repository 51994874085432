export const getRawImg = (file) => {
  return new Promise((resolve /*, reject*/) => {
    const name = file?.name?.replace(/ /g, "");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // ...
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      // ...
      img.onload = function () {
        resolve({
          fileInfo: {
            name,
            alternativeText: "",
            caption: "",
          },
          base64: reader.result,
          naturalWidth: img.naturalWidth,
          naturalHeight: img.naturalHeight,
        });
      };
    };

    // reader.onerror = (error) => reject(error);
  });
};

export const getRawFile = (file) => {
  return new Promise((resolve /*, reject*/) => {
    const name = file?.name?.replace(/ /g, "");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // ...
    reader.onload = () => {
      resolve({
        fileInfo: {
          name,
          alternativeText: "",
          caption: "",
        },
        base64: reader.result,
      });
    };

    // reader.onerror = (error) => reject(error);
  });
};
