import { Autocomplete, Box, Button, Chip, Grid, MenuItem, TextField } from "@material-ui/core";
import { useField } from "formik";
import React, { useRef } from "react";
import _ from "underscore";
import { EVENT_TAGS } from "../../../views/EventSetup/entries";
const tagsIndex =  _.mapObject(_.indexBy(EVENT_TAGS, "value"), (val, key) => val.label);

const EntryAutocompleteTags = ({
  label,
  name,
  placeholder,
  helper,
  // ...
  freeSolo,
  options = [],
  disabled,
  // ...
  sx = {},
  // ...
  xs = 12,
  md = 6
}) => {
  const [field, meta, helpers] = useField({ name });
  const inpRef = useRef();
  // ...
  const iVals = field?.value;
  const values = Array.isArray(iVals) ? [...iVals] :  [] ;
  // ...
  const handleChange = (e) => {
    //console.log("handleChange values: ", values)
    
    //console.log("handleChange e: ", e)
    //console.log("handleChange e.target.value: ", e.target.value)
    // ...
    helpers.setValue([...values, e.target.value]);
  };
  // ...
  const onAddByCheckbox = (value) => {
    //console.log("onAddByCheckbox value: ", value)
    //console.log("onAddByCheckbox iVals: ", iVals)
    helpers.setValue([...values, value ]);
  };
  // ...
  const onAddFreeTag = () => {
    //console.log("onAddFreeTag inpRef?.current.value: ", inpRef?.current.value)
    const value = inpRef?.current.value;
    // ...
    if (!value) return;
    // ...
    //console.log("iVals: ", iVals)
    helpers.setValue([...iVals, value ]);
  };
  // ...
  const handleDelete = (opt) => {
    //console.log("handleDelete opt: ", opt)
    const tmp = values.filter((v) => v !== opt) || [];
    helpers.setValue( tmp );
  };
  // ...
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <Box className="EntryAutocomplete">
        {!!helper && <div className="EntryAutocomplete__helper">{helper}</div>}
        <Autocomplete
          multiple
          filterSelectedOptions
          freeSolo={freeSolo}
          sx={{ ...sx }}
          // ...
          name={name}
          value={values}
          onChange={handleChange}
          onBlur={field.onBlur}
          options={options}
          disabled={disabled}
          // ...
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option) => {
            const _exist = values?.filter((opt) => opt === option.value);
            return !!_exist.length;
          }}
          // ...
          renderTags={(value) => {
            return value?.map((option, idx) => <div key={idx} />);
          }}
          renderOption={(option, { label, value }) => {
            const isSelected = (values || []).map((o) => o.value).indexOf(value) > -1;
            // ...
            return (
              <MenuItem
                key={`idx-iitem-${value}`}
                onClick={() => {
                  if (!isSelected) {
                    onAddByCheckbox(value);
                  }
                }}>
                {label}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label={label}
                name={name}
                placeholder={placeholder}
                // ...
                inputRef={inpRef}
              />

              <Box className="__action">
                <Button
                  // component="div"
                  className="__btn"
                  onClick={onAddFreeTag}>
                  Add
                </Button>
              </Box>
            </>
          )}
        />
        <Box className="EntryAutocomplete__chips">
          {values?.map((value, idx) => (
            <Chip key={idx} label={tagsIndex[value]||value} onDelete={() => handleDelete(value)} />
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default EntryAutocompleteTags;
