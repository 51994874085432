import { FormControl, FormHelperText, Grid, InputLabel } from "@material-ui/core";
import cx from "classnames";
import { useField } from "formik";
import React from "react";

import BaseMarkdown from "../BaseMarkdown";

const EntryEditor = ({
  required,
  maxLength,
  // ...
  label,
  name,
  placeholder,
  // ...
  topLabel,
  // ...
  error,
  // ...
  sx = {},
  // ...
  xs = 12,
  md = 6,
  // ...
  isEmbedCode,
  disabled = false,
  heightStatic = "__height0"
}) => {
  const [field, meta, helpers] = useField({ name });
  // ...
  let helperText = meta.touched ? meta.error : "";
  const maxLn = maxLength ? 500 : 50;
  const hasError = meta.touched && (!!meta.error || error);
  // ...
  if (error) {
    helperText = error;
  }
  // ...
  // ...
  return (
    <Grid item xs={xs} md={md} sx={{ ...sx }}>
      {topLabel && (
        <label
          style={{
            display: "block",
            color: "#333",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13px",
            lineHeight: "20px",
            fontWeight: "500",
            margin: "0 0 5px 0"
          }}>
          {topLabel}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
      )}

      <FormControl className={cx("EntryEditor", { __embeded: isEmbedCode, ...({[heightStatic]: heightStatic}) })} variant="outlined" margin="dense" fullWidth>
        <InputLabel shrink={true}>{label}</InputLabel>

       <BaseMarkdown 
          readOnly={disabled}
          name={name}
          placeholder={placeholder}
          chars={maxLength}
          // ...
          value={field?.value}
          onChange={helpers.setValue}
          // ...
          isEmbedCode={isEmbedCode}
        />

        <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
      </FormControl>

      {/* {!hasError && (
        <div className="EntryTextLimit" style={{ top: "calc(100% + 3px)" }}>
          <span className="__lng">{field?.value?.length || 0}</span>
          <span className="__sep">/</span>
          <span className="__max">{maxLn}</span>
        </div>
      )} */}
    </Grid>
  );
};

export default EntryEditor;
