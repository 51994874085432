import "./style.scss";

// import { ExportToCsv } from "export-to-csv";
// import csvToJson from "papaparse";
import { withFormik } from "formik";
import React, { useEffect } from "react";
import { read, utils, writeFile } from "xlsx";

import { EntryFile, EntryText } from "../../../../components/Entry";
import EventButton from "../../../../components/EventButton";
import EventForm from "../../../../components/EventForm";
import { validationSchema } from "../../config";
// ...
import { getFormatedAttendees } from "./data";
const iniValues = validationSchema.cast();

const AddAttendee = ({
  attendees,
  isLoading,
  data,
  onEditAttendee,
  ...props
}) => {
  const isEdit = !!data?.id;
  // ...
  const onInsertAttendees = async (name, raw) => {
    const res = await fetch(raw?.base64);
    const blob = await res.blob();
    const file = new File([blob], "attendees-list");
    // ...
    let reader = new FileReader();
    reader.onload = function (e) {
      let r = e.target.result;
      let w = read(r, { type: "binary" });
      // ...
      w.SheetNames.forEach((s) => {
        const x = w.Sheets[s];
        let d = utils.sheet_to_row_object_array(x);
        const header = utils.sheet_to_json(x, { header: 1 })?.[0] || [];
        // ...
        const attds = getFormatedAttendees(d, header, attendees);
        onEditAttendee(attds);
      });
    };
    // ...
    reader.readAsBinaryString(file);
  };
  // ...
  const onExportXLSX = async () => {
    const rows = [{ email: "att@email.com", name: "Attendee name" }];

    /* generate worksheet and workbook */
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Dates");
    // ...
    utils.sheet_add_aoa(worksheet, [["email", "name"]], {
      origin: "A1",
    });
    // ...
    const wch = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    worksheet["!cols"] = [{ wch }];
    // ...
    writeFile(workbook, "attendees_template.xlsx");
  };
  // ...
  const isDups = attendees.indexOf(props?.values?.email) > -1;
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...data } });
  }, []);
  // ...
  return (
    <div className="AddAttendee">
      <div className="AddAttendee__title __">
        Add single attendee via the form below
      </div>
      <EventForm spacing={3}>
        <EntryText
          label="Attendee Email"
          type="email"
          name="email"
          error={isDups ? "Attendee already in the list" : ""}
          md={12}
        />
        <EntryText label="Attendee full name" name="name" md={12} />
      </EventForm>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "40px 0 0 0",
        }}
      >
        <EventButton
          isLoading={isLoading}
          handler={() => onEditAttendee([props.values])}
        >
          Save Attendee
        </EventButton>
      </div>

      {!isEdit && (
        <>
          <div className="AddAttendee__sep" />

          <div className="AddAttendee__title">Or upload the attendees list</div>

          <div className="AddAttendee__title __sub">
            Step 1 :{" "}
            <span
              style={{ color: "#00AB55", fontWeight: "600", cursor: "pointer" }}
              onClick={onExportXLSX}
            >
              Click here to download the template
            </span>
          </div>

          <div className="AddAttendee__title __sub __">
            Step 2 : Fill and upload the excel file
          </div>

          <EventForm>
            <EntryFile
              medium
              name="files"
              label="Only .xlsx files matching the template"
              placeholder="local files"
              onUpload={onInsertAttendees}
              isLoading={isLoading}
              accept=".xlsx"
              md={12}
            />
          </EventForm>
        </>
      )}
    </div>
  );
};

const AddAttendeeWithFormik = withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddAttendee);

export default AddAttendeeWithFormik;
