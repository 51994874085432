import EventSetupBranding from "./tabs/Branding";
import EventSetupInfo from "./tabs/Info";
import EventSetupRegistration from "./tabs/Registration";
import EventSetupReview from "./tabs/Review";

export const TABS_LIST = [
  {
    step: 0,
    label: "Event Details",
    Node: EventSetupInfo,
    hash: "#info",
  },
  {
    step: 1,
    label: "Event Branding",
    Node: EventSetupBranding,
    hash: "#branding",
  },
  {
    step: 2,
    label: "Registration",
    Node: EventSetupRegistration,
    hash: "#registration",
  },
  {
    step: 3,
    label: "Review",
    Node: EventSetupReview,
    hash: "#review",
  },
];

export const previewOptSteps = [
  { label: "Add event agenda", sub: "Optional" },
  { label: "Add at least one speaker", sub: "Optional" },
  { label: "Add sponsors", sub: "Optional" },
  {
    label: "create & publish tickets",
    sub: "required for paid events",
    isReq: true,
  },
];
