export const getDefaults = () => {
  const values = localStorage.getItem("DEFAULTS");
  return values ? JSON.parse(values) : {};
};

export const setDefaults = (data) => {
  localStorage.setItem(
    "DEFAULTS",
    JSON.stringify({ ...getDefaults(), ...data })
  );
};
