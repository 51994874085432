import "./style.scss";

import { Box, Container } from "@material-ui/core";
import { withFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import EventActions from "../../components/EventActions";
import EventSectionTitle from "../../components/EventSectionTitle";
import EventStepper from "../../components/EventStepper";
import EventTabPanel from "../../components/EventTabPanel";
// ...
import Page from "../../components/Page";
import useAttendees from "../../hooks/useAttendees";
import useEvent from "../../hooks/useEvent";
import useRegFields from "../../hooks/useRegFields";
import useSpeakers from "../../hooks/useSpeakers";
import useSponsors from "../../hooks/useSponsors";
import useTicket from "../../hooks/useTicket";
import Printable from "./components/Printable";
// ...
import { getAttendeesList, getRegistrationOptions, getSpeakersList, getSponsorsList, TABS_LIST } from "./config";
import { getTicketData, validationSchema } from "./entries";
const iniValues = validationSchema.cast();

const EventTicketIem = ({ ...props }) => {
  const ticketRef = useRef();

  //# Event Data
  const { isLoading: isLE, data: event } = useEvent();
  const isPaidEvent = !event?.isFree;

  //# Ticket Data
  const { id: ticketId } = useParams();
  const { data: regFields } = useRegFields();
  const [currTab, setCurrTab] = useState(0);
  const { isLoading: isLT, data, onUpdate } = useTicket(ticketId);
  // ...
  const isLoading = isLE || isLT;
  const isPrintStep = currTab === 2;

  //# Attendees Data
  const { data: attendees } = useAttendees();
  const attendeesList = getAttendeesList(attendees, props?.values, regFields);

  //# Speakers Data
  const { data: speakers } = useSpeakers();
  const speakersList = getSpeakersList(speakers);

  //# Sponsors Data
  const { data: sponsors } = useSponsors();
  const sponsorsList = getSponsorsList(sponsors);

  //# Registration Options
  const targetAudience = props?.values?.targetAudience;
  const isAttsTarget = targetAudience === "attendees";
  const registrationOptions = getRegistrationOptions(regFields, targetAudience);
  // ...
  let targetList = attendeesList;
  if (targetAudience === "speakers") targetList = speakersList;
  if (targetAudience === "sponsors") targetList = sponsorsList;

  //# Print Handler
  const onPrintHandler = useReactToPrint({
    content: () => ticketRef.current
  });

  //# Save + Handle Steps
  const onEditTicket = async (noNext) => {
    if (isPrintStep && !noNext) {
      onPrintHandler();
    } else {
      await onUpdate(props.values);
      if (!noNext) setCurrTab((s) => s + 1);
    }
  };

  //# Open Ticket Preview
  const onOpenTicketPreview = async () => {
    //# Save Ticket
    await onEditTicket(true);

    //# Open Ticket Preview
    const THE_URI = window.location.origin;
    const link = `${THE_URI}/manage-tickets/${ticketId}/preview`;
    window.open(link, "__blank");
  };

  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...getTicketData(data) } });
  }, [data]);
  // ...
  return (
    <Page title="Ticketing">
      <Container>
        <Box>
          <EventSectionTitle title="Ticketing" subTitle="Create and manage event tickets" src="/static/setup/title/tickets.svg" />

          <EventStepper currTab={currTab} setCurrTab={(step) => setCurrTab(step)} ITEMS={TABS_LIST} />

          {TABS_LIST.map((tab, idx) => (
            <EventTabPanel key={idx} value={idx} currTab={currTab} sx={{ mt: "30px" }}>
              <tab.Node
                ticketId={ticketId}
                registrationFields={regFields}
                registrationOptions={registrationOptions}
                onOpenTicketPreview={onOpenTicketPreview}
                // va
                // ...
                targetList={targetList}
                attendeesList={attendeesList}
                speakersList={speakersList}
                sponsorsList={sponsorsList}
                // ...
                isPaidEvent={isPaidEvent}
                isAttsTarget={isAttsTarget}
              />
            </EventTabPanel>
          ))}
        </Box>

        <Box id="printables" ref={ticketRef} className={isPrintStep ? "__print-step" : ""}>
          {targetList.map((attendee, idx) => (
            <Printable key={idx} eventData={event} ticketData={data} attendee={attendee} />
          ))}

          {targetList?.length === 0 && (
            <div
              className="TicketPrintable"
              style={{
                fontFamily: "monospace",
                fontSize: "4mm",
                padding: "4mm"
              }}>
              No registered attendees
            </div>
          )}
        </Box>
      </Container>

      <EventActions
        save={{
          isActive: true,
          label: isPrintStep ? "Print" : "Save & continue",
          isLoading,
          handler: () => onEditTicket()
        }}
        cancel={{
          isActive: 0 < currTab,
          label: "Back",
          handler: () => setCurrTab((s) => s - 1)
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(EventTicketIem);
