import { useEffect, useState } from "react";

import HTTP from "../api/http";
import { setDefaults } from "../utils/defaults";

export default function useDefaults() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const getAll = async () => {
    try {
      const defaults = await HTTP.GET("/defaults");
      setDefaults(defaults);
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
    }
  };
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return { isLoading, hasError };
}
