import { Box, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import EventActions from "../../../components/EventActions";
import EventBanner from "../../../components/EventBanner";
import Page from "../../../components/Page";
import useEvent from "../../../hooks/useEvent";
import useTickets from "../../../hooks/useTickets";
import { previewOptSteps } from "../config";
import EventPrediction from "../../../components/EventPrediction";

const getNavLinks = (data) => {
  const THE_ID = data?.id;
  const THE_ORG = data?.organization?.id;
  const THE_JWT = window.localStorage.getItem("THE_JWT");
  const LIVE_URL = process.env.REACT_APP_EVEY_URL;
  const STUDIO_URL = process.env.REACT_APP_BACK_URL;
  // ...
  return {
    previewLink: `${LIVE_URL}/events/${data?.organization?.slug}/${data.slug}`,
    studioLink: `${STUDIO_URL}/?token=${THE_JWT}&eventId=${THE_ID}&orgId=${THE_ORG}`
  };
};

const ReviewItem = ({ data }) => (
  <div className="EventReview__item">
    <div className="__state">
      {data.state && <DoneIcon style={{ color: "#61ba73" }} />}
      {!data.state && !data.isReq && <WarningIcon style={{ color: "#FF8A00" }} />}
      {!data.state && data.isReq && <ErrorIcon style={{ color: "#e8463e" }} />}
    </div>
    <div className="__content">
      <div className="__label">{data.label}</div>
      <div className="__sub">{data.sub}</div>
    </div>
  </div>
);

const EventNavLink = ({ isTop, label, link = "" }) => {
  const { enqueueSnackbar } = useSnackbar();
  // ...
  return (
    <div className="EventReview__link" style={{ margin: isTop ? "0 0 50px 0" : "0" }}>
      <CopyToClipboard text={link} onCopy={() => enqueueSnackbar("Link copied", { variant: "success" })}>
        <Tooltip title="Click to copy" arrow>
          <div className="__inner">
            <span className="__label">{label}</span>
            <span className="__url">{link}</span>
          </div>
        </Tooltip>
      </CopyToClipboard>
    </div>
  );
};

const EventReview = ({ valSteps = [], onBackHandler }) => {
  const { isLoading, data, onUpdate } = useEvent();
  const { data: tickets } = useTickets();
  // ...
  const [valOpts, setValOpts] = useState([false, false, false, false]);
  const valBasicSteps = valSteps.slice(0, 2).reduce((x, y) => x && y, true);
  const isPublished = data?.published;
  let isDisabled = data?.isFree ? !valBasicSteps : !valBasicSteps || !valOpts[3];
  if (isPublished) isDisabled = false;
  // ...
  const { previewLink, studioLink } = getNavLinks(data);
  // ...
  const onValidate = async () => {
    let _val = [];
    // ...
    _val[0] = data.stages?.length > 0;
    _val[1] = data.speakers?.length > 0;
    _val[2] = data.sponsors?.length > 0;
    _val[3] = tickets?.length > 0;
    // ...
    setValOpts(_val);
  };
  // ...
  const onPublishEvent = async () => {
    if (isPublished) return;
    await onUpdate({ published: true });
  };
  // ...
  const onPreviewEvent = () => {
    window.open(previewLink, "_blank");
  };
  // ...
  const onOpenBackstage = () => {
    window.open(studioLink, "backstage");
  };
  // ...
  useEffect(() => {
    onValidate();
  }, [data, tickets]);
  // ...
  return (
    <Page title="Event Review">
      <EventBanner data={data} />

      {isPublished && (
        <div className="EventReview__content">
          <Typography variant="titleBig" component="h2" className="EventReview__title">
            {data?.title} <span style={{ color: "#00AB55" }}>Published!</span>
          </Typography>
          <Typography variant="titleBig" component="h3" className="EventReview__sub">
            Click on the link below to copy your published event link
          </Typography>

          <Box className="EventReview__links">
            <EventNavLink isTop label="Participants link" link={previewLink} />
            <EventNavLink label="Studio link" link={studioLink} />
          </Box>
          <Box  sx={{marginTop: "50px"}}>
            <Typography variant="titleBig" component="h3" className="EventReview__sub">
              To improve event prediction add speaker and sponsor
            </Typography>
            <EventPrediction event={data||{}}/>
          </Box>
        </div>
      )}

      {!isPublished && (
        <div className="EventReview__content">
          <Typography variant="titleBig" component="h2" className="EventReview__title">
            Your event is about to be published
          </Typography>
          <Typography variant="titleBig" component="h3" className="EventReview__sub">
            {valBasicSteps && <span>Basic details are complete, </span>}
            <span>however you can add more info for better visibility, learn more about</span>
            <br />
            <span style={{ color: "#00AB55", fontWeight: "500" }}>event quality standards</span>
          </Typography>

          <div
            className={cx("EventReview__items EventReview__expand", {
              __isFree: data?.isFree
            })}>
            {previewOptSteps
              .filter((s, idx) => {
                if (data?.isFree && idx === 3) return false;
                return true;
              })
              .map((data, idx) => (
                <ReviewItem key={idx} data={data} />
              ))}
          </div>
        </div>
      )}

      <EventActions
        save={{
          isActive: true,
          color: "#DB1F35",
          label: isPublished ? "Open in live studio" : "Back",
          handler: isPublished ? onOpenBackstage : onBackHandler
        }}
        cancel={{
          isActive: true,
          label: isPublished ? "View event" : "Publish event",
          isLoading,
          isDisabled,
          handler: isPublished ? onPreviewEvent : onPublishEvent
        }}
      />
    </Page>
  );
};

export default EventReview;
