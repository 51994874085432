import { Box, Typography } from "@material-ui/core";
import React, { useRef, useState } from "react";

import MenuPopover from "../components/MenuPopover";

const AccountPopover = ({ orgLabel, eventName, children }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  //...
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ...
  const onNavigateToOrgProfile = () => {
    const LIVE_URL = process.env.REACT_APP_EVEY_URL;
    const link = `${LIVE_URL}/profile/my-organization`;
    window.open(link, "_blank");
  };
  // ...
  return (
    <Box className="DashboardNavbarPopover">
      <Box ref={anchorRef} onClick={handleOpen}>
        {children}
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            className="DashboardNavbarPopover__org"
            variant="subtitle1"
            noWrap
            onClick={onNavigateToOrgProfile}
          >
            {orgLabel}
          </Typography>
          <Typography
            className="DashboardNavbarPopover__event"
            variant="body2"
            sx={{ color: "text.secondary" }}
            noWrap
          >
            {eventName}
          </Typography>
        </Box>
      </MenuPopover>
    </Box>
  );
};

export default AccountPopover;
