import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import React from "react";
import { NavLink as RouterLink } from "react-router-dom";

import SVG from "../components/Svg";

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  fill: "#637381",
  fontFamily: "Poppins",
  fontWeight: "500",
  height: "43px",
  textTransform: "capitalize",
  paddingLeft: "30px",
  paddingRight: 0,
  color: theme.palette.text.secondary,
  "&.isActiveRoot": {
    fill: "#00AB55",
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: "absolute",
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const SubIconStyle = styled("span")(({ theme }) => ({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    width: 4,
    height: 4,
    content: "''",
    display: "block",
    borderRadius: "50%",
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create("transform"),
  },
}));

// ----------------------------------------------------------------------

export default function SidebarItem({
  title,
  href,
  icon,
  open = false,
  isSubItem,
}) {
  return (
    <ListItemStyle
      button
      to={href}
      exact={open}
      disableGutters
      component={RouterLink}
      activeClassName={isSubItem ? "isActiveSub" : "isActiveRoot"}
      isActive={(match, location) => {
        if (!match) {
          return false;
        }

        const { url } = match;
        const { pathname } = location;
        const isMatch = url === pathname;

        if (!isSubItem) {
          return url.length && pathname.includes(url);
        }

        return isMatch;
      }}
    >
      <ListItemIcon sx={{ width: "24px", justifyContent: "center" }}>
        {isSubItem ? <SubIconStyle className="subIcon" /> : <SVG src={icon} />}
      </ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}
