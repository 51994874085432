import { Box, Card, Container } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventEntityTable from "../../components/EventEntityTable";
import EventSectionTitle from "../../components/EventSectionTitle";
import ModalValidation from "../../components/ModalValidate";
// ...
import Page from "../../components/Page";
import useEventPack from "../../hooks/useEventPack";
import useTickets from "../../hooks/useTickets";
import { EXPORT_HEAD, TABLE_HEAD } from "./config";

// ...
const getPriceWithCurrency = (price, currency) => {
  if (!price) return "FREE";
  return `${price} ${currency?.toUpperCase()}`;
};
// ...
const EventTickets = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const [delModal, setDelModal] = useState({
    open: false,
    id: null,
    label: "",
  });
  const { packData } = useEventPack();
  // ...
  const history = useHistory();
  const { isLoading, data: tickets, onCreate, onDelete } = useTickets();
  // ...
  const ticketsList = tickets.map((t) => ({
    ...t,
    priceLabel: getPriceWithCurrency(t?.price, t?.currency),
    ticketStatus: t.isActive ? "Active" : "Not active",
    amountSum: t.currency=="tnd" ? ((t.amountSum||0)/1000) : ((t.amountSum||0)/100)
  }));
  // ...
  const totalTicketsCount = useMemo(() => {
    return _.reduce(tickets, function(memo, ticket){ return memo + ticket.paymentCount; }, 0);
  }, [tickets]);
  const totalTicketsAmount = useMemo(() => {
    
    return _.reduce(tickets, function(memo, ticket){ return memo + (ticket.currency=="tnd" ? ((ticket.amountSum||0)/1000) : ((ticket.amountSum||0)/100)); }, 0);
  }, [tickets]);
  // ...
  const onAddTicket = async () => {
    const { ticketId, error } = await onCreate();
    // ...
    if (error) return;
    history.push(`/manage-tickets/${ticketId}`);
  };
  const onEditTicket = (data) => {
    history.push(`/manage-tickets/${data.id}`);
  };
  const onDeleteTicket = (id) => {
    onDelete(id);
    // ...
    setDelModal({ open: false, id: null, label: "" });
  };
  // ...
  const canEditTicketSettings = () => {    
    return packData.type && ["business", "prestige"].includes(packData.type) && true;
  }
  // ...
  return (
    <Page title="Ticketing and badging">
      <Container>
        <Box>
          <EventSectionTitle
            title="Ticketing and badging"
            subTitle="Create and manage event tickets"
            src="/static/setup/title/tickets.svg"
          />

          {ticketsList.length > 0 && (
            <EventSectionTitle
              title="View tickets list"
              subTitle="Review and manage tickets"
              sx={{ marginBottom: "30px !important" }}
            >
              <EventButton disabled={!canEditTicketSettings()} isLoading={isLoading} handler={() => onAddTicket()}>
                {isMobile && "New"}
                {!isMobile && "New ticket"}
              </EventButton>
            </EventSectionTitle>
          )}

          {ticketsList.length === 0 && (
            <EventEmptyView
              src="/static/setup/empty/tickets.svg"
              title="Event ticketing and badging"
              desc="Customize event ticket and attendance badges"
              btnLabel="Create new"
              handler={onAddTicket}
              isLoading={isLoading}
            />
          )}

          {ticketsList.length > 0 && (
            <Box>
              <Card>
                <EventEntityTable
                  head={TABLE_HEAD}
                  data={ticketsList}
                  // ...
                  searchLabel="title"
                  exportName="tickets-list"
                  exportHead={EXPORT_HEAD}
                  // ...
                  disabledOptions={!canEditTicketSettings()}
                  onEditHandler={(data) => onEditTicket(data)}
                  onDeleteHandler={(data) => {
                    setDelModal({
                      open: true,
                      id: data.id,
                      label: data?.title,
                    });
                  }}
                >
                  <p >total count: {(totalTicketsCount||0)}</p>
                  <p >total amount : {totalTicketsAmount}</p>
                </EventEntityTable>
              </Card>
            </Box>
          )}
        </Box>
      </Container>

      <ModalValidation
        open={delModal.open}
        title="Delete sponsor"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteTicket(delModal.id),
        }}
        no={{
          label: "cancel",
          handler: () => setDelModal({ open: false, id: null, label: "" }),
        }}
      >
        Are you sure to delete {delModal?.label || "ticket"}?
      </ModalValidation>
    </Page>
  );
};

export default EventTickets;
