import * as yup from "yup";

export const validationSchema = yup
  .object({
    name: yup.string().required(),
    slogan: yup.string().notRequired(),
    description: yup.string().notRequired(),
    email: yup.string().email("Must be a valid email").required(),
    phone: yup.string().notRequired(),
    website: yup.string().notRequired(),
    facebook: yup.string().notRequired(),
    linkedin: yup.string().notRequired(),
    instagram: yup.string().notRequired(),
    card: yup.object().required().nullable(),
    cover: yup.object().required().nullable(),
    // ...
    settings: yup.object({
      country: yup.string().notRequired(),
      city: yup.string().notRequired(),
      industries: yup.object().notRequired(),
      tags: yup.object().notRequired()
    }),
    // ...
    type: yup.string().notRequired(),
    level: yup.string().notRequired(),
    featureLandingPage: yup.boolean().nullable(),
    featureVenue: yup.boolean().nullable(),
    sendEmailNotification: yup.boolean().nullable(),
    files: yup.array().nullable().notRequired(),
    // ...
    search: yup.string().notRequired()
  })
  .default({
    name: "",
    slogan: "",
    description: "",
    email: "",
    phone: "",
    website: "",
    facebook: "",
    linkedin: "",
    instagram: "",
    card: null,
    cover: null,
    settings: {
      country: "",
      city: "",
      industries: {},
      tags: {}
    },
    // ...
    type: "sponsor",
    level: "silver",
    featureLandingPage: false,
    featureVenue: false,
    sendEmailNotification: false,
    files: [],
    // ...
    search: ""
  });

export const EVENT_INDUSTRIES = [
  { label: "Accommodation", value: "Accommodation" },
  { label: "Administration", value: "Administration" },
  { label: "Agriculture", value: "Agriculture" },
  { label: "Architecture", value: "Architecture" },
  { label: "Art & Culture", value: "Art&Culture" },
  { label: "Audiovisual", value: "Audiovisual" },
  { label: "Books", value: "Books" },
  { label: "Business", value: "Business" },
  { label: "Chemicals", value: "Chemicals" },
  { label: "Construction", value: "Construction" },
  { label: "Consulting", value: "Consulting" },
  { label: "Distribution", value: "Distribution" },
  { label: "Education", value: "Education" },
  { label: "Electronics", value: "Electronics" },
  { label: "Employment", value: "Employment" },
  { label: "Energy", value: "Energy" },
  { label: "Finance & Insurance", value: "Finance&Insurance" },
  { label: "Food", value: "Food" },
  {
    label: "Healthcare & Social Assistance",
    value: "Healthcare&SocialAssistance"
  },
  { label: "Internet", value: "Internet" },
  { label: "Marketing", value: "Marketing" },
  { label: "MasterClass", value: "MasterClass" },
  { label: "Medecine", value: "Medecine" },
  { label: "Mining", value: "Mining" },
  { label: "Nature", value: "Nature" },
  { label: "Online Services", value: "OnlineServices" },
  { label: "Politics", value: "Politics" },
  { label: "Rental & Leasing", value: "Rental&Leasing" },
  { label: "Science", value: "Science" },
  { label: "Security", value: "Security" },
  { label: "Software", value: "Software" },
  { label: "Technology", value: "Technology" },
  { label: "Travel", value: "Travel" }
];

export const EVENT_TAGS = [
  { label: "Association", value: "Association" },
  { label: "Business", value: "Business" },
  { label: "Change", value: "Change" },
  { label: "Club", value: "Club" },
  { label: "Community", value: "Community" },
  { label: "Corporation", value: "Corporation" },
  { label: "Course", value: "Course" },
  { label: "Data", value: "Data" },
  { label: "Development", value: "Development" },
  { label: "Discover", value: "Discover" },
  { label: "Economy", value: "Economy" },
  { label: "Ecology", value: "Ecology" },
  { label: "Education", value: "Education" },
  { label: "Entertainement", value: "Entertainement" },
  { label: "Entrepreneurship", value: "Entrepreneurship" },
  { label: "Goal", value: "Goal" },
  { label: "Green", value: "Green" },
  { label: "Growth", value: "Growth" },
  { label: "Innovation", value: "Innovation" },
  { label: "Institution", value: "Institution" },
  { label: "Leadership", value: "Leadership" },
  { label: "Marketing", value: "Marketing" },
  { label: "Networking", value: "Networking" },
  { label: "ONG", value: "ONG" },
  { label: "Partnership", value: "Partnership" },
  { label: "Process", value: "Process" },
  { label: "Sales", value: "Sales" },
  { label: "Social", value: "Social" },
  { label: "Success", value: "Success" },
  { label: "Startup", value: "Startup" },
  { label: "Strategy", value: "Strategy" },
  { label: "Structure", value: "Structure" },
  { label: "Students", value: "Students" },
  { label: "Sustainability", value: "Sustainability" },
  { label: "Training", value: "Training" },
  { label: "University", value: "University" }
];

export const SPONSOR_TYPE = [
  { label: "Partner", value: "partner" },
  { label: "Sponsor", value: "sponsor" },
  { label: "Exhibitor", value: "exhibitor" }
];
export const SPONSOR_LEVEL = [
  { label: "Diamond", value: "diamond" },
  { label: "Platinum", value: "platinum" },
  { label: "Gold", value: "gold" },
  { label: "Silver", value: "silver" },
  { label: "Bronze", value: "bronze" }
];
