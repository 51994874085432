import { Box, Grid } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useFormikContext } from "formik";
import React from "react";

import {
  EntryColor,
  EntryFile,
  EntrySelect,
  EntrySwitch,
  EntryText,
} from "../../../components/Entry";
import EventForm from "../../../components/EventForm";
import SVG from "../../../components/Svg";
import useFile from "../../../hooks/useFile";
import { designFields, forthDetailOptions } from "../config";

const Design = ({ onOpenTicketPreview, registrationOptions }) => {
  const { onUpload } = useFile();
  const { values, setFieldValue } = useFormikContext();
  // ...
  const onUploadFile = async (key, files) => {
    const data = await onUpload(files);
    setFieldValue([key], data[0]);
  };
  const onResetFile = async (name) => {
    setFieldValue([name], null);
  };

  //# Placehoders
  const ticketName = values?.title || "";
  const hasQRCode = !!values?.showQrcode;
  const tHolders = [];
  tHolders[0] = values?.heading || values?.text;
  tHolders[1] = values?.subHeading1 || values?.subText1;
  tHolders[2] = values?.subHeading2 || values?.subText2;
  tHolders[3] =
    values?.subHeading3 === "ticket-name" ? ticketName : values?.subText3;
  // ...
  return (
    <div className="TicketStep __design">
      <div className="__header">
        <div className="__content">
          <h3 className="__title">1- ticket Layout</h3>
          <h4 className="__sub">Customize ticket background and colors</h4>
        </div>
        <div className="__actions" onClick={() => onOpenTicketPreview()}>
          <div className="__icon">
            <SVG src="/static/icons/extern.svg" />
          </div>
          <div className="__label">Preview Ticket</div>
        </div>
      </div>

      <EventForm>
        <EntryColor
          label="Background color"
          name="backgroundColor"
          placeholder="white"
          xs={12}
          md={4}
        />
        {/* <EntryFile
            compact
            label="Ticket Banner"
            name="bannerImage"
            placeholder="Local image"
            onUpload={onUploadFile}
            xs={12}
            md={3}
          /> */}
        <EntryFile
          compact
          label="Background image"
          name="backgroundImage"
          placeholder="Local image"
          onUpload={onUploadFile}
          onReset={onResetFile}
          xs={12}
          md={4}
        />
        <EntryColor
          label="Text color"
          name="textColor"
          placeholder="white"
          xs={12}
          md={4}
        />
      </EventForm>

      <div style={{ margin: "0 0 80px 0" }} />

      <div className="__groups">
        <div className="__group">
          <h3 className="__group__title">
            2- Details that will appear in the ticket
          </h3>
          <h4 className="__group__sub">
            Choose the event and / or participant details that will be printed
            in the ticket
          </h4>

          <EventForm>
            {designFields.map((opt, idx) => {
              const value = values?.[opt.name];
              const isOtherValue = value === "";
              // ...
              const isForthDetail = opt.name === "subHeading3";
              const options = isForthDetail
                ? forthDetailOptions
                : registrationOptions;
              // ...
              return (
                <Grid key={idx} container item spacing={4}>
                  <EntrySelect
                    name={opt.name}
                    label={opt.label}
                    options={options}
                    xs={12}
                    md={12}
                  />

                  {isOtherValue && (
                    <EntryText
                      name={opt.otherName}
                      label={opt.otherLabel}
                      placeholder={opt.otherHolder}
                      noMaxLength
                      xs={12}
                      md={12}
                    />
                  )}
                </Grid>
              );
            })}
          </EventForm>

          <Box sx={{ margin: "0 0 30px 0" }} />

          <EventForm>
            <EntrySwitch
              name="showQrcode"
              label="QR code will appear in this ticket"
              xs={12}
              md={12}
            />
          </EventForm>
        </div>

        <div className="__sep" />

        <div className="__group __qr-bar">
          <h3 className="__group__title">3- Live ticket preview</h3>
          {/* <h4 className="__group__sub">
            <span className="__nav">Click here</span> to view in full screen
          </h4> */}
          <div className="__group__note" style={{ marginTop: "40px" }}>
            <div className="__icon">
              <InfoIcon />
            </div>
            <div className="__content">
              <h3 className="__title">Check the below ticket layout</h3>
              <h4 className="__sub">
                or click here to display a{" "}
                <span
                  className="__clickable"
                  onClick={() => onOpenTicketPreview()}
                >
                  ticket sample
                </span>
              </h4>
            </div>
          </div>

          <div className="__qr-bar">
            <div className="__body">
              <div className="__info">
                {/* <div className="__info__num">
                  <span className="__label">Ticket </span>
                  <span className="__value">#002158413</span>
                </div> */}
                <div className="__info__name">{tHolders?.[0] || ""}</div>
                <div className="__info__pos">{tHolders?.[1] || ""}</div>
                <div className="__info__org">{tHolders?.[2] || ""}</div>
              </div>
              <div className="__qr">
                {hasQRCode && <img src="/static/icons/qr.svg" alt="" />}
              </div>
            </div>
            {!!tHolders[3] && <div className="__type">{tHolders[3]}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
