import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { HelmetProvider } from "react-helmet-async";
import { hotjar } from "react-hotjar";
import { Router } from "react-router-dom";
// ...
import { RecoilRoot } from "recoil";

import GoogleAnalytics from "./components/GoogleAnalytics";
import NotistackProvider from "./components/NotistackProvider";
import RtlLayout from "./components/RtlLayout";
// ...
import ScrollToTop from "./components/ScrollToTop";
import routes, { renderRoutes } from "./routes";
// ...
import ThemeConfig from "./theme";

const REACT_GA_TRACKING_ID =
  process.env.REACT_APP_GA_TRACKING_ID || "UA-XXXXX-X";
ReactGA.initialize(REACT_GA_TRACKING_ID);
const history = createBrowserHistory();

export default function App() {
  // ...
  useEffect(() => {
    try {
      hotjar.initialize(
        process.env.REACT_APP_HOTJAR_ID,
        process.env.REACT_APP_HOTJAR_SV
      );
    } catch (error) {
      console.error("Hotjar error : ", error);
    }
  }, []);
  // ...
  return (
    <HelmetProvider>
      <RtlLayout>
        <ThemeConfig>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <NotistackProvider>
              <RecoilRoot>
                <Router history={history}>
                  <ScrollToTop />
                  <GoogleAnalytics />
                  {renderRoutes(routes)}
                </Router>
              </RecoilRoot>
            </NotistackProvider>
          </LocalizationProvider>
        </ThemeConfig>
      </RtlLayout>
    </HelmetProvider>
  );
}
