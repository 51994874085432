import useMediaQuery from "@material-ui/core/useMediaQuery";
import cx from "classnames";
import React from "react";

import LoadingDots from "../../LoadingDots";
import EntryImage from "../EntryImageV2";

const EntryPhoto = ({
  // required,
  name,
  src,
  placeholder = "upload photo",
  htmlFor,
  // defaultK,
  // ...
  isReadOnly,
  isRounded,
  isLoading,
  // ...
  onUpload,
  meta,
  // ...
  style = {}
}) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  return (
    <div
      className={cx("EntryPhoto", {
        __isReadOnly: isReadOnly,
        __isRounded: isRounded,
        __empty: !src
      })}
      style={{ ...style }}>
      <div className="__img">
        <img src={src} />
      </div>

      <div className="__inner">
        <label className="__input" htmlFor={htmlFor}>
          <EntryImage
            required
            preview
            label=""
            placeholder=""
            htmlFor={htmlFor}
            name={name}
            md={12}
            // ...
            isRounded={isRounded}
            isLoading={isLoading}
            onUpload={onUpload}
            meta={meta}
          />
        </label>
        <div className="__icon">
          {!isLoading && (
            <img
              src="/static/icons/upload.svg"
              style={{
                width: isMobile ? "19px" : "24px",
                height: isMobile ? "19px" : "24px"
              }}
            />
          )}
        </div>
        <div className={cx("__label", { __required: !isLoading })}>{isLoading ? <LoadingDots /> : placeholder}</div>
      </div>
    </div>
  );
};

export default EntryPhoto;
