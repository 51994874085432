import "./style.scss";

import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import React from "react";

const EventStepper = ({ currTab, setCurrTab, ITEMS }) => {
  const steps = ITEMS.map((i) => ({ label: i.label, step: i.step }));
  // ...
  return (
    <>
      <Stepper className="EventStepper" activeStep={currTab} alternativeLabel>
        {steps.map((s) => (
          <Step key={s.step} className="__step">
            <StepLabel
              className="__label"
              onClick={() => {
                if (currTab === s.step) return;
                setCurrTab(s.step);
              }}>
              <span>{s.label}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default EventStepper;
