import _ from "underscore";

const emailRE = /\S+@\S+\.\S+/;

export const getFormatedAttendees = (data, header, attds) => {
  const out = data
    ?.map((a) => {
      let attd = {};
      // ...
      // { email: a?.email || "", name: a?.name || "" }
      header.forEach((hName) => {
        attd[hName] = a?.[hName] || "";
      });
      // ...
      return attd;
    })
    ?.filter((a) => !!a?.email && emailRE.test(a.email) && attds.indexOf(a.email) === -1);
  // ...
  return out?.filter((a, cIdx) => {
    const fIdx = _.indexOf(_.pluck(out, "email"), a.email);
    const lIdx = _.lastIndexOf(_.pluck(out, "email"), a.email);
    // ...
    return fIdx === lIdx || fIdx === cIdx;
  });
};
