import { useFormikContext } from "formik";
import React from "react";

import EventTicketPreview from "../../EventTicketPreview";

const Print = () => {
  const { values } = useFormikContext();
  const target = values?.targetAudience || "";
  // ...
  return (
    <div className="TicketStep __print">
      <div className="__header">
        <div className="__content">
          <h3 className="__title">Print ticket</h3>
          <h4 className="__sub">Print this ticket for all {target}</h4>
        </div>
        {/* <div className="__actions" onClick={() => onOpenTicketPreview()}>
          <div className="__icon">
            <SVG src="/static/icons/extern.svg" />
          </div>
          <div className="__label">Preview Ticket</div>
        </div> */}
      </div>

      <div className="__body">
        <EventTicketPreview />
      </div>
    </div>
  );
};

export default Print;
