import { Box, Card, Container } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";

import EventButton from "../../components/EventButton";
import EventEntityDrawer from "../../components/EventEntityDrawer";
import EventEntityTable from "../../components/EventEntityTable";
import EventSectionTitle from "../../components/EventSectionTitle";
import ModalValidation from "../../components/ModalValidate";
import Page from "../../components/Page";
import useEmails from "../../hooks/useEmails";
import useEvent from "../../hooks/useEvent";
import useEventPack from "../../hooks/useEventPack";
import useRegFields from "../../hooks/useRegFields";
import AddEmail from "./components/AddEmail";
import EmailCategory, { getCategoryName } from "./components/Category";
import { EXPORT_HEAD, TABLE_HEAD } from "./config";

const EventEmail = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const [drawer, setDrawer] = useState({ open: false, data: {}, idx: "" });
  const [delModal, setDelModal] = useState({
    open: false,
    id: null,
    label: "",
  });
  const { packData } = useEventPack();
  // ...
  const { data: event } = useEvent();
  const { isLoading, data: emails, onUpdate, onRemove } = useEmails();
  // ...
  const { data: fields } = useRegFields();
  const { regLabel, regFields } = fields || {};
  const hasExtraFields = regLabel === "customized";
  const customFields = hasExtraFields
    ? regFields?.map((f) => f?.settings?.slug).filter((f) => !!f)
    : [];

  // ...
  const isPrivate = event?.access === "private";
  const emailsList = emails.map((e) => ({
    ...e,
    categoryLabel: <EmailCategory val={e.category} />,
    categoryExp: getCategoryName(e.category),
    status: e.active ? "active" : "disbaled"
  }));
  // ...
  const onEditEmail = async (data) => {
    await onUpdate(data);
    setDrawer({ open: false, data: {}, idx: "" });
  };
  // ...
  const onDeleteEmail = (id) => {
    onRemove(id);
    setDelModal({ open: false, id: null, label: "" });
  };
  // ...
  const canEditTemplateEmail = () => {    
    return packData.type && ["starter", "business", "prestige"].includes(packData.type) && true;
  }
  // ...
  return (
    <Page title="Manage Emails">
      <Container>
        <Box>
          <EventSectionTitle
            title="Email template"
            subTitle="Create email templates"
            src="/static/setup/title/email.svg"
          />

          <EventSectionTitle title="View templates" subTitle="View templates">
            <EventButton
              disabled={!canEditTemplateEmail()}
              isLoading={isLoading}
              handler={() => setDrawer({ open: true, _id: null })}
            >
              {isMobile && "New"}
              {!isMobile && "New template"}
            </EventButton>
          </EventSectionTitle>

          <Box>
            <Card>
              <EventEntityTable
                label="email"
                head={TABLE_HEAD}
                data={emailsList}
                // ...
                searchLabel="category"
                exportName="templates-list"
                exportHead={EXPORT_HEAD}
                // ...
                disabledOptions={!canEditTemplateEmail()} 	
                // ...
                onEditHandler={(data, idx) =>
                  setDrawer({ open: true, data, idx })
                }
                onDeleteHandler={(data) => {
                  setDelModal({
                    open: true,
                    id: data.id,
                    label: data?.name,
                  });
                }}
              />
            </Card>
          </Box>
        </Box>

        <EventEntityDrawer
          open={drawer.open}
          closeDrawer={() => setDrawer({ open: false, data: {}, idx: "" })}
          title={
            drawer?.data?.id ? "Edit email template" : "Create email templates"
          }
          sub="Send customized emails to your stakholders."
          width="80%"
          innerSX={{ padding: "0", backgroundColor: "#fff" }}
          headerSX={{ padding: "20px 24px 0px 24px" }}
          contentSX={{ padding: "0" }}
        >
          <AddEmail
            idx={drawer.idx}
            data={drawer.data}
            onEditEmail={onEditEmail}
            isLoading={isLoading}
            // ...
            isPrivate={isPrivate}
            customFields={customFields}
          />
        </EventEntityDrawer>
      </Container>

      <ModalValidation
        open={delModal.open}
        title="Delete sponsor"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteEmail(delModal.id),
        }}
        no={{
          label: "cancel",
          handler: () => setDelModal({ open: false, id: null, label: "" }),
        }}
      >
        Are you sure to delete email template?
      </ModalValidation>
    </Page>
  );
};

export default EventEmail;
