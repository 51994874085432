import { Box, IconButton, Menu, MenuItem, TableCell } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";

const TableCellActions = ({
  label,
  idx,
  data,
  // ...
  noEdit,
  // ...
  onEditHandler,
  onDeleteHandler,
  // ...
  onResendHanlder,
  disabledOptions = false
}) => {
  const [open, setOpen] = useState(null);
  // ...
  let OPTIONS = [{ label: `Delete ${label}`, handler: onDeleteHandler, isDanger: true }];
  // ...
  if (!noEdit) {
    OPTIONS = [
      { label: `Edit ${label}`, handler: onEditHandler },
      { label: `Delete ${label}`, handler: onDeleteHandler, isDanger: true }
    ];
  }
  // ...
  if (onResendHanlder && !!data?.sessionsBase?.length > 0) {
    OPTIONS = [
      { label: `Edit ${label}`, handler: onEditHandler },
      { label: "Resend email", handler: onResendHanlder },
      { label: `Delete ${label}`, handler: onDeleteHandler, isDanger: true }
    ];
  }
  // ...
  return (
    <TableCell align="right">
      <Box sx={{ position: "relative" }}>
        <IconButton disabled={disabledOptions} aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={(e) => setOpen(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          keepMounted
          anchorEl={open}
          onClose={() => setOpen(null)}
          open={Boolean(open)}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: "20ch"
            }
          }}>
          {OPTIONS.map((opt) => (
            <MenuItem
              key={opt.label}
              onClick={() => {
                opt.handler(data, idx);
                setOpen(null);
              }}
              sx={{ color: opt.isDanger ? "red" : "" }}>
              {opt.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </TableCell>
  );
};

export default TableCellActions;
