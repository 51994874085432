import { Box, Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import EventEmptyView from "../../components/EventEmptyView";
import EventSectionTitle from "../../components/EventSectionTitle";
import Page from "../../components/Page";
import useEvent from "../../hooks/useEvent";
import useEventPack from "../../hooks/useEventPack";

const EventCarbon = () => {
  const [reportLink, setReportLink] = useState(null);
  // ...
  const { data } = useEvent();
  const { packData } = useEventPack();
  const isPubEvent = data?.published;
  const typeEvent = data?.type;
  // ...
  let baseLink = process.env.REACT_APP_REPORT_CARBON_VIRTUAL_BASE_LINK;
  if (typeEvent === "hybrid")
    baseLink = process.env.REACT_APP_REPORT_CARBON_HYBRID_BASE_LINK;
  if (typeEvent === "physical")
    baseLink = process.env.REACT_APP_REPORT_CARBON_PHYSICAL_BASE_LINK;
  // ...
  const canShowCarbonReport = () => {    
    return packData.type && ["business", "prestige"].includes(packData.type) && true;
  }
  // ...
  useEffect(() => {
    if (data?.id && data?.slug && data?.organization?.slug) {
      setReportLink(
        (baseLink || "")
          .replace("___org_slug___", data?.organization?.slug)
          .replace("___event_slug___", data?.slug)
          .replace("___event_hash___", data?.hash)
      );
    }
  }, [data]);
  // ...
  return (
    <Page title="Carbon footprint">
      <Container>
        <Box>
          <EventSectionTitle
            title="Carbon footprint"
            subTitle="Explore real time event analytics"
            src="/static/setup/title/dashboard.svg"
          />

          {!isPubEvent && (
            <EventEmptyView
              src="/static/setup/empty/dashboard.svg"
              title="Get realtime event insights"
              desc="Publish your event to get carbon footprint real-time insights"
            />
          )}

          {!canShowCarbonReport() && (
            <EventEmptyView
              src="/static/setup/empty/dashboard.svg"
              title="Upgrade your plan"
              desc="Upgrade your plan to get carbon footprint real-time insights"
            />
          )}

          {isPubEvent && reportLink && canShowCarbonReport() && (
            <Box>
              <iframe
                display="initial"
                src={reportLink}
                frameBorder="0"
                style={{ border: "none", width: "100%", height: "2000px" }}
              />
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default EventCarbon;
