import { useRecoilValue } from "recoil";

import { AUTH_CHECK } from "../api";

const AuthProtect = ({ children }) => {
  const HAS_AUTH = useRecoilValue(AUTH_CHECK);
  // ...
  if (!HAS_AUTH) return "Not auth";
  return children;
};

export default AuthProtect;
