import { Tab as MuiTab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const Tab = withStyles(() => ({
  root: {
    flex: "1",
    fontWeight: "700",
    fontSize: "14px",
    color: "#98A4B0",
    fontFamily: "\"Montserrat\", sans-serif",
    textTransform: "capitalize",
    padding: "10px 0",
    width: "110px",
    margin: "0",
    marginRight: "30px !important",
    overflow: "visible",
    "&:hover": {
      color: "#212b36",
      opacity: 1,
    },
    "&$selected": {
      color: "#212b36",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "flex-start",
  },
  selected: {
    color: "#5BE584",
  },
}))((props) => <MuiTab {...props} disableRipple />);

export default Tab;
