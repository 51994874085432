import "./style.scss";

import { Box } from "@material-ui/core";
import cx from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";

import SVG from "../../components/Svg";
import useEvent from "../../hooks/useEvent";

const EventSectionTitle = ({ src, title, subTitle, children, sx = {} }) => {
  const { data } = useEvent();
  const history = useHistory();
  // ...
  const __sub = !src && children;
  const isPublished = data?.published;
  // ...
  const onNavigateToSatus = () => {
    history.push("/event-setup#review");
  };
  // ...
  return (
    <Box className={cx("EventSectionTitle", { __sub })} sx={{ ...sx }}>
      <Box className="__content">
        <Box className="__content__icon">
          <Box component="img" alt="" src={src} />
        </Box>
        <Box className="__content__event">
          {title && <Box className="__content__event__title">{title}</Box>}
          {subTitle && <Box className="__content__event__sub">{subTitle}</Box>}
        </Box>
      </Box>

      {__sub && <Box className="__status">{children}</Box>}

      {!__sub && (
        <Box className="__status" onClick={onNavigateToSatus}>
          <Box className={cx("__status__icon", { isPublished })}>
            {isPublished && <SVG src="/static/icons/done_event.svg" alt="" />}
            {!isPublished && <SVG src="/static/icons/draft_event.svg" alt="" />}
          </Box>
          <Box className="__status__content">
            {isPublished && <h3>Event status: Published</h3>}
            {!isPublished && <h3>Event status: Draft</h3>}
            {isPublished && <p>Check the live dashboard</p>}
            {!isPublished && <p>Complete the missing details Review</p>}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EventSectionTitle;
