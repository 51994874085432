import axios from "axios";
import { atom, selector, selectorFamily } from "recoil";

import HTTP from "./http";
import JS_FUCK from "./js.fuck";

const FAKE_DELAY = 1000;

export const AUTH_CHECK = selector({
  key: "AUTH_CHECK",
  get: async () =>
    new Promise((resolve) => {
      setTimeout(resolve.bind(null, JS_FUCK), FAKE_DELAY);
    }),
});

// Stages - Slots (Sessions)
export const GET_SLOT = selectorFamily({
  key: "SLOT_DATA",
  get: (id) => async () => ({ id }),
});

// Sponsor
export const GET_SPONSORS = selector({
  key: "SPONSOR_LIST",
  get: async () => [],
});
export const GET_SPONSOR = selectorFamily({
  key: "SPONSOR_DATA",
  get: (id) => async () => ({ id }),
});
export const POST_SPONSOR = async () => ({});
export const PUT_SPONSOR = async () => ({});
export const DELETE_SPONSOR = async () => ({});

// Attendees
export const GET_ATTENDEES = selector({
  key: "ATTENDEE_LIST",
  get: async () => [],
});
export const GET_ATTENDEE = selectorFamily({
  key: "ATTENDEE_DATA",
  get: (id) => async () => ({ id }),
});
export const POST_ATTENDEE = async () => ({});
export const PUT_ATTENDEE = async () => ({});
export const DELETE_ATTENDEE = async () => ({});

// Emails
export const GET_EMAILS = selector({
  key: "EMAIL_LIST",
  get: async () => [],
});
export const GET_EMAIL = selectorFamily({
  key: "EMAIL_DATA",
  get: (id) => async () => ({ id }),
});
export const POST_EMAIL = async () => ({});
export const PUT_EMAIL = async () => ({});
export const DELETE_EMAIL = async () => ({});

// Tickets
export const GET_TICKETS = selector({
  key: "TICKET_LIST",
  get: async () => [],
});
export const GET_TICKET = selectorFamily({
  key: "TICKET_DATA",
  get: (id) => async () => ({ id }),
});
export const POST_TICKET = async () => ({});
export const PUT_TICKET = async () => ({});
export const DELETE_TICKET = async () => ({});

// Badges
export const GET_BADGES = selector({
  key: "BADGE_LIST",
  get: async () => [],
});
export const GET_BADGE = selectorFamily({
  key: "BADGE_DATA",
  get: (id) => async () => ({ id }),
});
export const POST_BADGE = async () => ({});
export const PUT_BADGE = async () => ({});
export const DELETE_BADGE = async () => ({});

// FILES
export const POST_FILE = async () => ({});
