import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React, { useState } from "react";

const Status = withStyles(() => ({
  root: {
    padding: "5px 10px",
    borderRadius: "5px",
    color: "white",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "20px",
    textTransform: "capitalize",
    "&.__pending": {
      color: "white",
      backgroundColor: "#ffC107",
    },
    "&.__accepted": {
      color: "green",
      backgroundColor: "#54d62c",
    },
    "&.__declined": {
      color: "orange",
      backgroundColor: "#ff4842",
    },
  },
}))(({ classes, val }) => {
  return (
    <span
      className={cx(
        classes.root,
        { __pending: val === "pending" },
        { __accepted: val === "accepted" },
        { __declined: val === "declined" }
      )}
    >
      {val}
    </span>
  );
});

export default Status;
