import * as yup from "yup";

export const TABLE_HEAD = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "stats",
    numeric: false,
    disablePadding: false,
    label: "Stats",
  },
  {
    id: "hash",
    numeric: false,
    disablePadding: true,
    label: "Hash",
  },
  {
    id: "link",
    numeric: false,
    disablePadding: true,
    label: "Checkin link",
  }
];

export const EXPORT_HEAD = [
  "id",
  "title",
  "stats",
  "hash"
];


export const validationSchema = yup.object({
  title: yup.string().required().default("default")
});
