import "./style.scss";

import { Autocomplete, Box, Grid, TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { useField } from "formik";
import React from "react";

const EntitiesAutocomplete = ({
  label,
  name,
  placeholder,
  helper,
  // ...
  addNewHandler,
  // ...
  freeSolo,
  options = [],
  // ...
  sx = {},
  // ...
  xs = 12,
  md = 6,
}) => {
  const [field, meta, helpers] = useField({ name });
  // ...
  const handleChange = (e, values) => {
    const tags = values.map((v) => {
      return typeof v === "string" ? { label: v, value: v } : { ...v };
    });
    helpers.setValue(tags);
  };
  const handleDelete = (opt) => {
    const tags = field?.value?.filter((v) => v.value !== opt.value) || [];
    helpers.setValue(tags);
  };
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <Box className="EntitiesAutocomplete">
        {!!helper && (
          <div className="EntitiesAutocomplete__helper">{helper}</div>
        )}

        <div
          className="EntitiesAutocomplete__helper __new"
          onClick={() => addNewHandler()}
        >
          Add new
        </div>

        <Autocomplete
          multiple
          filterSelectedOptions
          freeSolo={freeSolo}
          sx={{ ...sx }}
          // ...
          name={name}
          value={field?.value || []}
          onChange={handleChange}
          onBlur={field.onBlur}
          options={options}
          // ...
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option) => {
            const _exist = field?.value?.filter(
              (opt) => opt.value === option.value
            );
            return !!_exist.length;
          }}
          // ...
          renderTags={(value, getTagProps) => {
            return value?.map((option, idx) => <div key={idx} />);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              name={name}
              placeholder={placeholder}
            />
          )}
        />
        <Box className="EntitiesAutocomplete__chips">
          {field?.value?.map((option, idx) => (
            <Chip
              key={idx}
              label={option.label}
              onDelete={() => handleDelete(option)}
            />
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default EntitiesAutocomplete;
