import * as yup from "yup";

export const TABLE_HEAD = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Ticket name",
  },
  {
    id: "priceLabel",
    numeric: false,
    disablePadding: true,
    label: "Ticket Price",
  },
  {
    id: "ticketStatus",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "mode",
    numeric: false,
    disablePadding: true,
    label: "Mode",
  },
  {
    id: "paymentProvider",
    numeric: false,
    disablePadding: true,
    label: "Provider",
  },
  {
    id: "paymentCount",
    numeric: false,
    disablePadding: true,
    label: "Count",
  },
  {
    id: "amountSum",
    numeric: false,
    disablePadding: true,
    label: "Total",
  },
];

export const EXPORT_HEAD = ["title", "priceLabel", "ticketStatus", "mode", "paymentProvider", "count", "totalAmount"];

export const addTicketSchema = yup.object({
  name: yup.string().required().default(""),
  price: yup.string().required().default(""),
  condition: yup.string().required().default(""),
});

export const getIfApplicable = (registrationFields) => {
  const { regLabel, regFields = [] } = registrationFields;
  const selectors = regFields.filter((f) => f.type === "select");
  // ...
  return regLabel === "customized" && selectors.length > 0;
};
