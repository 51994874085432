import React, { useState } from "react";
import { Box, Backdrop, Button, Modal } from "@material-ui/core";

import Gallery from "./";
import useGallery from "../../hooks/useGallery";

const GalleryModal = ({ open, onCloseHandler, onSetImage }) => {
  const [selected, setSelected] = useState(null);
  const { isLoading, data } = useGallery();
  const files = data?.data || [];
  // ...
  return (
    <Modal className="GalleryModal" open={open} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
      <Box className="GalleryModal__modal">
        <Box className="GalleryModal__content scrollbar">
          {files.length > 0 && (
            <Gallery
              files={files}
              isSelectable={true}
              selected={selected}
              onSelectHandler={(file) => {
                setSelected(file.id === selected?.id ? null : file);
              }}
            />
          )}
          {files.length === 0 && <Box>No files</Box>}
        </Box>
        <Box className="GalleryModal__footer">
          <div className="__actions">
            {!isLoading && (
              <Button className="__no-btn" onClick={() => onCloseHandler()}>
                Cancel
              </Button>
            )}
            <Button className="__yes-btn" onClick={() => onSetImage(selected)} disabled={!selected?.id}>
              Save
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default GalleryModal;
