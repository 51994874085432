import { DateTime } from "luxon";

/**
 * Convert a JavaScript date to an ISO date.
 * @param date - the date to convert
 * @returns The date in ISO format.
 */
export const dateToISO = (date) => {
  const dt = DateTime.fromJSDate(date);
  const utcDate = dt.setZone("UTC", { keepLocalTime: true });
  const reDate = utcDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return reDate.toISO();
};

/**
 * Given a JavaScript date, return the ISO 8601 string representation of that date.
 * @param date - the date to convert
 * @returns The date in ISO format.
 */
export const dateSameToISO = (date) => {
  const dt = DateTime.fromJSDate(date);
  const utcDate = dt.setZone("UTC", { keepLocalTime: true });
  return utcDate.toISO();
};

/**
 * Given a date in ISO format, return a date in UTC format.
 * @param date - The date to convert.
 * @returns A string representing the date in the format "YYYY-MM-DD"
 */
export const dateFromISO = (date) => {
  const dt = DateTime.fromISO(date);
  const offset = dt.offset;
  const utcDate = dt.minus(offset * 60000);
  return utcDate.toString();
};

/**
 * Convert a date string to a date ID string.
 * @param date - the date to convert
 * @returns The date in ISO format.
 */
export const dateToID = (date) => {
  const dt = DateTime.fromISO(date);
  const utcDate = dt.setZone("UTC", { keepLocalTime: true });
  const reDate = utcDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return reDate.toISO();
};

/**
 * Given a date and a slot, return the date and time of the slot.
 * @param d - the date to be modified
 * @param s - the start time of the slot
 * @returns The date and time in the format of ISO 8601.
 */
export const getSlotTime = (d, s) => {
  const dd = DateTime.fromISO(d, { zone: "UTC" });
  const ds = DateTime.fromISO(s, { zone: "UTC" });
  // ...
  const utcDate = dd.setZone("UTC", { keepLocalTime: false });
  const reDate = utcDate.set({
    hour: ds.hour,
    minute: ds.minute,
    second: ds.second,
    millisecond: ds.millisecond,
  });
  // ...
  return reDate.toISO();
};
