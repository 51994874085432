import "./style.scss";

import { Box, Card, Container, Grid } from "@material-ui/core";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Page from "../../components/Page";
// ...
import { stageSchema as validationSchema } from "./entries";
import useEmails from "../../hooks/useEmails";
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import 'grapesjs-preset-webpage'
import webpage from 'grapesjs-preset-webpage';
import newsletter from 'grapesjs-preset-newsletter';
import basic from 'grapesjs-blocks-basic';
import _ from "underscore";
import useGallery from "../../hooks/useGallery";
import { getRawImg } from "../../utils/files";
import useFile from "../../hooks/useFile";
import useEvent from "../../hooks/useEvent";
import { EntryText } from "../../components/Entry";
import EventButton from "../../components/EventButton";
import http from "../../api/http";

const EventEmailEditHTML = ({ ...props }) => {
  // ...
  const { id } = useParams();
  const { data, onUpdate, isLoading, testEmailTemplate} = useEmails();
  const [emailData, setEmailData] = useState(null);
  const [email, setEmail] = useState("");
  const [editorState, setEditorState] = useState(null);
  // ...
  const history = useHistory();
  // ...
  const { data: files} = useGallery();
  const { onUpload } = useFile();
  const { orgData } = useEvent();
  // ...
  var editor;
  // ...
  // ...
  // ...
  // ...
  // ...
  // ...
  // ...
  const sendTest = async (editor) => {
    await testEmailTemplate({...emailData, content: editorState.getHtml()?.replaceAll("{{{", "<%=").replaceAll("}}}", "%>")}, email);
  }
  // ...
  const uploadFile = async (e) => {
    const files = e.dataTransfer ? [...e.dataTransfer.files] : [...e.target.files];

    for await (let file of files){
      const fileRaw = await getRawImg(file);
      const uploadResponse = await onUpload(fileRaw);
      const assets = [];
      if(uploadResponse && uploadResponse.length){
        uploadResponse.map(r => {
          assets.push({
            src: r.url,
            name: r.name,
            width: r.width,
            height: r.height,
            type: "image"
          })
        });
      }      
      editor.AssetManager.add(assets);
    }    
  }
  // ...
  const saveEditor = (editor) => {
    onUpdate({id: +id, content: editor?.getHtml()?.replaceAll("{{{", "<%=").replaceAll("}}}", "%>")});
  }
  // ...
  const goBack = (editor, sender) => {
    if (confirm("Are you sure to leave the editor!")) {
      history.push(`/manage-emails`)
    }
  }
  // ...
  useEffect(() => {
    if(!emailData || !emailData.id) return;
    const assets = [];
    if(files?.data?.length){
      files?.data.map(f => assets.push({src: f.url, type: f.mime.split("/")[0], width: f.width, height: f.height, name: f.name}));      
    }  
    editor  = grapesjs.init({
        container: `#email-template-${emailData.id}` ,
        height: '100vh',
        width: '100%',
        plugins: [webpage, newsletter, basic ],
        autorender: true,
        noticeOnUnload: true,
        storageManager: false,
        assetManager: {
          storageType  	: '',
          storeOnChange  : true,
          storeAfterUpload  : true,
          upload: 'https://localhost/assets/upload',        //for temporary storage
          assets,
          autoAdd: true,
          uploadFile
        }
      });
    editor.Panels.addButton('options', [ { id: 'save', className: 'fa fa-floppy-o icon-blank', command: saveEditor, attributes: { title: 'Save Template' } }]);
    editor.Panels.addButton('options', [ { id: 'cancel', className: 'fa fa-list icon-blank', command: goBack, attributes: { title: 'Go back to templates' } }]);
    //editor.Panels.addButton('options', [ { id: 'send', className: 'fa fa-envelope icon-blank', command: sendTest, attributes: { title: 'Send Test' } }]);
    editor.setComponents(emailData?.content?.replaceAll("<%=", "{{{").replaceAll("%>", "}}}") || "");
    editor.Assets.clear().add(assets);
    setEditorState(editor);
  },[emailData, files]) 
  // ...
  useEffect(() => {
    if(!id || !data || !data.length) return;
    const emailTemplate = _.findWhere(data, {id: +id});
    if(emailTemplate){
      setEmailData(emailTemplate);
    }
  }, [data, id]);
  // ...
  useEffect(() => {
    //console.log("orgData: ", orgData);
    if(orgData?.email){
      setEmail(orgData.email);
    }else if(orgData?.owner?.email){
      setEmail(orgData.owner.email);
    }
  }, [orgData])
  // ...
  return (
    <Page title="Stages and schedules">
      <Container>
          <Grid  container spacing={2}>
            <Grid item xs={4}>
              <h3> subject : {emailData?.subject} </h3>
            </Grid>
            <Grid item xs={3}>
              <input type="email"
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                //topLabel="Test Email" 
                placeholder="Test Email" 
                style={{marginTop: "5px"}}
              />
            </Grid>
            <Grid item xs={2}>
              <EventButton handler={()=> {sendTest(editor)}} isLoading={isLoading}>Send test</EventButton>
            </Grid>          
          </Grid>
          <Box id={`email-template-${id}`}>

          </Box> 
      </Container>
    </Page>
  );
};

export default EventEmailEditHTML;
