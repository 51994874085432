import "./style.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import prettyBytes from "pretty-bytes";

import cx from "classnames";

const Gallery = ({ files, selected, onSelectHandler }) => {
  // ...
  return (
    <div className="EventGallery">
      {files?.map((file) => {
        const icon = file.ext.replace(".", "");
        const isImage = file.mime.startsWith("image/");
        // ...
        const isSelected = selected?.id === file.id;
        if (!isImage) return null;
        // ...
        return (
          <div key={file.id} className={cx("EventGalleryItem", { __selected: !!isSelected })} onClick={() => onSelectHandler(file)}>
            <div className="EventGalleryItem__preview">
              {isImage && <img src={file.url} alt="" />}
              {!isImage && <FileIcon extension={icon} {...defaultStyles[icon]} />}

              <div className="__checkbox">
                <CheckCircleIcon />
              </div>
            </div>
            <div className="EventGalleryItem__content">
              <div className="__name">{file.name}</div>
              <div className="__meta">
                {icon.toUpperCase()} - {prettyBytes(file.size)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Gallery;
