import "./style.scss";

import { Button } from "@material-ui/core";
import cx from "classnames";
import React from "react";

import LoadingDots from "../LoadingDots";

const EventButton = ({
  variant,
  className,
  // ...
  emails,
  handler,
  isLoading,
  disabled,
  children,
  sx = {},
  title = ""
}) => {
  return (
    <Button
      className={cx("EventButton", {
        [className]: !!className,
        [variant]: !!variant,
        __emails: emails
      })}
      title={title}
      size="large"
      variant="contained"
      disabled={disabled}
      onClick={handler}
      sx={{
        px: 4,
        width: "auto",
        minWidth: "242px",
        ...sx
      }}>
      {emails && <img src="/static/icons/email.svg" style={{ width: "28px", margin: "0 10px 0 0" }} />}
      {isLoading ? <LoadingDots /> : children}
    </Button>
  );
};

export default EventButton;
