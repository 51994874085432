import "react-image-crop/dist/ReactCrop.css";

import { Button } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import React, { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import _ from "underscore";

import LoadingDots from "../LoadingDots";

const CropImage = ({
  open,
  onClose,
  // ...
  name,
  raw,
  isRounded,
  meta = {},
  // ...
  isLoading,
  handler
}) => {
  const [crop, setCrop] = useState({ unit: "px", x: 0, y: 0, width: 0, height: 0 });
  const aspect = isRounded ? 1 : meta.width / meta.height || 1;
  // ...
  const onSaveCropHandler = () => {
    const { fileInfo, base64 } = raw;
    const { x, y, width, height } = crop;
    let data = { fileInfo, base64, crop: { x: parseInt(x, 10), y: parseInt(y, 10), width: parseInt(width, 10), height: parseInt(height, 10) } };
    // ...
    handler(name, data);
  };
  // ...
  const onCloseHandler = () => {
    setCrop({});
    onClose();
  };
  // ...
  useEffect(() => {
    if (!open) return setCrop({ unit: "%", x: 0, y: 0, width: 0, height: 0 });
    const { naturalWidth, naturalHeight } = raw;
    // ...
    let width = meta.width;
    let height = meta.height;
    // ...
    if (width > naturalWidth && height > naturalHeight) {
      if (naturalWidth > naturalHeight) {
        width = naturalHeight;
        height = naturalHeight * aspect;
      } else {
        width = naturalWidth;
        height = naturalWidth / aspect;
      }
    } else if (width > naturalWidth) {
      width = naturalWidth;
      height = naturalWidth / aspect;
    } else if (height > naturalHeight) {
      width = naturalHeight * aspect;
      height = naturalHeight;
    }
    // ...
    setCrop({ unit: "px", x: 0, y: 0, width, height });
  }, [open, raw, meta]);
  // ...
  // ...
  return (
    <Modal
      className="CropImage"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <div className="CropImage__modal">
          <div className="CropImage__body">
            <div className="CropImage__body__inner">
              <ReactCrop
                keepSelection={true}
                circularCrop={isRounded}
                aspect={aspect}
                minWidth={10}
                minHeight={10}
                // ...
                crop={crop}
                onChange={(c, p) => setCrop(c)} // NOTE: c en PX, p en %
                // locked={true}
              >
                <img src={raw.base64} />
              </ReactCrop>
            </div>
          </div>

          <div className="CropImage__footer">
            <div className="__meta">{meta.helper}</div>
            <div className="__actions">
              {!isLoading && (
                <Button className="__no-btn" onClick={() => onCloseHandler()}>
                  Cancel
                </Button>
              )}
              <Button className="__yes-btn" onClick={onSaveCropHandler}>
                <span>{isLoading ? <LoadingDots /> : "Save"}</span>
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CropImage;
