export const PATH_EVENT = {
  root: "/",
  setup: "/event-setup",
  stages: "/stages-schedules",
  stageItem: "/stages-schedules/:id",
  speakers: "/manage-speakers",
  sponsors: "/sponsors-partners",
  attendees: "/manage-attendees",
  emails: "/manage-emails",
  tickets: "/manage-tickets",
  checkin: "/manage-checkin",
  ticketItem: "/manage-tickets/:id",
  ticketPreview: "/manage-tickets/:id/preview",
  badges: "/manage-badges",
  badgeItem: "/manage-badges/:id",
  live: "/live-insights",
  carbon: "/live-carbon",
  emailEditHTML: "/manage-emails/:id/html",
};
