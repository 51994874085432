import { Box, Card, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

const EventDescriptiveCard = withStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  preview: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "126px",
    background: "rgba(0, 171, 85, 0.14)",
  },
  content: {
    flex: "1",
    fontFamily: "Montserrat, sans-serif",
    color: "#212B36",
    padding: "24px 30px",
    "& .__title": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "600",
      margin: "0 0 10px 0",
    },
    "& .__desc": {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: "500",
    },
    [theme.breakpoints.down("md")]: {
      padding: "12px 15px",
      "& .__title": {
        fontSize: "14px",
        lineHeight: "18px",
        margin: "0 0 5px 0",
      },
      "& .__desc": {
        lineHeight: "16px",
      },
    },
  },
}))(({ classes, icon, title, desc, isVisPriv, onLinkNav }) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  if (!title) return null;
  // ...
  return (
    <Card sx={{ height: isMobile ? "auto" : "140px" }}>
      <CardContent sx={{ height: "100%", padding: "0 !important" }}>
        <Box className={classes.root}>
          <Box className={classes.preview}>
            <img src={icon} alt="" />
          </Box>
          <Box className={classes.content}>
            <Box className="__title">{title}</Box>
            <Box className="__desc">
              <span>{desc}</span>
              {isVisPriv && (
                <span
                  className="__reg__link"
                  onClick={() => onLinkNav("/manage-emails")}
                >
                  here
                </span>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
});

export default EventDescriptiveCard;
