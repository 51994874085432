import "./style.scss";

// import { ExportToCsv } from "export-to-csv";
// import csvToJson from "papaparse";
import { withFormik } from "formik";
import React, { useEffect } from "react";
import { read, utils, writeFile } from "xlsx";

import { EntryFile, EntryText } from "../../../../components/Entry";
import EventButton from "../../../../components/EventButton";
import EventForm from "../../../../components/EventForm";
import { validationSchema } from "../../config";
// ...
const iniValues = validationSchema.cast();

const AddChekinList = ({
  checkinLists,
  isLoading,
  data,
  onCreateCheckinList,
  ...props
}) => {
  // ...
  
  // ...
  
  // ...
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...data } });
  }, []);
  // ...
  return (
    <div className="CheckinList">
      <div className="CheckinList__title __">
        Add single checkin list via the form below
      </div>
      <EventForm spacing={3}>
        <EntryText
          label="Checkin list title"
          type="text"
          name="title"
          error={checkinLists.includes(props?.values?.title) ? "title already in the list" : ""}
          md={12}
        />
      </EventForm>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "40px 0 0 0",
        }}
      >
        <EventButton
          isLoading={isLoading}
          handler={() =>  onCreateCheckinList(props.values) }
          disabled={checkinLists.includes(props?.values?.title)}
        >
          Save Checkin list
        </EventButton>
      </div>
    </div>
  );
};

const AddChekinListWithFormik = withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddChekinList);

export default AddChekinListWithFormik;
