import React from "react";
import { Redirect } from "react-router-dom";

import Guard from "../guard";
import Layout from "../layout";
import EventAttendees from "../views/EventAttendees";
import EventAuth from "../views/EventAuth";
import EventCarbon from "../views/EventCarbon";
import EventEmailEditHTML from "../views/EventEmailEditHTML";
import EventEmails from "../views/EventEmails";
import EventLive from "../views/EventLive";
import EventSetup from "../views/EventSetup";
import EventSpeakers from "../views/EventSpeakers";
import EventSponsors from "../views/EventSponsors";
import EventStageItem from "../views/EventStageItem";
import EventStages from "../views/EventStages";
import EventTicketItem from "../views/EventTicketItem";
import EventTicketPreview from "../views/EventTicketPreview";
import EventTickets from "../views/EventTickets";
import EventCheckin from "../views/EventCheckin";
// ...
import { PATH_EVENT } from "./paths";

const routes = [
  {
    exact: true,
    path: PATH_EVENT.root,
    component: EventAuth,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.setup,
    component: EventSetup,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.stages,
    component: EventStages,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.stageItem,
    component: EventStageItem,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.speakers,
    component: EventSpeakers,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.sponsors,
    component: EventSponsors,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.attendees,
    component: EventAttendees,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.emails,
    component: EventEmails,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.tickets,
    component: EventTickets,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.checkin,
    component: EventCheckin,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.ticketItem,
    component: EventTicketItem,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.ticketPreview,
    component: EventTicketPreview,
    guard: Guard,
    layout: null
  },
  {
    exact: true,
    path: PATH_EVENT.live,
    component: EventLive,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.carbon,
    component: EventCarbon,
    guard: Guard,
    layout: Layout
  },
  {
    exact: true,
    path: PATH_EVENT.emailEditHTML,
    component: EventEmailEditHTML,
    guard: Guard,
    layout: Layout
  },
  {
    component: () => <Redirect to={PATH_EVENT.setup} />
  }
];

export default routes;
