import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { atom, useRecoilStateLoadable, useRecoilValue } from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM_PACK = atom({ key: "EVENT_PACK", default: {} });

export default function useEventPack() {
  const THE_ID = useId();
  const [packData, setPackData] = useState(useRecoilValue(ATOM_PACK));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM_PACK);
  // ...
  const getAll = async () => {
    try {
      const pack = await HTTP.GET(`/packs/getCurrentPackEvent/${THE_ID}`);
      // ...
      setAtom({ ...pack });
    } catch (err) {
      setAtom({ });
      setIsLoading(false);
      setHasError(true);
      // ...
      //enqueueSnackbar("No active plan, you can upgrade to have more features", { variant: "error" });
      console.log("No active plan, you can upgrade to have more features");
    }
  };
  // ...
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setPackData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    packData
  };
}
