import { Grid } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";

import {
  EntryAutocompleteCheckbox,
  EntryEditor,
  EntrySelect,
  EntrySlug,
  EntrySwitch,
  EntryText,
  EntryStyleEditor,
  EntryDateTime,
  EntryAutocompleteTags
} from "../../../components/Entry";
import EventActions from "../../../components/EventActions";
import EventForm from "../../../components/EventForm";
// ...
import Page from "../../../components/Page";
import useEvent from "../../../hooks/useEvent";
import useEventPack from "../../../hooks/useEventPack";
import RegEntries from "../components/RegEntries";

import {
  EVENT_CATEGORIES,
  EVENT_FEES,
  EVENT_INTS,
  EVENT_MOTS,
  EVENT_TAGS,
  EVENT_TYPES,
  valSchemaInfo as validationSchema,
  formatEventInfo
} from "../entries";
import EntryMap from "../../../components/Entry/EntryMap";
const iniValues = validationSchema.cast();

const EventTypeStyle = styled("div")(() => ({
  position: "relative",
  margin: "10px 0 20px 0"
}));

const embedCodePlaceholder = "Insert content from external apps or website (like videos, images, or forms) into your event page using embed codes. +Learn More button that leads to help center + tools logo (design)";

const EventInfo = ({ currTab, setCurrTabHandler, ...props }) => {
  const [stripeReq, setStripeReq] = useState(false);
  const { isLoading, data, orgData, onUpdate } = useEvent();
  const isVirtualEvent = props?.values?.type === "virtual";
  const { packData } = useEventPack();
  // ...
  const onHandleFeesChange = (e) => {
    const value = e.target.value;
    const canCreatePaidEvents = !!orgData?.canCreatePaidEvents || !!orgData?.stripeAccountId;
    if (!canCreatePaidEvents && value === "paid") {
      return setStripeReq(true);
    }
    // ...
    props.setFieldValue("isFree", value);
  };
  // ...
  const onNavigateToStripeConfig = () => {
    const LIVE_URL = process.env.REACT_APP_EVEY_URL;
    const link = `${LIVE_URL}/profile/my-organization?goTo=payment`;
    window.open(link, "_blank");
  };
  // ...
  const onSaveData = async () => {
    if (isLoading) return;
    // ...
    const res = await onUpdate(formatEventInfo(props.values));
    if (res.error) return;
    setCurrTabHandler(currTab + 1);
  };
  // ...
  const canMatchmaking = () => {
    if(packData && ["starter", "business", "prestige"].includes(packData.type) ){
      return true;
    }
    return false
  }
  const canEmbedCode = () => {
    if(packData && ["business", "prestige"].includes(packData.type) ){
      return true;
    }
    return false
  }
  // ...
  useEffect(() => {
    if (!isVirtualEvent) return;
    props.setFieldValue("address", "Evey Virtual Venue");
  }, [isVirtualEvent]);
  // ...
  // ...
  useEffect(() => {
    if (isLoading) return;
    // ...
    props.resetForm({
      values: {
        ...iniValues,
        ...data,
        isFree: data?.isFree ? "free" : "paid",
        address: data?.type === "virtual" ? "Evey Virtual Venue" : data?.address
      }
    });
  }, [data, isLoading]);
  // ...
  // ...
  return (
    <Page title="Event Informations | Evey">
      <EventForm>
        <EntryText required label="Event name" name="title" placeholder="Event name" maxLength="100"/>
        <EntrySlug required label="Event slug" name="slug" placeholder="Event slug" />
        <EntryDateTime required label="Event start date" name="startDate" xs={12} md={6} />
        <EntryDateTime required label="Event end date" name="endDate" xs={12} md={6} />
        <EntryText required label="Short description" name="shortDescription" placeholder="Write a brief summary of the event" multiline minRows={10} maxRows={10} />

        <EntryEditor heightStatic = "__height2" label="Event Description" name="description" placeholder="Describe your event content, goals, audience ... What makes your event special !" />

        <EntryEditor  
          heightStatic = "__height3"
          xs={12} md={12}
          disabled={!canEmbedCode()} 
          isEmbedCode label= {"Insert External media/tools " + (!canEmbedCode() ? "(disabled)" : "")}
          name="embedCode" 
          placeholder={!canEmbedCode() ? "(Upgrade your plan to use this feature)" : embedCodePlaceholder} />
        
        <EntryStyleEditor
          heightStatic = "__height3"
          xs={12} md={12}
          disabled={!canEmbedCode()} 
          isEmbedCode label= {"Custom css " + (!canEmbedCode() ? "(disabled)" : "")}
          name="customCss" 
          placeholder={!canEmbedCode() ? "(Upgrade your plan to use this feature)" : "Custom css"}/>

        <Grid item xs={12} md={6}>
          <EventTypeStyle>
            <label className="custom-label required">Event type</label>
            <RegEntries
              name="type"
              value={props.values?.type}
              handleChange={props.handleChange}
              handleBlur={props.handleBlur}
              options={EVENT_TYPES}
            />
          </EventTypeStyle>
        </Grid>

        <Grid item xs={12} md={6}>
          <EventTypeStyle>
            <label className="custom-label required">Event Participation Fees</label>
            <RegEntries
              name="isFree"
              value={props.values?.isFree}
              handleChange={onHandleFeesChange}
              handleBlur={props.handleBlur}
              options={EVENT_FEES}
            />
          </EventTypeStyle>

          {stripeReq && (
            <div className="__stripe-connected">
              <div className="__inner">
                <div className="__icon">
                  <InfoIcon />
                </div>
                <div className="__content">
                  <div>You can not create paid events</div>
                  <div className="__setting">
                    First please, setup your <span onClick={onNavigateToStripeConfig}>Stripe account</span>
                  </div>
                  <div className="__setting">
                    Or better contact Evey 
                  </div>
                </div>
              </div>
            </div>
          )}
        </Grid>

        <EntryText required label="Event location" name="address" disabled={isVirtualEvent} placeholder="Event location" xs={12} md={6} />

        <EntrySelect label="Event Category" name="category" options={EVENT_CATEGORIES} />

        <Grid item xs={12} md={12} />
        {/* <EntrySwitch name="requireVisio" label="This event requires a visio conference" /> */}

        <EntryAutocompleteTags
          xs={12} md={6}
          freeSolo
          name="tags"
          helper="Press ENTER to add tags"
          options={EVENT_TAGS}
        />

        {["physical", "hybrid"].includes(props?.values?.type) && <EntryMap xs={12} md={12} name="location" topLabel="Location" />}

        <Grid container spacing={4} className="__matchmaking">
          <Grid item xs={12} md={12} className="__matchmaking__top">
            <div className="__title">Matchmaking setting </div>
            <div className="__sub">
              Add interests and motives to help attendees find the matches,
              <span>learn more</span>
            </div>
            <div className="__title">
              {canMatchmaking()? "" : "Upgrade your plan to use this feature!"}
            </div>
          </Grid>

          <EntryAutocompleteCheckbox disabled={!canMatchmaking()} freeSolo name="interests" helper="Select event interests" options={EVENT_INTS} />

          <EntryAutocompleteCheckbox disabled={!canMatchmaking()} freeSolo name="motives" helper="Select motives" options={EVENT_MOTS} />
        </Grid>
      </EventForm>

      <EventActions
        save={{
          isActive: true,
          label: "Save & continue",
          isLoading,
          isDisabled: isLoading,
          handler: onSaveData
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(EventInfo);
