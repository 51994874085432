import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React, { useState } from "react";

const Status = withStyles(() => ({
  root: {
    padding: "5px 10px",
    borderRadius: "5px",
    color: "white",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "20px",
    textTransform: "capitalize",
    "&.__true": {
      color: "#229A16",
      backgroundColor: "rgba(84, 214, 44, 0.16)",
    },
    "&.__none": {
      display: "none",
    },
  },
}))(({ classes, value }) => {
  // ...
  return (
    <span className={cx(classes.root, { __true: !!value }, { __none: !value })}>
      {value}
    </span>
  );
});

export default Status;
