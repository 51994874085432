import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import xlsExport from "xlsexport";

import Scrollbar from "../Scrollbar";
import TableCellActions from "./TableCellActions";
import TableHeader from "./TableHeader";
import TableToolbar from "./TableToolbar";
import _ from "underscore";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(data, searchLabel, searchFor, comparator) {
  const stabilizedThis = data
    .filter((el) => {
      const search = searchFor.toLowerCase();
      const label = el?.[searchLabel]?.toLowerCase() || "";
      const found = label.indexOf(search) > -1;
      // ...
      return searchFor ? found : true;
    })
    .map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EventEntityTable = ({
  label = "",
  head,
  data,
  // ...
  searchLabel,
  exportName,
  exportHead,
  // ...
  noEdit,
  // ...
  onResend,
  onEditHandler,
  onDeleteHandler,
  disabledOptions = false,
  children
}) => {
  const [searchFor, setSearchFor] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // ...
  const isSelected = (id) => selected.indexOf(id) !== -1;
  // ...
  const onResendHanlder = async (row) => {
    const speakerId = row?.id;
    const sessionIds = row?.sessionsBase || [];
    // ...
    if (!!speakerId && sessionIds.length > 0) {
      await onResend({ speakerId, sessionIds });
    }
  };
  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // ...
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  // ...
  const onExportXlsx = () => {
    if (!exportName) return;
    // ...
    let customHead = [];
    const newData = [];
    data.map((d) => {
      customHead = [...customHead, ...Object.keys(d.custom || {})];
      customHead = _.uniq(customHead);
      const newCustom = {...d.custom};
      //console.log("newCustom : ", newCustom);
      const newD = {...d};
      delete newD.custom;
      newData.push({...newCustom, ...newD })
    });

    //console.log("newData export : ", newData);
    const exportData = newData.map((d) => {
      let out = {};
      [...exportHead, ...customHead].forEach((n) => (out[n] = d[n]));
      // ...
      return out;
    });
    // ...
    const xls = new xlsExport(exportData, exportName);
    xls.exportToXLS("export.xls");
  };
  // ...
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  // ...
  return (
    <>
      <Scrollbar>
        <TableToolbar
          searchFor={searchFor}
          setSearchFor={setSearchFor}
          // ...
          hasExport={!!exportName}
          exportHandler={onExportXlsx}
          children={children }
        />
        <TableContainer sx={{ minWidth: 800 }}>
          <Table size="medium">
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={head}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {stableSort(data, searchLabel, searchFor, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // ...
                  return (
                    <TableRow hover tabIndex={-1} key={row.id} selected={isItemSelected}>
                      {head
                        .map((i) => i.id)
                        .map((d) => (
                          <TableCell key={d} id={labelId} component="th" scope="row" padding="none">
                            {row[d]}
                          </TableCell>
                        ))}

                      <TableCellActions
                        idx={index}
                        data={row}
                        label={label}
                        // ...
                        noEdit={noEdit}
                        disabledOptions={disabledOptions}
                        // ...
                        onEditHandler={onEditHandler}
                        onDeleteHandler={onDeleteHandler}
                        onResendHanlder={() => onResendHanlder(row)}
                      />
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: "relative" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
};

export default EventEntityTable;
