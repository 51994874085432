import "./style.scss";

import cx from "classnames";
import React from "react";
import Moment from "react-moment";

const PreviewTop = ({ event = {}, data = {}, style = {} }) => {
  //# Org / Event
  const orgLogo = event?.card?.url || event?.organization?.logo?.url;
  const orgName = event?.organization?.libele;
  const eventTitle = event?.title;
  const hasQrCode = data?.showQrcode;

  //# Holders
  let holder0 = data.heading;
  if (holder0 === "") holder0 = data.text;
  let holder1 = data.subHeading1;
  if (holder1 === "") holder1 = data.subText1;
  let holder2 = data.subHeading2;
  if (holder2 === "") holder2 = data.subText2;
  let holder3 =
    data.subHeading3 === "ticket-name" ? data?.title : data.subText3;
  // ...
  return (
    <div className="PreviewTop" style={{ ...style }}>
      <div className="__logo">
        <img src={orgLogo} alt="" />
      </div>
      <div className="__title">{eventTitle}</div>

      <div className="__details">
        <div className="__details__body">
          <div className="__info">
            <div className="__info__num">
              <span className="__label">Ticket </span>
              <span className="__value">#002158413</span>
            </div>
            <div className={cx("__info__name", { __key: data.heading !== "" })}>
              {holder0 || ""}
            </div>
            <div
              className={cx("__info__pos", { __key: data.subHeading1 !== "" })}
            >
              {holder1 || ""}
            </div>
            <div
              className={cx("__info__org", { __key: data.subHeading2 !== "" })}
            >
              {holder2 || ""}
            </div>
          </div>
          <div className="__qr">
            {hasQrCode && <img src="/static/icons/qr.svg" alt="" />}
          </div>
        </div>
        <div className="__details__type">{holder3 || ""}</div>
      </div>

      <div className="__date">
        <Moment format="dddd, MMMM Do YYYY [at] HH:mm a">
          {event?.startDate}
        </Moment>
        <br />
        <Moment format="dddd, MMMM Do YYYY [at] HH:mm a">
          {event?.endDate}
        </Moment>
        {/* Monday , December 27th 2021 at 8:00 AM Friday 31st December 2021 at 3PM */}
      </div>
      <div className="__who">{event?.address || ""}</div>
    </div>
  );
};

export default PreviewTop;
