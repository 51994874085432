// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";

import EventActions from "../../../components/EventActions";
import Page from "../../../components/Page";
import useEvent from "../../../hooks/useEvent";
import useEventPack from "../../../hooks/useEventPack";
import useRegFields from "../../../hooks/useRegFields";
import RegSection from "../components/RegSection";
import { EVENT_VISIBILITIES, REGISTRATION_METHODS, valSchemaVis as validationSchema, formatEventVis } from "../entries";
const iniValues = validationSchema.cast();

const EventRegistration = ({ currTab, setCurrTabHandler, onBackHandler, ...props }) => {
  const [disabledVisibilities, setDisabledVisibilities] = useState({});
  const [disabledRegistrationMethods, setDisabledRegistrationMethods] = useState({});
  const { isLoading: isLE, data: dataE, onUpdate: onUE } = useEvent();
  const { isLoading: isLR, data: dataR, onUpdate: onUR } = useRegFields();
  const { packData } = useEventPack();
  const isLoading = isLE || isLR;
  // ...
  const onSaveData = async () => {
    if (isLoading) return;
    // ...
    const values = { ...props.values };
    const { regLabel, regFields } = values;
    // ...
    delete values.regLabel;
    delete values.regFields;
    // ...
    const x = await onUE(formatEventVis(values));
    const y = await onUR({ regLabel, regFields });
    // ...
    if (x.error || y.error) return;
    setCurrTabHandler(currTab + 1);
  };
  // ...
  const canUsePrivate = () => {
    return packData.type && ["business", "prestige"].includes(packData.type) && true;
  }
  const canUseRestricted = () => {
    return packData.type && packData.type && true;
  }
  const canEditRegistration = () => {
    return packData.type && ["starter", "business", "prestige"].includes(packData.type) && true;
  }
  // ...
  useEffect(() => {
    if (isLoading) return;
    const isFree = dataE?.isFree ? "free" : "paid";
    props.resetForm({ values: { ...iniValues, ...dataE, ...dataR, isFree } });
  }, [dataE, dataR, isLoading]);
  // ...
  useEffect(() => {
    if (!packData) return;
    setDisabledVisibilities(s => {
      const newS = {};
      if(!canUsePrivate()){ //console.log("!canUsePrivate()")
        newS[EVENT_VISIBILITIES[2].value] = true;
      }
      if(!canUseRestricted()){//console.log("!canUseRestricted()")
        newS[EVENT_VISIBILITIES[1].value] = true;
      }
      return newS;
    });
    setDisabledRegistrationMethods(s => {
      const newS = {};
      if(!canEditRegistration()){ //console.log("!canUsePrivate()")
        newS[REGISTRATION_METHODS[2].value] = true;
      }
      return newS;
    });
    
  }, [packData]);
  // ...
  return (
    <Page title="Event Registration | Evey">
      <RegSection
        title="Define Event visibility and access"
        helper="Please define the requested action for this visibility type"
        entries={{
          name: "access",
          options: EVENT_VISIBILITIES
        }}
        disabled={disabledVisibilities}
      />

      <RegSection
        title="Manage registration fields"
        helper="Please define the requested action for this visibility type"
        entries={{
          name: "regLabel",
          options: REGISTRATION_METHODS
        }}
        disabled={disabledRegistrationMethods}
      />

      <EventActions
        save={{
          isActive: true,
          label: "Save & continue",
          isLoading,
          isDisabled: isLoading,
          handler: onSaveData
        }}
        cancel={{
          isActive: true,
          label: "Back",
          handler: onBackHandler
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(EventRegistration);
