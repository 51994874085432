import { Box, Grid } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";

import { EntrySelect, EntrySwitch, EntryText } from "../../../components/Entry";
import EventForm from "../../../components/EventForm";

const Details = ({ isPaidEvent, isAttsTarget, registrationFields }) => {
  const { values, setFieldValue } = useFormikContext();
  const { regLabel, regFields = [] } = registrationFields;
  const selectors = regFields.filter((f) => f.type === "select");
  // ...
  const isCustomRegFields = regLabel === "customized" && selectors.length > 0;
  const currConditionKey = values?.conditionKey;
  const currConditionValue = values?.conditionValue;
  // ...
  let conditionKeyOptions = [{ label: "Empty", value: "" }];
  let conditionValueOptions = [];
  // ...
  selectors.forEach((o, idx) => {
    const label = o.label;
    const value = o.key;
    conditionKeyOptions.push({ label, value });
    // ...
    const valOptions = o?.settings?.options?.values || [];
    if (value === currConditionKey) {
      conditionValueOptions = Array.isArray(valOptions) ? valOptions : [];
    }
  });
  // ...
  useEffect(() => {
    if (!isCustomRegFields) {
      setFieldValue("conditionKey", "");
      setFieldValue("conditionValue", "");
      return;
    }
    // ...
    if (!currConditionKey && conditionKeyOptions.length > 0) {
      setFieldValue("conditionKey", conditionKeyOptions[0].value);
      setFieldValue("conditionValue", "");
    } else if (currConditionKey && !currConditionValue && conditionKeyOptions.length > 0) {
      const fOpt = conditionValueOptions?.[0]?.value || "";
      setFieldValue("conditionValue", fOpt);
    }
  }, [isCustomRegFields, currConditionKey]);
  // ...
  return (
    <div className="TicketStep __details">
      <div className="__groups">
        <div className="__group">
          <h3 className="__group__title">Build customized tickets for specific type of attendees</h3>
          <h4 className="__group__sub">Start with ticket category and pricing</h4>

          <Box>
            <EventForm>
              <EntryText required label="Ticket name" name="title" placeholder="Student pass" xs={12} md={12} />
              <EntryText
                // required
                label="Ticket description"
                name="paragraph"
                placeholder="Example: ticket includes lunch, hotel, etc"
                multiline
                minRows={2}
                maxRows={2}
                xs={12}
                md={12}
              />
              <EntrySelect
                xs={12}
                md={12}
                required
                topLabel="Target audience"
                name="targetAudience"
                options={[
                  { label: "Registered Attendees", value: "attendees" },
                  { label: "Speakers", value: "speakers" },
                  { label: "Sponsors", value: "sponsors" }
                ]}
              />
            </EventForm>
          </Box>

          {isPaidEvent && isAttsTarget && (
            <>
              <Box sx={{ mt: "50px" }}>
                <EventForm>
                  <EntrySwitch name="isFree" label="This ticket is free" labelPlacement="start" xs={5} md={5} />
                  <Grid item xs={2} md={2} />
                  <EntrySwitch name="isActive" label="This ticket is active" labelPlacement="start" xs={5} md={5} />
                </EventForm>
              </Box>
              <Box sx={{ mt: "50px" }}>
                <EventForm>
                  {!values?.isFree && (
                    <>
                      <EntryText noMaxLength label="Ticket price" name="price" placeholder="100.00" type="number" xs={12} md={6} />
                      <EntrySelect
                        xs={12}
                        md={6}
                        label="Currency"
                        name="currency"
                        options={[
                          { label: "EUR", value: "eur" },
                          { label: "USD", value: "usd" },
                          { label: "TND", value: "tnd" }
                        ]}
                      />
                      <EntrySelect
                        xs={12}
                        md={6}
                        label="Mode"
                        name="mode"
                        options={[
                          { label: "Prod", value: "prod" },
                          { label: "Test", value: "test" }
                        ]}
                      />
                    </>
                  )}
                </EventForm>
              </Box>
            </>
          )}
        </div>

        {isCustomRegFields && isAttsTarget ? (
          <>
            <div className="__sep" />
            <div className="__group">
              <div className="__group__note">
                <div className="__icon">
                  <InfoIcon />
                </div>
                <div className="__content">
                  <h3 className="__title">This ticket applies when a registration field is matching a specific condition</h3>
                  <h4 className="__sub">Eg. when the attendee enters “student” in the occupation filed</h4>
                </div>
              </div>

              <EventForm sx={{ mb: "60px" }}>
                <EntrySelect
                  topLabel="When the event attendee answer of this field"
                  name="conditionKey"
                  options={conditionKeyOptions}
                  xs={12}
                  md={12}
                />
                {!!currConditionKey && !!conditionValueOptions?.length && (
                  <EntrySelect topLabel="Is equal ( == ) to" name="conditionValue" options={conditionValueOptions} xs={12} md={12} />
                )}
              </EventForm>
            </div>
          </>
        ) : (
          <div className="__group" />
        )}
      </div>
    </div>
  );
};

export default Details;
