import * as yup from "yup";

export const validationSchema = yup
  .object({
    subject: yup.string().required().default(""),
    content: yup.string().required().default(""),
    category: yup.string().required().default("speaker"),
    // ...
    files: yup.array().nullable().notRequired()
    // files: yup.array().default([]),
  })
  .default({ subject: "", content: "", category: "speaker", files: [] });

export const templateInit = {
  invitation: `Dear <%= participant.email %>, <br> We are thrilled to invite you to our event, please register here : <%= callbackUrl %> `,
  registration: `Dear participant your registration is confirmed, <br> with e-mail address <%= participant.email %>, <br> Thank you`,
  speaker: `Hello <%= speaker.firstName %> <%= speaker.lastName %>, <br> You have been selected to participate on the event: <%= event.title %> as a speaker ,<br>Confirm <a href="<%= callbackUrl %>">here</a>`,
  sponsor: `Dear sponsor <%= sponsor.name %>,<br> your are sponsor in the event <%= event.title %>, <br> with e-mail address <%= sponsor.email %>, <br> Thank you`
};

export const categories = [
  { label: "Registration template", value: "registration" },
  { label: "Invitation template", value: "invitation" },
  { label: "Speaker template", value: "speaker" },
  { label: "Sponsor template", value: "sponsor" },
  { label: "Speaker Confirmation template", value: "speaker_confirmation" }
];

export const holders = {
  invitation: {
    email: "<%= participant.email %>",
    callbackUrl: "<%= callbackUrl %>"
  },
  registration: {
    email: "<%= participant.email %>",
    callbackUrl: "<%= callbackUrl %>"
  },
  speaker: {
    firstName: "<%= speaker.firstName %>",
    lastName: "<%= speaker.lastName %>",
    position: "<%= speaker.position %>",
    company: "<%= speaker.company %>",
    phone: "<%= speaker.phone %>",
    description: "<%= speaker.description %>",
    callbackUrl: "<%= callbackUrl %>"
  },
  sponsor: {
    level: "<%= sponsor.level %>",
    type: "<%= sponsor.type %>",
    name: "<%= sponsor.name %>",
    description: "<%= sponsor.description %>",
    adresse: "<%= sponsor.adresse %>",
    slogan: "<%= sponsor.slogan %>",
    sector: "<%= sponsor.sector %>",
    phone: "<%= sponsor.phone %>"
  },
  speaker_confirmation: {
    firstName: "<%= speaker.firstName %>",
    lastName: "<%= speaker.lastName %>",
    position: "<%= speaker.position %>",
    company: "<%= speaker.company %>",
    phone: "<%= speaker.phone %>",
    description: "<%= speaker.description %>",
    title: "<%= speaker.title %>",
    start: "<%= speaker.start %>",
    duration: "<%= speaker.duration %>",
    slug: "<%= speaker.slug %>",
    callbackUrl: "<%= callbackUrl %>"
  }
};

export const formatHolder = (k) => `<%= participant.${k} %>`;

export const formatTag = (h) =>
  h
    .replace(/<%= /, "")
    .replace(/ %>/, "")
    .replace(/participant\./, "")
    .replace(/speaker\./, "")
    .replace(/sponsor\./, "");

export const getHolder = (extraFields) => {
  let customFields = {};
  // ...
  extraFields.forEach((f) => {
    customFields[f] = formatHolder(f);
  });
  // ...
  return {
    ...holders,
    invitation: { ...holders.invitation, ...customFields },
    registration: { ...holders.registration, ...customFields }
  };
};
