import "./style.scss";

import { Box, Card, Container, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useHistory, useParams } from "react-router-dom";

import AddSpeaker from "../../components/AddSpeaker";
import AddSponsor from "../../components/AddSponsor";
import {  EntryText } from "../../components/Entry";
import EventActions from "../../components/EventActions";
import EventButton from "../../components/EventButton";
import EventEntityDrawer from "../../components/EventEntityDrawer";
import EventEntityTable from "../../components/EventEntityTable";
import EventForm from "../../components/EventForm";
import EventSectionTitle from "../../components/EventSectionTitle";
import Page from "../../components/Page";
import useFile from "../../hooks/useFile";
import useSlots from "../../hooks/useSlots";
import useSpeakers from "../../hooks/useSpeakers";
import useSponsors from "../../hooks/useSponsors";
import useStage from "../../hooks/useStage";
import { dateToID, getSlotTime } from "../../utils/dates";
import { getDefaults } from "../../utils/defaults";
import AddSlot from "./components/AddSlot";
import DatesAdd from "./components/DatesAdd";
// ...
import { getDatesFromSlots, stageSchema as validationSchema, TABLE_HEAD } from "./entries";
import useEventPack from "../../hooks/useEventPack";
const iniValues = validationSchema.cast();

const SlotStartTime = ({ time }) => {
  if (!time) return "";
  return <Moment format="hh:mm a">{time}</Moment>;
};
const SlotSpeaker = ({ slot }) => {
  let out = "";
  const s = slot?.speakers?.length;
  if (s === 1) out = "1 speaker";
  if (s > 1) out = `${s} speakers`;
  // ...
  return out;
};

const EventStageItem = ({ ...props }) => {
  const history = useHistory();
  const dValues = getDefaults();
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { id: stageId } = useParams();
  const { isLoading: isLS, data: stage, onUpdate } = useStage(stageId);
  const { isLoading: isLSS, data: slots, onUpdate: onUpdateSlot, onRemove: onRemoveSlot, onUpdateDates } = useSlots(stageId);
  const { isLoading: isLSPKS, data: speakersList, onCreate: onCreateSpeaker } = useSpeakers();
  const { isLoading: isLSPNS, data: sponsorsList, onUpdate: onCreateSponsor } = useSponsors();
  const { isLoading: isLF, onUpload } = useFile();
  const { packData } = useEventPack();
  // ...
  const isLoading = isLS || isLSS || isLSPKS || isLSPNS || isLF;
  // ...
  const [drawer, setDrawer] = useState({ open: false, data: {} });
  // ...
  const [drawerSpeaker, setDrawerSpeaker] = useState({ open: false, data: {} });
  // ...
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [drawerSponsor, setDrawerSponsor] = useState({
    open: false,
    data: {},
    check: true
  });
  // ...
  const [dateTab, setDateTab] = useState(0);
  const [datesList, setDatesList] = useState([]);
  const hasDates = datesList?.length > 0;
  const currDate = datesList?.[dateTab];
  // ...
  const slotsList = slots
    ?.filter((s) => dateToID(s.start) === currDate)
    ?.map((s) => {
      let durationLabel = "";
      if (s.duration) durationLabel = s.duration + " min";
      // ...
      return {
        ...s,
        durationLabel,
        startTime: <SlotStartTime time={s.start} />,
        speakerId: <SlotSpeaker slot={s} />
      };
    });
  // ...
  const onEditStage = async (init = {}) => {
    await onUpdate({ ...props.values, ...init });
    if (!init?.id) history.push("/stages-schedules");
  };
  // ...
  const onEditSlots = async (idx, date) => {
    const tmp =
      slots
        ?.filter((s) => dateToID(s.start) === currDate)
        ?.map((s) => {
          return { id: s.id, start: getSlotTime(date, s.start) };
        }) || [];
    // ...
    if (tmp.length > 0) {
      await onUpdateDates(tmp);
    }
    // ...
    setDatesList((dates) => dates.map((d, ix) => (idx === ix ? date : d)).sort((a, b) => (a > b ? 1 : -1)));
  };
  // ...
  const onEditSlot = async (slot) => {
    const speakerIds = slot?.speakers?.map((s) => s.value) || [];
    const speakers = speakersList.filter((s) => speakerIds.indexOf(s.user.id) > -1);
    // ...
    if(!slot.streamConf) slot.streamConf = {};
    const streamConf = slot.streamConf ? {...slot.streamConf} : {};
    //console.log("streamConf: ", streamConf)
    //console.log("slot.disableLiveStream: ", slot.disableLiveStream)
    streamConf.disableLiveStream = slot.disableLiveStream;
    streamConf.publicMeeting= slot.disableLiveStream;
    //console.log("{ ...slot, streamConf, speakers }: ", { ...slot, streamConf, speakers })
    await onUpdateSlot({ ...slot, streamConf, speakers });
    setDrawer({ open: false, data: {} });
  };
  const onDeleteSlot = (slot) => {
    onRemoveSlot(slot);
  };
  // ...
  const onAddSpeaker = async (data) => {
    await onCreateSpeaker(data);
    setDrawerSpeaker({ open: false, data: {}, idx: "" });
    setDrawer((s) => ({ ...s, open: true }));
  };
  // ...
  const onAddSponsor = async (data) => {
    await onCreateSponsor(data);
    setDrawerSponsor({ open: false, data: {}, idx: "", check: true });
    setDrawer((s) => ({ ...s, open: true }));
  };
  // ...
  const onFetchSponsor = async (s, isNew) => {
    if (!isNew) {
      let sponsor = { ...s, sponsor: s.id };
      delete sponsor.id;
      //...
      setDrawerSponsor((o) => ({
        ...o,
        data: { ...o.data, ...sponsor, type: "sponsor", level: "silver" },
        check: false
      }));
      // ...
      setIsReadOnly(true);
    } else {
      // ...
      setDrawerSponsor((o) => ({ ...o, data: { ...s }, check: false }));
      setIsReadOnly(false);
    }
  };
  // ...
  const onUploadFile = async (name, data) => {
    if (isLoading) return;
    // ...
    const res = await onUpload(data);
    const ids = res?.map((d) => d.id);
    // ...
    await onUpdate({
      id: parseInt(stageId, 10),
      ...props.values,
      [name]: ids[0]
    });
  };
  // ...
  const canCreateSession = () => {
    if(!slots || !slots.length || (slots.length<6 && packData.type=="starter") || (slots.length<8 && packData.type=="business") || packData.type=="prestige"){
      return true;
    }
    return false;
  }
  // ...
  useEffect(() => {
    const datesList = getDatesFromSlots(slots);
    setDatesList(datesList);
  }, [slots]);
  // ...
  useEffect(() => {
    if (isLS || !stage) return;
    props.resetForm({ values: { ...iniValues, ...stage } });
  }, [stage]);
  // ...
  let bcLinks = [{ name: "Event management" }, { name: "Virtual spaces and schedules" }, { name: stage?.label || "New stage" }];
  if (isMobile) {
    delete bcLinks[2];
  }
  // ...
  return (
    <Page title="Virtual spaces and schedules">
      <Container>
        <Box>
          <EventSectionTitle title="Event Agenda" subTitle="Create agenda and sessions" src="/static/setup/title/stages.svg" />

          <Grid container spacing={8}>
            <EventForm item xs={12} md={6} alignContent="flex-start" alignItems="flex-start">
              <EntryText required label="Virtual space name" name="label" placeholder="Virtual space name" xs={12} md={12} />

              {/* <EntryEditor
                required
                label="Virtual space description"
                name="description"
                placeholder="Virtual space description"
                multiline
                minRows={4}
                maxRows={4}
                sx={{ margin: "40px 0 0 0 !important" }}
                xs={12}
                md={12}
              />

              <EntrySwitch name="requireVisio" label="This virtual space requires a visio conference" xs={12} md={12} /> */}
            </EventForm>

           {/*  <EventForm item xs={12} md={6} sx={{ height: "377px" }}>
              <EntryImage
                required
                preview
                label="Virtual space banner (600 x 400 px)"
                placeholder="Select virtual space banner"
                name="card"
                defaultK="stageCard"
                xs={12}
                md={12}
                // ...
                isLoading={isLoading}
                onUpload={onUploadFile}
                meta={{
                  width: 600,
                  height: 400,
                  helper: "Virtual space banner 600 x 400 px"
                }}
              />
            </EventForm> */}
          </Grid>
                
          <EventSectionTitle title="Virtual space agenda" subTitle="Create agenda and sessions for this virtual space" sx={{ marginTop: "70px" }}>
            
            <EventButton disabled={!canCreateSession()} isLoading={isLoading} handler={() => setDrawer({ open: true, _id: null })}>
              {isMobile && "Add"}
              {!isMobile && "Add new session"}
            </EventButton>
          </EventSectionTitle>

          {hasDates && (
            <>
              <DatesAdd
                currDate={currDate}
                hasDates={hasDates}
                datesList={datesList}
                setDatesList={setDatesList}
                dateTab={dateTab}
                setDateTab={setDateTab}
                // ...
                onEditSlots={onEditSlots}
              />

              <Box className="EventStageItem__group __title">
                <span>Agenda of </span>
                <span>
                  <Moment format="D MMM YYYY">{currDate}</Moment>
                </span>
              </Box>
              <Box className="EventStageItem__group">
                {datesList !== 0 && (
                  <Card sx={{ borderRadius: "0 0 8px 8px" }}>
                    <EventEntityTable
                      label="slot"
                      head={TABLE_HEAD}
                      data={slotsList}
                      onEditHandler={(data) => setDrawer({ open: true, data })}
                      onDeleteHandler={(data) => onDeleteSlot(data)}
                    />
                  </Card>
                )}
              </Box>
            </>
          )}
        </Box>

        <EventEntityDrawer
          open={drawer.open}
          closeDrawer={() => setDrawer({ open: false, data: {} })}
          title={drawer?.data?.id ? "Edit session" : "Add new session"}
          sub="Enter slot details"
          width="800px">
          <AddSlot
            currDate={currDate}
            data={drawer.data}
            onEditSlot={onEditSlot}
            isLoading={isLoading}
            // ...
            speakersList={speakersList}
            sponsorsList={sponsorsList}
            // ...
            onAddNewSpeaker={(data) => {
              setDrawer({ open: false, data });
              setDrawerSpeaker({ open: true, data: {}, idx: "" });
            }}
            onAddNewSponsor={(data) => {
              setDrawer({ open: false, data });
              setDrawerSponsor({ open: true, data: {}, idx: "", check: true });
            }}
          />
        </EventEntityDrawer>

        <EventEntityDrawer
          open={drawerSpeaker.open}
          closeDrawer={() => {
            setDrawer((s) => ({ ...s, open: true }));
            setDrawerSpeaker({ open: false, data: {}, idx: "" });
          }}
          title={drawerSpeaker?.data?.id ? "Edit speaker" : "Add new speaker"}
          sub="Enter speaker details"
          width="800px">
          <AddSpeaker  data={drawerSpeaker.data} onAddSpeaker={onAddSpeaker} onEditSpeaker={null} isLoading={isLoading} />
        </EventEntityDrawer>
        <EventEntityDrawer
          open={drawerSponsor.open}
          closeDrawer={() => {
            setDrawer((s) => ({ ...s, open: true }));
            setDrawerSponsor({ open: false, data: {}, idx: "", check: true });
          }}
          title={drawerSponsor?.data?.id ? "Edit sponsor" : "Add new sponsor"}
          sub={drawerSponsor.check ? "" : "Enter sponsor details"}
          width={drawerSponsor?.check ? "490px" : "900px"}>
          <AddSponsor
            idx={drawerSponsor.idx}
            data={drawerSponsor.data}
            check={drawerSponsor.check}
            // ...
            isReadOnly={isReadOnly}
            onEditSponsor={onAddSponsor}
            onFetchSponsor={onFetchSponsor}
            // ...
            isLoading={isLoading}
          />
        </EventEntityDrawer>
      </Container>

      <EventActions
        save={{
          isActive: true,
          label: "Save virtual space",
          isLoading,
          handler: onEditStage
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(EventStageItem);
