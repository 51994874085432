import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { atom, useRecoilCallback, useRecoilStateLoadable, useRecoilValue } from "recoil";

import HTTP from "../api/http";
import { sanatizeEditorData } from "../utils/editor";
import useId from "./useId";

const ATOM = atom({ key: "EVENT", default: {} });
const ATOM_ORG = atom({ key: "EVENT_ORG", default: {} });

const getEventData = (e) => ({
  title: e.title,
  slug: e.slug,
  address: e.address,
  startDate: e.startDate,
  endDate: e.endDate,
  shortDescription: e.shortDescription,
  description: sanatizeEditorData(e.description),
  embedCode: sanatizeEditorData(e.embedCode),
  type: e.type,
  tags: e.tags,
  interests: e.interests,
  motives: e.motives,
  requireVisio: e.requireVisio,
  disableVenue: e.disableVenue,
  // ...
  isFree: e?.isFree === "free",
  // ...
  cover: e.cover,
  card: e.card,
  linkedin: e.linkedin,
  facebook: e.facebook,
  videoCover: e.videoCover,
  website: e.website,
  // ...
  access: e.access,
  password: e.password,
  regLabel: e.regLabel,
  regFields: e.regFields,
  // ...
  requiredSignIn: e.requiredSignIn,
  ticketAttachement: e.ticketAttachement,
  // ...
  published: e.published
});

export default function useEvent() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const [orgData, setOrgData] = useState(useRecoilValue(ATOM_ORG));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    setIsLoading(true);
    try {
      const event = await HTTP.GET(`/organizations/events/${THE_ID}`);
      const org = await HTTP.GET("/organizations/myOrganization/get");
      // ...
      setAtom({ ...event });
      setOrgData({ ...org });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured "+(err?.response?.data?.message || err?.response?.statusText), { variant: "error", persist: true });
    }
  };
  // ...
  const onUpdate = useRecoilCallback(() => async (eventData) => {
    setIsLoading(true);
    setHasError(false);

    try {
      // # Update Event
      // let eventData = getEventData(dataSate);
      // delete eventData.registration;
      await HTTP.POST(`/organizations/events/${THE_ID}/update`, eventData);
      // ...
      getAll();
      // ...
      enqueueSnackbar("Event updated", { variant: "success" });
      setIsLoading(false);
      return {};
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured "+(err?.response?.data?.message || err?.response?.statusText), { variant: "error" });
      return { error: true };
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    //setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    orgData,
    onUpdate
  };
}
