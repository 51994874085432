import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useField } from "formik";
import React from "react";

const EntryCheckbox = ({
  name,
  label,
  // ...
  labelPlacement = "start",
  sx = {},
  // ...
  xs = 12,
  md = 6
}) => {
  const [field, meta, helpers] = useField({ name });
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <FormControlLabel
        className="Entry EntryCheckbox"
        label={label}
        labelPlacement={labelPlacement}
        sx={{ ...sx }}
        control={
          <Checkbox
            checked={field.value}
            onChange={(e) => {
              helpers.setValue(e.target.checked);
            }}
            onBlur={field.onBlur}
          />
        }
      />
    </Grid>
  );
};

export default EntryCheckbox;
