// Google it
export default [][
  (![] + [])[+[]] +
    (![] + [])[!+[] + !+[]] +
    (![] + [])[+!+[]] +
    (!![] + [])[+[]]
][
  ([][
    (![] + [])[+[]] +
      (![] + [])[!+[] + !+[]] +
      (![] + [])[+!+[]] +
      (!![] + [])[+[]]
  ] + [])[!+[] + !+[] + !+[]] +
    (!![] +
      [][
        (![] + [])[+[]] +
          (![] + [])[!+[] + !+[]] +
          (![] + [])[+!+[]] +
          (!![] + [])[+[]]
      ])[+!+[] + [+[]]] +
    ([][[]] + [])[+!+[]] +
    (![] + [])[!+[] + !+[] + !+[]] +
    (!![] + [])[+[]] +
    (!![] + [])[+!+[]] +
    ([][[]] + [])[+[]] +
    ([][
      (![] + [])[+[]] +
        (![] + [])[!+[] + !+[]] +
        (![] + [])[+!+[]] +
        (!![] + [])[+[]]
    ] + [])[!+[] + !+[] + !+[]] +
    (!![] + [])[+[]] +
    (!![] +
      [][
        (![] + [])[+[]] +
          (![] + [])[!+[] + !+[]] +
          (![] + [])[+!+[]] +
          (!![] + [])[+[]]
      ])[+!+[] + [+[]]] +
    (!![] + [])[+!+[]]
](
  (!![] + [])[+!+[]] +
    (!![] + [])[!+[] + !+[] + !+[]] +
    (!![] + [])[+[]] +
    ([][[]] + [])[+[]] +
    (!![] + [])[+!+[]] +
    ([][[]] + [])[+!+[]] +
    (+[![]] +
      [][
        (![] + [])[+[]] +
          (![] + [])[!+[] + !+[]] +
          (![] + [])[+!+[]] +
          (!![] + [])[+[]]
      ])[+!+[] + [+!+[]]] +
    (!![] + [])[!+[] + !+[] + !+[]] +
    (+(!+[] + !+[] + !+[] + [+!+[]]))[
      (!![] + [])[+[]] +
        (!![] +
          [][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ])[+!+[] + [+[]]] +
        ([] + [])[
          ([][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ] + [])[!+[] + !+[] + !+[]] +
            (!![] +
              [][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ])[+!+[] + [+[]]] +
            ([][[]] + [])[+!+[]] +
            (![] + [])[!+[] + !+[] + !+[]] +
            (!![] + [])[+[]] +
            (!![] + [])[+!+[]] +
            ([][[]] + [])[+[]] +
            ([][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ] + [])[!+[] + !+[] + !+[]] +
            (!![] + [])[+[]] +
            (!![] +
              [][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ])[+!+[] + [+[]]] +
            (!![] + [])[+!+[]]
        ][
          ([][[]] + [])[+!+[]] +
            (![] + [])[+!+[]] +
            ((+[])[
              ([][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ] + [])[!+[] + !+[] + !+[]] +
                (!![] +
                  [][
                    (![] + [])[+[]] +
                      (![] + [])[!+[] + !+[]] +
                      (![] + [])[+!+[]] +
                      (!![] + [])[+[]]
                  ])[+!+[] + [+[]]] +
                ([][[]] + [])[+!+[]] +
                (![] + [])[!+[] + !+[] + !+[]] +
                (!![] + [])[+[]] +
                (!![] + [])[+!+[]] +
                ([][[]] + [])[+[]] +
                ([][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ] + [])[!+[] + !+[] + !+[]] +
                (!![] + [])[+[]] +
                (!![] +
                  [][
                    (![] + [])[+[]] +
                      (![] + [])[!+[] + !+[]] +
                      (![] + [])[+!+[]] +
                      (!![] + [])[+[]]
                  ])[+!+[] + [+[]]] +
                (!![] + [])[+!+[]]
            ] + [])[+!+[] + [+!+[]]] +
            (!![] + [])[!+[] + !+[] + !+[]]
        ]
    ](!+[] + !+[] + !+[] + [!+[] + !+[]]) +
    (![] + [])[+!+[]] +
    (![] + [])[!+[] + !+[]]
)()(
  ([] + [])[
    (![] + [])[+[]] +
      (!![] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+[]]] +
      ([][[]] + [])[+!+[]] +
      (!![] + [])[+[]] +
      ([][
        (![] + [])[+[]] +
          (![] + [])[!+[] + !+[]] +
          (![] + [])[+!+[]] +
          (!![] + [])[+[]]
      ] + [])[!+[] + !+[] + !+[]] +
      (!![] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+[]]] +
      (![] + [])[!+[] + !+[]] +
      (!![] +
        [][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ])[+!+[] + [+[]]] +
      (!![] + [])[+!+[]]
  ]()[+!+[] + [!+[] + !+[]]] +
    (!![] + [])[!+[] + !+[] + !+[]] +
    (+(!+[] + !+[] + !+[] + [+!+[]]))[
      (!![] + [])[+[]] +
        (!![] +
          [][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ])[+!+[] + [+[]]] +
        ([] + [])[
          ([][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ] + [])[!+[] + !+[] + !+[]] +
            (!![] +
              [][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ])[+!+[] + [+[]]] +
            ([][[]] + [])[+!+[]] +
            (![] + [])[!+[] + !+[] + !+[]] +
            (!![] + [])[+[]] +
            (!![] + [])[+!+[]] +
            ([][[]] + [])[+[]] +
            ([][
              (![] + [])[+[]] +
                (![] + [])[!+[] + !+[]] +
                (![] + [])[+!+[]] +
                (!![] + [])[+[]]
            ] + [])[!+[] + !+[] + !+[]] +
            (!![] + [])[+[]] +
            (!![] +
              [][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ])[+!+[] + [+[]]] +
            (!![] + [])[+!+[]]
        ][
          ([][[]] + [])[+!+[]] +
            (![] + [])[+!+[]] +
            ((+[])[
              ([][
                (![] + [])[+[]] +
                  (![] + [])[!+[] + !+[]] +
                  (![] + [])[+!+[]] +
                  (!![] + [])[+[]]
              ] + [])[!+[] + !+[] + !+[]] +
                (!![] +
                  [][
                    (![] + [])[+[]] +
                      (![] + [])[!+[] + !+[]] +
                      (![] + [])[+!+[]] +
                      (!![] + [])[+[]]
                  ])[+!+[] + [+[]]] +
                ([][[]] + [])[+!+[]] +
                (![] + [])[!+[] + !+[] + !+[]] +
                (!![] + [])[+[]] +
                (!![] + [])[+!+[]] +
                ([][[]] + [])[+[]] +
                ([][
                  (![] + [])[+[]] +
                    (![] + [])[!+[] + !+[]] +
                    (![] + [])[+!+[]] +
                    (!![] + [])[+[]]
                ] + [])[!+[] + !+[] + !+[]] +
                (!![] + [])[+[]] +
                (!![] +
                  [][
                    (![] + [])[+[]] +
                      (![] + [])[!+[] + !+[]] +
                      (![] + [])[+!+[]] +
                      (!![] + [])[+[]]
                  ])[+!+[] + [+[]]] +
                (!![] + [])[+!+[]]
            ] + [])[+!+[] + [+!+[]]] +
            (!![] + [])[!+[] + !+[] + !+[]]
        ]
    ](!+[] + !+[] + !+[] + [!+[] + !+[]]) +
    (!![] + [])[!+[] + !+[] + !+[]] +
    (+[![]] +
      [
        +(
          +!+[] +
          (!+[] + [])[!+[] + !+[] + !+[]] +
          [+!+[]] +
          [+[]] +
          [+[]] +
          [+[]]
        ),
      ])[+!+[] + [+[]]] +
    ([] + [])[
      (![] + [])[+[]] +
        (!![] +
          [][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ])[+!+[] + [+[]]] +
        ([][[]] + [])[+!+[]] +
        (!![] + [])[+[]] +
        ([][
          (![] + [])[+[]] +
            (![] + [])[!+[] + !+[]] +
            (![] + [])[+!+[]] +
            (!![] + [])[+[]]
        ] + [])[!+[] + !+[] + !+[]] +
        (!![] +
          [][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ])[+!+[] + [+[]]] +
        (![] + [])[!+[] + !+[]] +
        (!![] +
          [][
            (![] + [])[+[]] +
              (![] + [])[!+[] + !+[]] +
              (![] + [])[+!+[]] +
              (!![] + [])[+[]]
          ])[+!+[] + [+[]]] +
        (!![] + [])[+!+[]]
    ]()[+!+[] + [!+[] + !+[]]]
);
