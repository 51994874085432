import deafIcon from "@iconify/icons-la/deaf";
import { Icon } from "@iconify/react";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import VideocamIcon from "@material-ui/icons/Videocam";
import React from "react";
import * as yup from "yup";

// - Event Info
export const EVENT_TICKITING = [
  { value: "paid", label: "Paid" },
  { value: "free", label: "free" }
];

export const EVENT_TYPES = [
  { label: "Virtual", value: "virtual" },
  { label: "Hybrid", value: "hybrid" },
  { label: "In-person", value: "physical" }
];

export const EVENT_FEES = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" }
];

export const EVENT_CATEGORIES = [
  { label: "Event", value: "event" },
  { label: "Conference", value: "conference" },
  { label: "Fair", value: "fair" },
  { label: "Training", value: "training" },
  { label: "Bootcamp", value: "bootcamp" },
  { label: "Show", value: "show" },
  { label: "Other", value: "other" }
];

export const EVENT_TAGS = [
  { label: "Finance", value: "finance" },
  { label: "Business", value: "business" },
  { label: "HR", value: "HR" },
  { label: "Softskills", value: "softskills" },
  { label: "IT", value: "IT" },
  { label: "Marketing", value: "marketing" },
  { label: "Law", value: "law" },
  { label: "Agriculture", value: "agriculture" },
  { label: "Education", value: "education" },
  { label: "Economics", value: "economics" },
  { label: "Other", value: "other" },
  { label: "Celebration", value: "celebration" },
  { label: "Health", value: "health" },
  { label: "Environnement", value: "environnement" },
  { label: "Culture", value: "culture" },
  { label: "Fintech", value: "fintech" },
  { label: "Orientation", value: "orientation" },
  { label: "Business Angels", value: "business-angels" },
  { label: "Sales", value: "sales" },
  { label: "Jobs", value: "jobs" },
  { label: "Career", value: "career" },
  { label: "Economy", value: "economy" },
  { label: "Networking", value: "networking" },
  { label: "INAI", value: "INAI" },
  { label: "Digital", value: "digital" },
  { label: "Self Confidence", value: "self-confidence" },
  { label: "Seminar", value: "seminar" },
  { label: "Africa", value: "africa" },
  { label: "Cybersecurity", value: "cybersecurity" },
  { label: "Hackathon", value: "hackathon" },
  { label: "Bootcamp", value: "bootcamp" },
  { label: "Pitching", value: "pitching" },
  { label: "Diplomacy", value: "diplomacy" },
  { label: "AI", value: "AI" },
  { label: "B2B", value: "B2B" },
  { label: "Internship", value: "internship" },
  { label: "Forum", value: "forum" },
  { label: "Innovation", value: "innovation" },
  { label: "Investment", value: "investment" },
  { label: "Coaching", value: "coaching" },
  { label: "Tourism", value: "tourism" },
  { label: "Productivity", value: "productivity" },
  { label: "Blockchain", value: "blockchain" },
  { label: "Workshop", value: "workshop" },
  { label: "Art", value: "art" },
  { label: "PME", value: "PME" },
  { label: "DATA", value: "DATA" },
  { label: "Training", value: "training" },
  { label: "Technology", value: "technology" },
  { label: "Startup", value: "startup" },
  { label: "Recrutement", value: "recrutement" },
  { label: "Teaching", value: "teaching" },
  { label: "Funding", value: "funding" },
  { label: "Intelligence", value: "intelligence" },
  { label: "Opportunities", value: "opportunities" },
  { label: "Medicine", value: "medicine" },
  { label: "Market", value: "market" },
  { label: "Franchisee", value: "fanchisee" },
  { label: "Environment", value: "environment" },
  { label: "Management", value: "management" },
  { label: "Social", value: "social" },
  { label: "Ecology", value: "ecology" }
];
export const EVENT_INTS = [
  { label: "Arts & Culture", value: "Arts & Culture" },
  { label: "Books", value: "Books" },
  { label: "Business", value: "Business" },
  { label: "Communication", value: "Communication" },
  { label: "Economy", value: "Economy" },
  { label: "Education", value: "Education" },
  { label: "Finance", value: "Finance" },
  { label: "Innovation", value: "Innovation" },
  { label: "Marketing", value: "Marketing" },
  { label: "Music", value: "Music" },
  { label: "Politics", value: "Politics" },
  { label: "Sciences", value: "Sciences" },
  { label: "Sports", value: "Sports" },
  { label: "Technology", value: "Technology" },
  { label: "Travelling", value: "Travelling" }
];
export const EVENT_MOTS = [
  { label: "B2B Meeting", value: "B2B Meeting" },
  { label: "B2C Meeting", value: "B2C Meeting" },
  { label: "Culture Exchange", value: "Culture Exchange" },
  { label: "Discovering", value: "Discovering" },
  { label: "Job Offers", value: "Job Offers" },
  { label: "Meeting People", value: "Meeting People" },
  { label: "Networking", value: "Networking" },
  { label: "Opportunities", value: "Opportunities" },
  { label: "Partnerships", value: "Partnerships" },
  { label: "Service Provider", value: "Service Provider" }
];

export const STREAM_TYPES = [
  {
    label: "Live stream",
    value: "live-stream",
    icon: <VideocamIcon sx={{ fontSize: "50px" }} />
  },
  {
    label: "Audio, tran.",
    value: "audio-trans",
    icon: <HeadsetMicIcon style={{ fontSize: "50px" }} />
  },
  {
    label: "Sign lang.",
    value: "sign-lang",
    icon: <Icon icon={deafIcon} style={{ fontSize: "60px" }} />
  }
];

export const EVENT_VISIBILITIES = [
  { value: "public", label: "Public" },
  { value: "restricted", label: "Restricted" },
  { value: "private", label: "Private" }
];
export const EVENT_VISIBILITIES_DETAILS = {
  public: {
    icon: "/static/icons/reg_public.svg",
    title: "Public, everyone can register and attend the event",
    desc: "Your event will be visible on evey.live , everyone can register and attend it"
  },
  restricted: {
    icon: "/static/icons/reg_restricted.svg",
    title: "Restricted , only people with access code can register",
    desc: "Your event will be visible on evey.live , but the registration requires the event access code"
  },
  private: {
    icon: "/static/icons/reg_private.svg",
    title: "Private, only invited people can access",
    desc: "Your event will be visible only to invited people. You can customize invitation email content",
    link: "/blues"
  }
};

export const REGISTRATION_METHODS = [
  { value: "easygo", label: "Easy Go" },
  { value: "standard", label: "Standard" },
  { value: "customized", label: "Customized" }
];
export const REGISTRATION_METHODS_DETAILS = {
  easygo: {
    icon: "/static/icons/reg_easy.svg",
    title: "Easy go, only email is required to register",
    desc: "Attendees will enter quickly to your event , this method is recommended for fast conversion."
  },
  standard: {
    icon: "/static/icons/reg_std.svg",
    title: "Standard, Attendee must create an account to access",
    desc: "Attendees will have to create an account on evey.live to access to your event."
  },
  customized: {
    icon: "/static/icons/reg_custom.svg",
    title: "Customized, attendee have to fill a registration form.",
    desc: "Create the registration form below and customize your fields."
  }
};

export const valSchemaInfo = yup.object({
  title: yup.string().nullable().required("Event name is required").default(""),
  slug: yup.string().nullable().required("Event slug is required").default(""),
  // ...
  address: yup.string().nullable().required("Event location is required").default(""),
  startDate: yup
    .date()
    .nullable()
    .required()
    .default(new Date())
    .when("endDate", (endDate, schema) => endDate && schema.max(endDate, "Start date must be greater than end date")),
  endDate: yup.date().nullable().required().default(new Date()).min(yup.ref("startDate"), "End date should be later than start date"),
  // ...
  shortDescription: yup.string().nullable().required(" Short description is required").default(""),
  description: yup.string().nullable().notRequired().default(""),
  embedCode: yup.string().nullable().notRequired().default(""),
  // ...
  type: yup.string().nullable().required("Event type is required").default("virtual"),
  category: yup.string().nullable().required("Event category is required").default(""),
  tags: yup.object().nullable().notRequired().default({}),
  interests: yup.object().nullable().notRequired().default({}),
  motives: yup.object().nullable().notRequired().default({}),
  requireVisio: yup.boolean().default(false),
  // ...
  isFree: yup.string().nullable().default("free")
});
// ...
export const formatEventInfo = (e) => ({
  title: e.title,
  slug: e.slug,
  address: e.address,
  startDate: e.startDate,
  endDate: e.endDate,
  // ...
  shortDescription: e.shortDescription,
  description: e.description,
  embedCode: e.embedCode,
  customCss: e.customCss,
  // ...
  type: e.type,
  category: e.category,
  tags: e.tags,
  interests: e.interests,
  motives: e.motives,
  requireVisio: e.requireVisio,
  // ...
  location: e.location,
  // ...
  isFree: e?.isFree === "free"
});

export const valSchemaBrand = yup
  .object({
    cover: yup.array().nullable().required().min(1),
    card: yup.object().nullable().required(),
    // ...
    linkedin: yup.string().nullable().notRequired().default(""),
    facebook: yup.string().nullable().notRequired().default(""),
    videoCover: yup.string().nullable().notRequired().default(""),
    website: yup.string().nullable().notRequired().default("")
  })
  .default({
    cover: null,
    card: null,
    linkedin: "",
    facebook: "",
    videoCover: "",
    website: ""
  });
// ...
export const formatEventBrand = (e) => ({
  cover: e.cover,
  card: e.card,
  linkedin: e.linkedin,
  facebook: e.facebook,
  videoCover: e.videoCover,
  website: e.website
});

export const valSchemaVis = yup.object({
  access: yup.string().nullable().required().default(""),
  password: yup.string().nullable().notRequired().default(""),
  requiredSignIn: yup.boolean().default(true),
  ticketAttachement: yup.boolean().default(true),
  regLabel: yup.string().nullable().required().default(""),
  regFields: yup.array().nullable().default([])
});
// ...
export const formatEventVis = (e) => ({
  access: e.access,
  password: e.password,
  requiredSignIn: e.requiredSignIn,
  ticketAttachement: e.ticketAttachement,
  regLabel: e.regLabel,
  regFields: e.regFields,
  disableVenue: e.disableVenue
});
