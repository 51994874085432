import "./style.scss";

// import { ExportToCsv } from "export-to-csv";
// import csvToJson from "papaparse";
import { withFormik } from "formik";
import React, { useEffect } from "react";

import { EntryDateTime, EntryFile, EntryText } from "../../../../components/Entry";
import EventButton from "../../../../components/EventButton";
import EventForm from "../../../../components/EventForm";
import Svg from "../../../../components/Svg";
import * as yup from "yup";
// ...

const validationSchema = yup.object({
  facebookDate: yup.string().required().default(new Date().toISOString()),
  facebookBudget: yup.number().required().default(5),
  emailDate: yup.string().required().default(new Date().toISOString()),
  emailNumber: yup.number().required().default(100),
});

const iniValues = validationSchema.cast();

const EstimationSet = ({
  setOpenEstimation,
  estimate,
  ...props
}) => {
  // ...
  // ...
  // ...
  // ...
  // ...
  return (
    <div className="EstimationSet">
      <div className="EstimationSet__title">
        <Svg src="/static/prediction/ic_facebbook.svg"/>
        <span className="EstimationSet__title__text">Select  Facebook campaign start date and budget</span>         
      </div>
      <EventForm spacing={3}>
        <EntryDateTime
          label="Start date"
          name="facebookDate"
          md={6}
          required
        />
        <EntryText 
          label="Ads budget (Facebook $)" 
          name="facebookBudget" 
          md={6}           
          required
          type="number"
          noMaxLength={true}
        />
      </EventForm>

      <div className="EstimationSet__title ">
        Select Email campaign start date  and number of emails
      </div>
      <EventForm spacing={3}>
        <EntryDateTime
          label="Start date"
          name="emailDate"
          md={6}
          required
        />
        <EntryText 
          label="Number of emails" 
          name="emailNumber" 
          md={6} 
          required
          type="number"
          noMaxLength={true}
        />
      </EventForm> 

      <div className="EstimationSet__foolter">        
        <EventButton 
          className="EventButton"
          sx={{width: "200px"}}
          handler={() => estimate(props.values)}
        >
          Estimate
        </EventButton>  
        <EventButton 
          className="EventButton__cancel"
          sx={{width: "200px"}}
          handler={() => setOpenEstimation(false)}
        >
          Cancel
        </EventButton> 
      </div>  
    </div>
  );
};

const EstimationSetWithFormik = withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(EstimationSet);

export default EstimationSetWithFormik;
