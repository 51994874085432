import { Box } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import React from "react";

const LogoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItem: "center",
  "& > .__logo": {
    height: "37px",
    [theme.breakpoints.down("md")]: {
      height: "25px"
    }
  },
  "& > .__full": {
    height: "30px",
    width: "164px",
    borderLeft: "1px solid #dbdbdb",
    paddingLeft: "25px",
    marginLeft: "25px",
    color: "#919EAB",
    textDecoration: "none",
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "300",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}));

export default function Logo({ isFull, ...other }) {
  return (
    <LogoWrapper>
      <Box className="__logo" component="img" alt="logo" src={isFull ? "/static/brand/logo_full.svg" : "/static/brand/logo.svg"} {...other} />
      {isFull && <div className="__full">Hybrid & Virtual events Management platform</div>}
    </LogoWrapper>
  );
}
