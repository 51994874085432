import { Box, Card, Container, Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import AddEmailSettings from "../../components/AddEmailSettings";
import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventEntityDrawer from "../../components/EventEntityDrawer";
import EventEntityTable from "../../components/EventEntityTable";
import EventSectionTitle from "../../components/EventSectionTitle";
import ModalValidation from "../../components/ModalValidate";
// ...
import Page from "../../components/Page";
import useCheckinList from "../../hooks/useCheckinList";
import AddChekinList from "./components/AddChekinList";
import { EXPORT_HEAD, TABLE_HEAD } from "./config";
import useEventPack from "../../hooks/useEventPack";

const checkUrl = process.env.REACT_APP_CHECKIN_URL || "https://checkin.evey.live";
const apiUrl = process.env.REACT_APP_API_URL || "https://api.evey.live";

const EventCheckin = () => {
  const checkinUrl = `${checkUrl}?origin=${encodeURIComponent(apiUrl)}&hash=`;
  const [delModal, setDelModal] = useState({
    open: false,
    id: null,
    label: "",
  });
  // ...
  const { isLoading, data: checkinLists, onCreate, onRemove } = useCheckinList();
  const { packData } = useEventPack();
  // ...
  // ...
  const [drawer, setDrawer] = useState({ open: false, data: {}, idx: "" });
  const [drawerEmail, setDrawerEmail] = useState(false);
  const checkinListsView = checkinLists.map((c) => ({
    ...c,
    // ...
    stats: `${c?.checkinCount} / ${c?.partitpantsCount}`,
    link: <CopyLink  label="copy chekin device link" link={`${checkinUrl}${c?.hash}`} />
  }));
  // ...
  const onCreateCheckList = async (data) => {
    console.log("data: ", data);
    await onCreate(data.title);
    setDrawer({ open: false, data: {}, idx: "" });
  };
  // ...
  const onDeleteCheckinList = async (id) => {
    await onRemove([id]);
    setDelModal({ open: false, id: null, label: "" });
  };
  // ...
  const canManageCheckin = () => {    
    return packData.type && ["business", "prestige"].includes(packData.type) && true;
  }
  // ...
  return (
    <Page title="Manage Checkin">
      <Container>
        <Box>
          <EventSectionTitle
            title="Event Checkin"
            subTitle="Create checkin lists and show real time checkedin attendees"
            src="/static/setup/title/checkin.svg"
          />

          {checkinListsView.length > 0 && (
            <EventSectionTitle
              title="Create Checkin list"
              subTitle="Manage your checkin lists"
              sx={{ marginBottom: "30px !important" }}
            >
              <EventButton 
                isLoading={isLoading}
                handler={() => setDrawer({ open: true, _id: null })}
                disabled={!canManageCheckin()}
              >
                {canManageCheckin() ? "Create checkin list" : "Upgrade your plan"} 
              </EventButton>
            </EventSectionTitle>
          )}

          {checkinListsView.length === 0 && (
            <EventEmptyView
              src="/static/setup/empty/checkin.svg"
              title="Create Checkin list"
              desc="Create your first checkin list"
              btnLabel="Create"
              handler={() => setDrawer({ open: true, data: {}, idx: "" })}
              isLoading={isLoading}
            />
          )}

          {checkinListsView.length > 0 && (
            <Box>
              <Card>
                <EventEntityTable
                  noEdit
                  label="Checkin list"
                  head={TABLE_HEAD}
                  data={checkinListsView}
                  // ...
                  searchLabel="title"
                  exportName="chekin-list"
                  exportHead={EXPORT_HEAD}
                  disabledOptions={!canManageCheckin()}
                  onDeleteHandler={(data) => {
                    setDelModal({
                      open: true,
                      id: data.id,
                      label: data?.title,
                    });
                  }}
                />
              </Card>
            </Box>
          )}
        </Box>
        <EventEntityDrawer
          open={drawer.open}
          closeDrawer={() => setDrawer({ open: false, data: {}, idx: "" })}
          title="New Checkin list"
          sub=""
          width="490px"
        >
          <AddChekinList
            idx={drawer.idx}
            data={drawer.data}
            onCreateCheckinList={onCreateCheckList}
            // ...
            checkinLists={checkinLists.map(c => c?.title)}
            isLoading={isLoading}
          />
        </EventEntityDrawer>

        <EventEntityDrawer
          open={drawerEmail}
          closeDrawer={() => setDrawerEmail(false)}
          title="Email invitation settings"
          sub="Configure email invitation dates and templates"
        >
          <AddEmailSettings
            open={drawerEmail}
            onClose={() => setDrawerEmail(false)}
          />
        </EventEntityDrawer>
        
      </Container>

      <ModalValidation
        open={delModal.open}
        title="Delete sponsor"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteCheckinList(delModal.id),
        }}
        no={{
          label: "cancel",
          handler: () => setDelModal({ open: false, id: null, label: "" }),
        }}
      >
        Are you sure to delete {delModal?.label || "checkin list"}?
      </ModalValidation>
    </Page>
  );
};

export default EventCheckin;

const CopyLink = ({ isTop, label, link = "" }) => {
  const { enqueueSnackbar } = useSnackbar();
  // ...
  return (
    <div className="EventReview__link_device" >
      <CopyToClipboard text={link} onCopy={() => enqueueSnackbar("Link copied", { variant: "success" })}>
        <Tooltip title="Click to copy" arrow>
          <div className="__inner">
          {label}
          </div>
        </Tooltip>
      </CopyToClipboard>
    </div>
  );
};
