import "./style.scss";

import cx from "classnames";
import React from "react";

const LoadingDots = ({ type, color }) => {
  return (
    <div className={cx("ball-loader", { [type]: type })}>
      <div className={cx("ball-loader-ball ball1", { [color]: color })} />
      <div className={cx("ball-loader-ball ball2", { [color]: color })} />
      <div className={cx("ball-loader-ball ball3", { [color]: color })} />
    </div>
  );
};

export default LoadingDots;
