import "./style.scss";

import { Grid } from "@material-ui/core";
import React from "react";

const EventForm = ({ container, spacing = 4, children, ...props }) => {
  return (
    <Grid container spacing={spacing} {...props}>
      {children}
    </Grid>
  );
};

export default EventForm;
