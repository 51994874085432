import { Box, Button, Grid, TextField } from "@material-ui/core";
import cx from "classnames";
import { useField } from "formik";
import React, { useRef, useState } from "react";

import { getRawFile } from "../../../utils/files";
// ...
import LoadingDots from "../../LoadingDots";

const EntryFile = ({
  required,
  name,
  label,
  placeholder,
  // ...
  accept = ".jpg,.png,.jpeg,.svg",
  multiple,
  // ...
  xs = 12,
  md = 6,
  sx = {},
  // ...
  onUpload,
  onReset,
  // ...
  isLoading: isLO
}) => {
  const [isLI, setIsLoading] = useState(false);
  const isLoading = isLI || isLO;
  // ...
  const [field] = useField({ name });
  const inpRef = useRef();
  // ...
  const raw = field?.value;
  const value = Array.isArray(raw) ? raw?.[0] : raw;
  const fName = value?.name;
  const hasValue = !!fName;
  // ...
  const onChangeHandler = async (e) => {
    const inp = e.target;
    setIsLoading(true);

    // # Get Files
    const files = inp.files || null;
    if (!files || files.length === 0) {
      setIsLoading(false);
      return;
    }

    // # Upload
    const raw = await getRawFile(files[0]);
    await onUpload(name, raw);

    // # Clear input
    inp.value = "";
    // ...
    setIsLoading(false);
  };
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <Box className="Entry EntryFile" style={{ ...sx }}>
        <div className={cx("EntryFile__label", { __required: required })}>{label}</div>
        <TextField
          className="EntryText"
          key={Date.now()}
          name={name}
          label={hasValue ? "" : placeholder}
          value={fName}
          // ...
          InputLabelProps={{ shrink: false }}
          // ...
          variant="outlined"
          margin="dense"
          fullWidth
        />

        <input
          ref={inpRef}
          id={`file-${name}`}
          type="file"
          className="EntryFile__input"
          accept={accept}
          multiple={multiple}
          onChange={onChangeHandler}
        />

        <div className="EntryFile__actions">
          {hasValue && (
            <div className="EntryFile__actions__reset">
              <Button aria-label="delete picture" component="div" className="__btn" onClick={() => onReset(name)}>
                {isLoading && <LoadingDots type="small" color="white" />}
                {!isLoading && "Delete File"}
              </Button>
            </div>
          )}

          {!hasValue && (
            <label className="EntryFile__actions__upload" htmlFor={`file-${name}`}>
              <Button aria-label="upload picture" component="div" className="__btn">
                {isLoading && <LoadingDots type="small" color="white" />}
                {!isLoading && "Select File"}
              </Button>
            </label>
          )}
        </div>
      </Box>
    </Grid>
  );
};

export default EntryFile;
