export const TABLE_HEAD = [
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Email type",
  },
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: "Subject",
  },
  {
    id: "categoryLabel",
    numeric: false,
    disablePadding: true,
    label: "Category",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

export const EXPORT_HEAD = ["category", "subject", "categoryExp"];
