import "./style.scss";

import ErrorIcon from "@material-ui/icons/Error";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import _ from "underscore";

import { EntryDate, EntryEditor, EntryText, EntryTime, EntrySwitch, EntryHTMLEditor, EntryDateTime } from "../../../../components/Entry";
import EventButton from "../../../../components/EventButton";
import EventForm from "../../../../components/EventForm";
import useEvent from "../../../../hooks/useEvent";
import { addSlotSchema as validationSchema } from "../../entries";
import EntitiesAutocomplete from "../EntitiesAutocomplete";
import useEventPack from "../../../../hooks/useEventPack";
const iniValues = validationSchema.cast();

const embedCodePlaceholder = "Insert content from external apps or website (like videos, images, or forms) into your event page using embed codes. +Learn More button that leads to help center + tools logo (design)";

const AddSlot = ({
  currDate,
  data = {},
  onEditSlot,
  isLoading,
  // ...
  speakersList = [],
  sponsorsList = [],
  // ...
  onAddNewSpeaker,
  onAddNewSponsor,
  ...props
}) => {
  const [canNext, setCanNext] = useState({});
  const { data: event } = useEvent();
  const { startDate, endDate } = event || {};
  const { packData } = useEventPack();
  // ...
  const onValidate = async () => {
    const validate = await props.validateForm(props.values);
    setCanNext({ ...validate });
  };
  // ...
  useEffect(() => {
    onValidate();
  }, [props.values]);
  // ...
  useEffect(() => {
    const sponsors = data?.sponsors?.map((s) => ({
      label: s.label || s.name,
      value: s.value || s.id
    }));
    const speakers = data?.speakers?.map((s) => ({
      label: s.firstName && s.lastName ? s.firstName + " " + s.lastName : s.label,
      value: s?.user?.id || s.value
    }));
    // ...
    let values = { ...iniValues, ...data, sponsors, speakers };

    //# StartTime
    if (!data?.id && currDate) values.start = currDate;

    values.disableLiveStream = !!values?.streamConf?.disableLiveStream;

    // ...
    props.resetForm({ values });
  }, []);

  // Entities
  const sponsorsOptions = sponsorsList.map((s) => ({
    label: s.name,
    value: s.sponsor
  }));
  const speakersOptions = speakersList.map((s) => ({
    label: s.firstName + " " + s.lastName,
    value: s.user.id
  }));
  // ...
  const canSave = !_.isEmpty(canNext);
  // ...  
  const canEmbedCode = () => {
    if(packData && ["business", "prestige"].includes(packData.type) ){
      return true;
    }
    return false
  }
  // ...
  return (
    <>
      <EventForm>
        <EntryText required label="Session title" placeholder="Session title" name="title" md={12} />
        <EntryDateTime required label="Start date" name="start" xs={4} md={8} minDate={startDate} maxDate={endDate} />
        {/* <EntryTime required label="Start time" name="start" xs={4} md={4} /> */}
        <EntryText required noMaxLength label="Duration (minutes)" name="duration" type="number" xs={4} md={4} />

        <EntryEditor label="Description" name="description" placeholder="Session description" md={12} />

        {canEmbedCode() && <EntryEditor isEmbedCode label="Insert External media/tools" name="embedCode" placeholder={embedCodePlaceholder} md={12} />}

        <EntitiesAutocomplete
          name="speakers"
          addNewHandler={() => onAddNewSpeaker(props.values)}
          helper="Select speaker"
          placeholder="Select speaker"
          options={speakersOptions}
          sx={{ mt: "20px" }}
        />
        <EntitiesAutocomplete
          name="sponsors"
          addNewHandler={() => onAddNewSponsor(props.values)}
          helper="Select sponsor"
          placeholder="Select sponsor"
          options={sponsorsOptions}
          sx={{ mt: "20px" }}
        />
        <EntrySwitch name="disableVirtualSpace" label="Disable virtual space" labelPlacement="end" />
        <EntrySwitch disabled={props.values?.disableVirtualSpace} name="disableLiveStream" label="Disable Live Stream" labelPlacement="end" />
        <EntrySwitch name="enableMobileNotification" label="Mobile notification (2 min before)" labelPlacement="end" title="tt"/>
      </EventForm>

      <div className="DrawerRequired">
        {!canSave ? (
          <div className="DrawerRequired__info" />
        ) : (
          <div className="DrawerRequired__info">
            <ErrorIcon className="__icon" />
            <span className="__label">Fill all required details</span>
          </div>
        )}

        <EventButton isLoading={isLoading} disabled={canSave} handler={() => onEditSlot(props.values)}>
          Save Slot
        </EventButton>
      </div>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(AddSlot);
