import "./style.scss";

import { FileIcon, defaultStyles } from "react-file-icon";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";

import SponsorSearch from "../../components/SponsorSearch";
import useFile from "../../hooks/useFile";
import useSponsors from "../../hooks/useSponsors";
import { getDefaults } from "../../utils/defaults";
import { getRawFile } from "../../utils/files";
import { EntryAutocomplete, EntryCheckbox, EntryPhoto, EntrySelect, EntryText } from "../Entry";
import EventButton from "../EventButton";
import EventForm from "../EventForm";
import { getCanSave } from "./data";
import { EVENT_INDUSTRIES, EVENT_TAGS, SPONSOR_LEVEL, SPONSOR_TYPE, validationSchema } from "./entries";
const iniValues = validationSchema.cast();

const EventTypeStyle = styled("div")(() => ({
  position: "relative",
  margin: "0",
  "& > label": {
    display: "block",
    color: "rgb(99, 115, 129) !important",
    fontFamily: "Poppins,sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "300",
    margin: "0 0 15px 0"
  }
}));

const RegEntries = ({ name, value, options, handleChange, handleBlur }) => {
  // ...
  return (
    <RadioGroup row name={name} value={value} onChange={handleChange} onBlur={handleBlur} style={{ flexWrap: "nowrap" }}>
      {options?.map((i, idx) => (
        <Box key={idx} sx={{ width: "150px" }}>
          <FormControlLabel value={i.value} label={i.label} control={<Radio />} />
        </Box>
      ))}
    </RadioGroup>
  );
};

const AddSponsor = ({
  data,
  check,
  // ...
  isReadOnly,
  onEditSponsor,
  onFetchSponsor,
  // ...
  isLoading: isLA,
  // ...
  ...props
}) => {
  const dValues = getDefaults();
  const [searchList, setSearchList] = useState([]);
  // ...
  const { onFetch: onFetchHandler } = useSponsors();
  // ...
  const isAnyReadOnly = isReadOnly || !!data?.email;
  const { isLoading: isLFD, onUpload: onUpload } = useFile();
  const isLoading = isLA || isLFD;
  // ...
  const canSave = !getCanSave(props.values);
  const lSrc = props.values?.card?.url || null;
  const bSrc = props.values?.cover?.url || null;
  // ...
  const onUploadFile = async (name, data) => {
    const res = await onUpload(data);
    props.setFieldValue(name, res?.[0] || null);
  };
  // ...
  const onUploadAdditionalFile = async (e) => {
    const inp = e.target;
    const files = inp.files || null;
    if (!files || files.length === 0) return;
    // ...
    const raw = await getRawFile(files[0]);
    const res = (await onUpload(raw)) || [];
    // ...
    const baseFiles = props.values?.files;
    props.setFieldValue("files", [...baseFiles, ...res]);
    // ...
    inp.value = "";
  };
  // ...
  const onDeleteFile = async (id) => {
    const files = (props.values?.files || []).filter((f) => f.id !== id);
    props.setFieldValue("files", files);
  };
  // ...
  const onFetchSponsorhandler = async () => {
    const search = props.values?.search;
    // ...
    if (!search || (search && search.length < 3)) {
      return setSearchList([]);
    }
    // ...
    const sponsors = await onFetchHandler(search);
    const sponsordList = sponsors?.filter((s) => !!s.email) || [];
    setSearchList(sponsordList);
  };
  // ...
  useEffect(() => {
    if (!check) return;
    // ...
    onFetchSponsorhandler();
  }, [props.values?.search]);
  // ...
  useEffect(() => {
    if (isLA) return;
    // ...
    let values = { ...iniValues, ...data };
    if (!data?.card) values.card = dValues.sponsorCard;
    if (!data?.cover) values.cover = dValues.sponsorCover;
    // ...
    props.resetForm({ values });
  }, [data]);
  // ...
  return (
    <div className="AddSponsor">
      {check && (
        <div className="AddSponsor">
          <EventForm>
            <EntryText label="Sponsor name" name="search" placeholder="Search by sponsor name or email" xs={12} md={12} />
            <SponsorSearch
              search={props.values?.search}
              options={searchList}
              // ...
              handler={(sponsor, isNew) => {
                onFetchSponsor(sponsor, isNew);
              }}
            />
          </EventForm>
        </div>
      )}
      {!check && (
        <>
          <EntryPhoto
            name="cover"
            src={bSrc}
            required
            htmlFor="sponsor-cover"
            defaultK="sponsorCover"
            // ...
            isReadOnly={isAnyReadOnly}
            isLoading={isLoading}
            // ...
            placeholder="Upload sponsor banner"
            onUpload={onUploadFile}
            meta={{
              width: 820,
              height: 321,
              helper: "Sponsor banner 820 x 321 px"
            }}
          />

          <div className="AddSponsor__photo">
            <EntryPhoto
              name="card"
              src={lSrc}
              required
              htmlFor="sponsor-card"
              defaultK="sponsorCard"
              // ...
              isReadOnly={isAnyReadOnly}
              isLoading={isLoading}
              isRounded
              // ...
              placeholder="Upload logo"
              onUpload={onUploadFile}
              meta={{
                width: 300,
                height: 300,
                helper: "Sponsor logo 300 x 300 px"
              }}
            />
          </div>

          <EventForm>
            <Grid item xs={12} md={3} />
            <EntryText required label="Company name" name="name" xs={12} md={4.5} disabled={isAnyReadOnly} />
            <EntryText label="Slogan" name="slogan" xs={12} md={4.5} disabled={isAnyReadOnly} />
            <EntryText label="About the company" name="description" multiline minRows={3} maxRows={3} md={12} disabled={isAnyReadOnly} noMaxLength/>

            <Grid item xs={0} md={12} />

            <EntryAutocomplete
              freeSolo
              name="settings.industries"
              // label="Industries"
              helper="Industry"
              options={EVENT_INDUSTRIES}
              disabled={isAnyReadOnly}
            />
            <EntryAutocomplete
              freeSolo
              name="settings.tags"
              // label="Tags"
              helper="Tags press enter to add"
              options={EVENT_TAGS}
              disabled={isAnyReadOnly}
            />
            <Grid item xs={6} md={6}>
              <EventTypeStyle>
                <label>Partnership type</label>
                <RegEntries
                  name="type"
                  value={props.values?.type}
                  handleChange={props.handleChange}
                  handleBlur={props.handleBlur}
                  options={SPONSOR_TYPE}
                />
              </EventTypeStyle>
            </Grid>
            <EntrySelect label="Partnership level" topLabel="Sponsorship / Partnership level" name="level" options={SPONSOR_LEVEL} />
            <EntryText required label="Company email" name="email" disabled={isAnyReadOnly} />
            <EntryText label="Company phone" name="phone" disabled={isAnyReadOnly} />
            <EntryText label="Country" name="settings.country" disabled={isAnyReadOnly} />
            <EntryText label="City" name="settings.city" disabled={isAnyReadOnly} />
            <EntryText label="Website" name="website" disabled={isAnyReadOnly} maxLength={250} />
            <EntryText label="Facebook" name="facebook" disabled={isAnyReadOnly} maxLength={250} />
            <EntryText label="Linkedin" name="linkedin" disabled={isAnyReadOnly} maxLength={250} />
            <EntryText label="Instagram" name="instagram" disabled={isAnyReadOnly} maxLength={250} />
          </EventForm>
          <Grid container spacing={4} sx={{ marginTop: "5px" }}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={12}>
                <p
                  style={{
                    color: "#637381",
                    fontFamily: "Montserrat",
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontWeight: "300",
                    marginBottom: "10px"
                  }}>
                  Feature sponsor / partner
                </p>
              </Grid>
              <EntryCheckbox name="featureLandingPage" label="Feature company on the event landing page" labelPlacement="end" xs={12} md={12} />
              <EntryCheckbox name="featureVenue" label="Feature company on the event venue" labelPlacement="end" xs={12} md={12} />
              <EntryCheckbox name="sendEmailNotification" label="Send email invitation " labelPlacement="end" xs={12} md={12} />
            </Grid>
            <Grid container item xs={12} md={6} style={{ marginTop: "20px" }}>
              <div className="AddSponsor__files">
                {props.values?.files?.length < 3 && (
                  <div className="FileAdd">
                    <input type="file" id="additional-file-input" onChange={onUploadAdditionalFile} />
                    <div className="__label">Additional documents</div>
                    <label className="__icon" htmlFor="additional-file-input">
                      <span>Add file</span>
                      <img src="/static/icons/file_icon.svg" alt="" />
                    </label>
                  </div>
                )}

                {props.values?.files?.map((f) => {
                  const icon = f.ext.replace(".", "");
                  // ...
                  return (
                    <div key={f.id} className="FileItem">
                      <div className="__label">
                        <FileIcon extension={icon} {...defaultStyles[icon]} />
                        <div className="__name">{f.name}</div>
                      </div>
                      <div className="__del" onClick={() => onDeleteFile(f.id)}>
                        <HighlightOffIcon />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>

          <div className="DrawerRequired">
            {!canSave ? (
              <div className="DrawerRequired__info" />
            ) : (
              <div className="DrawerRequired__info">
                <ErrorIcon className="__icon" />
                <span className="__label">Fill all required details</span>
              </div>
            )}

            <EventButton isLoading={isLoading} disabled={canSave} handler={() => onEditSponsor(props.values)}>
              Save Sponsor
            </EventButton>
          </div>
        </>
      )}
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(AddSponsor);
