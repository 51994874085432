import "./style.scss";

import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { withFormik } from "formik";
import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Moment from "react-moment";

import {
  EntryEditor,
  EntrySelect,
  EntryText,
  EntryHTML,
  EntrySwitch
} from "../../../../components/Entry";
import EventButton from "../../../../components/EventButton";
import useFile from "../../../../hooks/useFile";
import { getRawFile } from "../../../../utils/files";
// ...
import { getCanSave } from "./data";
import {
  categories,
  formatTag,
  getHolder,
  // templateInit,
  validationSchema,
} from "./entries";
import { Input } from "@material-ui/core";
import { useHistory } from "react-router";

const iniValues = validationSchema.cast();

const AddEmail = ({
  data = {},
  onEditEmail,
  isLoading: isLT,
  // ...
  customFields,
  // ...
  ...props
}) => {
  const { isLoading: isLF, onUpload } = useFile();
  const history = useHistory();
  const isLoading = isLT || isLF;
  // ...
  const values = props?.values || {};
  const category = values?.category || "";
  const canSave = getCanSave(props.values);
  // ...
  const onUploadFile = async (e) => {
    const inp = e.target;
    const files = inp.files || null;
    if (!files || files.length === 0) return;
    // ...
    const raw = await getRawFile(files[0]);
    const res = (await onUpload(raw)) || [];
    // ...
    const baseFiles = values?.files;
    props.setFieldValue("files", [...baseFiles, ...res]);
    // ...
    inp.value = "";
  };
  // ...
  let catHolders = [];
  if (props.values?.category) {
    catHolders = Object.values(getHolder(customFields)[props.values?.category]);
  }
  // ...
  useEffect(() => {
    props.resetForm({ values: { ...iniValues, ...data } });
  }, []);
  // ...
  return (
    <div className="AddEmail">
      <div className="AddEmail__header">
        <div className="AddEmail__header__top">
          <div className="__left">
            {category === "speaker" && "Speaker template"}
            {category === "invitation" && "Invitation template"}
            {category === "registration" && "Registration template"}
          </div>
          <div className="__right">
            <EntrySelect name="category" options={categories} xs={12} md={12} />
          </div>
        </div>
        <div className="AddEmail__header__subject">
          <EntryText
            maxLength={100}
            name="subject"
            placeholder="Email subject"
            label="Email subject"
            sx={12}
            md={12}
          />
        </div>
      </div>
      <div className="AddEmail__wsysg">
        <div className="AddEmail__wsysg__input">
          <EntryEditor
            name="content"
            placeholder="Email content goes here!"
            label="Email body"
            xs={12}
            md={12}
          />
          {/* <EntryHTML
            name="content"
            placeholder="Email content goes here!"
            label="Email body"
            xs={12}
            md={12}
          /> */}
        </div>
        <div className="AddEmail__wsysg__footer">
          <div className="__title">User information placeholders</div>
          <div className="__tags">
            {catHolders.map((t, idx) => (
              <CopyToClipboard key={idx} text={t} onCopy={() => { }}>
                <Tooltip title="Click to copy" arrow>
                  <div key={idx} className="__tag">
                    [{formatTag(t)}]
                  </div>
                </Tooltip>
              </CopyToClipboard>
            ))}
          </div>
        </div>

        <div className="AddEmail__files">
          {values.files?.map((file) => (
            <div className="__item" key={file.id}>
              <div className="__item__icon">
                <InsertDriveFileIcon />
              </div>
              <div className="__item__details">
                <div className="__item__details__name">{file.name}</div>
                <div className="__item__details__date">
                  <Moment format="D MMM YYYY">{file.created_at}</Moment>
                </div>
              </div>
              <div className="__item__delete">
                <HighlightOffIcon
                  style={{ color: "#FF4842" }}
                  onClick={() => {
                    const files = (props.values?.files || []).filter((f) => {
                      return f.id !== file.id;
                    });
                    props.setFieldValue("files", files);
                  }}
                />
              </div>
            </div>
          ))} 
          
          <EntrySwitch name="active" label={values?.active ? "Active" : "Disabled"} />
          <EntrySwitch name="fullHtml" label="full HTML" />

          <div className="AddEmail__files__add">            
            <input id="email-file-input" type="file" onChange={onUploadFile} />
            <label htmlFor="email-file-input">
              <span>Add file</span>
              <img src="/static/icons/file_icon.svg" alt="" />
            </label>
          </div>
        </div>
      </div>
      <div className="AddEmail__wsysg__actions">
        {canSave ? (
          <div className="DrawerRequired__info" />
        ) : (
          <div className="DrawerRequired__info">
            <ErrorIcon className="__icon" />
            <span className="__label">Fill all required details</span>
          </div>
        )}
        <p style={{color: "white"}} onClick={()=>{history.push(`/manage-emails/${props?.values?.id}/html`);}}>edit html</p>
        <EventButton
          isLoading={isLoading}
          disabled={!canSave}
          handler={() => onEditEmail(props.values)}
        >
          Save email
        </EventButton>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema,
})(AddEmail);
