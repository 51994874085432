import copyFill from "@iconify/icons-eva/copy-fill";
import reloadIcon from "@iconify/icons-mdi/reload";
import { Icon } from "@iconify/react";
import { Button, InputAdornment, TextField, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "'Montserrat', sans-serif",
      color: "#454545",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      height: "52px",
      marginTop: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #B4BCC3",
    },
  },
}))(TextField);

const StyledButton = withStyles((theme) => ({
  root: {
    color: "#454545",
    "&:hover": {
      color: "#00AB55",
      background: "none",
    },
  },
}))(Button);

export default function CopyAccessCode() {
  const { values, setFieldValue } = useFormikContext();
  // ...
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const onCopy = () => {
    if (!values.password) return;
    enqueueSnackbar("Copied", { variant: "success" });
  };
  // ...
  const randomstring = (l) => {
    let s = "";
    const randomchar = function () {
      const n = Math.floor(Math.random() * 62);
      if (n < 10) return n;
      if (n < 36) return String.fromCharCode(n + 55);
      return String.fromCharCode(n + 61);
    };
    while (s.length < l) s += randomchar();
    return s;
  };
  const generateAccessCode = () => {
    const code = randomstring(8);
    setFieldValue("password", code);
  };
  // ...
  const handleChange = (e) => {
    console.log("e.target.value: ", e.target.value)
    setFieldValue("password", e.target.value);
    //setFieldValue("password", code);
  };
  // ...
  useEffect(() => {
    if (values.password) return;
    generateAccessCode();
  }, []);
  // ...
  return (
    <StyledTextField
      fullWidth
      value={values.password}
      onChange={handleChange}
      
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <StyledButton
              type="button"
              value="generate"
              startIcon={
                <Icon icon={reloadIcon} style={{ fontSize: "35px" }} />
              }
              onClick={generateAccessCode}
            />
            <CopyToClipboard text={values.password} onCopy={onCopy}>
              <Tooltip title="Copy">
                <Button
                  variant="contained"
                  startIcon={<Icon icon={copyFill} width={24} height={24} />}
                  sx={{ px: 4, textAlign: "center", background: "#0E8C93" }}
                >
                  Copy
                </Button>
              </Tooltip>
            </CopyToClipboard>
          </InputAdornment>
        ),
      }}
    />
  );
}
