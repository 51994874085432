import { Grid, TextField } from "@material-ui/core";
import cx from "classnames";
import { useField } from "formik";
import React from "react";

import slugify from "../../../utils/slugify";

const EntrySlug = ({
  required,
  // ...
  type = "text",
  label,
  name,
  placeholder,
  InputProps,
  // ...
  error,
  // ...
  xs = 12,
  md = 6
}) => {
  const [field, meta, helpers] = useField({ name });
  // ...
  let helperText = meta.touched ? meta.error : "";
  if (error) {
    helperText = error;
  }
  // ...
  const onBlurHandler = (e) => {
    helpers.setValue(slugify(field.value));
    field.onBlur(e);
  };
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <TextField
        className={cx("Entry EntryText EntrySlug", { __required: required })}
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        value={field?.value || ""}
        onChange={field.onChange}
        onBlur={onBlurHandler}
        // ...
        variant="outlined"
        margin="dense"
        fullWidth
        // ...
        InputProps={InputProps}
        InputLabelProps={{ shrink: true }}
        error={meta.touched && (!!meta.error || error)}
        helperText={helperText}
      />
    </Grid>
  );
};

export default EntrySlug;
