import "./style.scss";

import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import DoneIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WarnIcon from "@material-ui/icons/Warning";
import React from "react";

const EventSetupStepper = ({
  isFree,
  valSteps = [],
  // ...
  currTab,
  setCurrTab,
  ITEMS
}) => {
  const steps = ITEMS.map((i) => ({ label: i.label, step: i.step }));
  // ...
  return (
    <>
      <Stepper className="EventSetupStepper" activeStep={currTab} alternativeLabel>
        {steps.map((s, idx) => {
          const error = !valSteps?.[idx];
          let ErrrIcon = <WarnIcon style={{ color: "#F9C128" }} />;
          if (idx === 3 && !isFree) ErrrIcon = <ErrorIcon style={{ color: "#E6273E" }} />;
          // ...
          return (
            <Step key={s.step} className="__step">
              <StepLabel
                className="__label"
                icon={error ? ErrrIcon : <DoneIcon style={{ color: "#61BA73" }} />}
                error={error}
                color="red"
                onClick={() => {
                  if (currTab === s.step) return;
                  setCurrTab(s.step);
                }}>
                <span>{s.label}</span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

export default EventSetupStepper;
