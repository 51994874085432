import { Box, Button, Grid, TextField, Menu, MenuItem } from "@material-ui/core";

import cx from "classnames";
import { useField } from "formik";
import React, { useRef, useState } from "react";

import { getRawImg } from "../../../utils/files";
// ...
import CropImage from "../../CropImage";
import LoadingDots from "../../LoadingDots";
import GalleryModal from "../../Gallery/Modal";

const EntryImage = ({
  required,
  name,
  label,
  placeholder,
  htmlFor,
  // ...
  accept = ".jpg,.png,.jpeg",
  multiple,
  // ...
  preview,
  previewStyle = {},
  // defaultK,
  // ...
  xs = 12,
  md = 6,
  sx = {},
  // ...
  meta,
  onUpload,
  // ...
  isRounded,
  isLoading: isLO
}) => {
  // ...
  const [galleryModal, setGalleryModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  // ...
  const [cropModal, setCropModal] = useState({
    open: false,
    name: "",
    raw: {}
  });
  // ...
  const [isLI, setIsLoading] = useState(false);
  const isLoading = isLI || isLO;
  // ...
  const [field, metaField, helpers] = useField({ name });
  const inpRef = useRef();
  const refId = htmlFor || `file-${name}`;
  // ...
  const raw = field?.value;
  const value = Array.isArray(raw) ? raw?.[0] : raw;
  const fName = value?.name;
  const fUrl = value?.url;
  const hasValue = !!fName;
  // ...
  const onChangeHandler = async (e) => {
    const inp = e.target;
    setIsLoading(true);

    // # Get Files
    const files = inp.files || null;
    if (!files || files.length === 0) {
      setIsLoading(false);
      return;
    }

    // # Upload
    const raw = await getRawImg(files[0]);
    setCropModal({ open: true, name, raw });

    // # Clear input
    inp.value = "";
    // ...
    setIsLoading(false);
  };
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <Box className="Entry EntryImage" style={{ ...sx }}>
        <div className={cx("EntryImage__label", { __required: required })}>{label}</div>
        <TextField
          className="EntryText"
          key={Date.now()}
          name={name}
          label={hasValue ? "" : placeholder}
          value={fName}
          // ...
          InputLabelProps={{ shrink: false }}
          // ...
          variant="outlined"
          margin="dense"
          fullWidth
        />

        <input ref={inpRef} id={refId} type="file" className="EntryImage__input" accept={accept} multiple={multiple} onChange={onChangeHandler} />

        <div className="EntryImage__actions">
          <Button
            className="__btn"
            // variant="green"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}>
            {isLoading && <LoadingDots type="small" color="green" />}
            {!isLoading && "Select File"}
          </Button>

          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleClose}>
              <label className="EntryImage__actions__upload" htmlFor={refId}>
                Local File
              </label>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setGalleryModal(true);
                handleClose();
              }}>
              From Gallery
            </MenuItem>
          </Menu>
        </div>

        {preview && fUrl && (
          <div className="EntryImage__preview" style={{ ...previewStyle }}>
            <img src={fUrl} />
          </div>
        )}
      </Box>

      <CropImage
        open={cropModal.open}
        onClose={() => setCropModal({ open: false, raw: {} })}
        // ...
        name={cropModal.name}
        raw={cropModal.raw}
        isRounded={isRounded}
        meta={meta}
        // ...
        isLoading={isLoading}
        handler={async (name, data) => {
          setCropModal({ open: false, raw: {} });
          await onUpload(name, data);
        }}
      />

      <GalleryModal
        open={galleryModal}
        onCloseHandler={() => setGalleryModal(false)}
        onSetImage={(file) => {
          helpers.setValue(file);
          setGalleryModal(false);
        }}
      />
    </Grid>
  );
};

export default EntryImage;
