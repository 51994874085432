import EventTicketDesign from "./tabs/Design";
import EventTicketDetails from "./tabs/Details";
import EventTicketPrint from "./tabs/Print";

export const TABS_LIST = [
  {
    step: 0,
    label: "Target audience",
    Node: EventTicketDetails,
  },
  {
    step: 1,
    label: "Design & layout",
    Node: EventTicketDesign,
  },
  {
    step: 2,
    label: "Print",
    Node: EventTicketPrint,
  },
];

export const designFields = [
  {
    label: "First ticket detail",
    name: "heading",
    otherLabel: "First detail other",
    otherName: "text",
    otherHolder: "Other detail",
  },
  {
    label: "Second ticket detail",
    name: "subHeading1",
    otherLabel: "Second detail other",
    otherName: "subText1",
    otherHolder: "Other detail",
  },
  {
    label: "Third ticket detail",
    name: "subHeading2",
    otherLabel: "Third detail other",
    otherName: "subText2",
    otherHolder: "Other detail",
  },
  {
    label: "Forth ticket detail",
    name: "subHeading3",
    otherLabel: "Forth detail other",
    otherName: "subText3",
    otherHolder: "Other detail",
  },
];

export const getAttendeesList = (attendees, ticket, regFields) => {
  const isCust = regFields?.regLabel === "customized";
  const tCondK = ticket?.conditionKey;
  const tCondV = ticket?.conditionValue;
  // ...
  return attendees
    .map((att) => {
      const regAttData = att?.custom || {};
      // ...
      return {
        id: att.id,
        email: att.email,
        hash: att.hash,
        index: att.index,
        isRegistered: att?.registrationStatus === "registered",
        // ...
        ...regAttData,
      };
    })
    .filter((att) => {
      //# NotRegistered
      if (!att.isRegistered) return false;

      //# NotCustom/CondKV
      if (!isCust || !tCondK) return true;

      //# NotTicketConfig
      return att[tCondK] === tCondV;
    });
};

export const getSpeakersList = (speakers) => {
  return speakers.map((s) => {
    return {
      id: s.id,
      email: s.email,
      fullname: s.firstName + "" + s.lastName,
      position: s.position,
      company: s.company,
    };
  });
};

export const getSponsorsList = (sponsors) => {
  return sponsors.map((s) => {
    return {
      id: s.id,
      email: s.email,
      name: s.name,
      type: s.type,
      level: s.level,
    };
  });
};

export const getRegistrationOptions = (registrationFields, target) => {
  const { regLabel, regFields = [] } = registrationFields || {};
  const isEasyGo = regLabel === "easygo";
  const isStandard = regLabel === "standard";
  const isCustom = regLabel === "customized";

  //# Registration options
  let options = [];

  //# Attendees
  if (target === "attendees") {
    if (isEasyGo) {
      options = [{ label: "Email", value: "email" }];
    } else if (isStandard) {
      options = [
        { label: "Email", value: "email" },
        { label: "First name", value: "first-name" },
        { label: "Last name", value: "last-name" },
      ];
    } else if (isCustom) {
      options = regFields.map((r) => ({ label: r.label, value: r.key }));
    }
  }

  //# Speakers
  if (target === "speakers") {
    options = [
      { label: "Speaker email", value: "email" },
      { label: "Speaker fullname", value: "fullname" },
      { label: "Speaker position", value: "position" },
      { label: "Speaker company", value: "company" },
    ];
  }

  //# Sponsors
  if (target === "sponsors") {
    options = [
      { label: "Sponsor email", value: "email" },
      { label: "Company name", value: "name" },
      { label: "Partnership type", value: "type" },
      { label: "Partnership level", value: "level" },
    ];
  }

  // ...
  return [
    ...options,
    { label: "Ticket name", value: "ticket-name" },
    { label: "Other", value: "" },
  ];
};

export const forthDetailOptions = [
  { label: "Ticket name", value: "ticket-name" },
  { label: "Other", value: "" },
];
