import { Box, Button, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import cx from "classnames";
import { Formik, useFormikContext } from "formik";
import React from "react";

import {
  EntryAutocomplete,
  EntrySelect,
  EntrySwitch,
  EntryText,
} from "../../../components/Entry";
import EventForm from "../../../components/EventForm";

const emptyField = {
  label: "",
  type: "text",
  required: false,
  multiselect: false,
  settings: {
    idx: -1,
    min: "",
    max: "",
    options: [],
    base: false,
    // ...
    multiselect: false,
    multiselectMax: 1,
    description: ""
  },
};

const FieldItem = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F4F6F8",
    border: "1px solid #B4BCC3",
    borderRadius: "8px",
    padding: "10px 16px",
    margin: "0 0 24px 0",
    width: "80%",
    "&.__curr": {
      border: "1px solid #00AB55",
    },
    "&:last-child": {
      margin: "0",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    flex: "1",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    color: "#637381",
    // color: "#333333",
    fontSize: "15px",
    lineHeight: "22px",
  },
  btn: {
    color: "#fff",
    borderRadius: "5px",
    fontSize: "12px",
    width: "55px",
    minWidth: "55px",
    padding: "4px 0",
    "&.__edit": {
      background: "#00AB55",
      margin: "0 5px 0 0",
    },
    "&.__delete": {
      background: "#DB1F35",
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      color: "#fff",
      background: "#DFE3E8",
    },
  },
  actions: {},
}))(({ classes, idx, isCurrIdx, data, onEditField, onDeleteField }) => {
  // ...
  return (
    <Box className={cx(classes.root, { __curr: isCurrIdx })}>
      <Box className={classes.title}>{data.label}</Box>
      <Box className={classes.actions}>
        <Button
          className={`${classes.btn} __edit`}
          onClick={() =>
            onEditField({
              ...data,
              settings: { ...(data?.settings || {}), idx },
            })
          }
          disabled={data?.settings?.base}
        >
          Edit
        </Button>
        <Button
          className={`${classes.btn} __delete`}
          onClick={() => onDeleteField(idx)}
          disabled={data?.settings?.base}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
});

const FormBuilder = withStyles((theme) => ({
  root: {
    margin: "70px 0",
    [theme.breakpoints.down("md")]: {
      margin: "40px 0",
    },
  },
  header: {
    margin: "0 0 20px 0",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "600",
    "& .__title": {
      color: "#333333",
      fontSize: "20px",
      lineHeight: "54px",
    },
    "& .__sub": {
      color: "#0E8C93",
      fontSize: "16px",
      lineHeight: "54px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 0 15px 0",
      "& .__title": {
        fontSize: "18px",
        lineHeight: "20px",
        margin: "0 0 20px 0",
      },
      "& .__sub": {
        fontSize: "15px",
        lineHeight: "20px",
      },
    },
  },
  form: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  left: {
    flex: "1",
    padding: "30px 78px 30px 0",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "0 0 40px 0",
    },
  },
  sep: {
    borderLeft: "1px solid #b4bcc373",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  right: {
    flex: "1",
    padding: "30px 0 30px 78px",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  empty: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    "& .__svg": {
      margin: "0 0 40px 0",
    },
    "& .__txt": {
      color: "#919EAB",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "20",
      lineheight: "23px",
      fontWeight: "600",
      textAlign: "center",
      maxWidth: "360px",
    },
  },
}))(({ classes }) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { values, setFieldValue } = useFormikContext();
  const valFieldsEntries = Array.from(values?.regFields || []);
  let entries = Array.from(valFieldsEntries);
  // ...
  const onManageField = (data) => {
    const isNew = data.settings.idx === -1;
    // ...
    if (isNew) {
      setFieldValue("regFields", entries.concat([data]));
    } else {
      entries[data.settings.idx] = data;
      setFieldValue("regFields", entries);
    }
  };
  const onDeleteField = (idx) => {
    entries.splice(idx, 1);
    setFieldValue("regFields", entries);
  };
  // ...
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className="__title">Registration form fileds</Box>
        <Box className="__sub">Add new field</Box>
      </Box>
      <Formik initialValues={emptyField}>
        {(helpers) => {
          const values = helpers?.values || {};
          // ...
          const isSelector = values?.type === "select";
          const isCheckbox = values?.type === "checkbox";
          // ...
          const isMaxed = values?.settings?.multiselect;
          const isRequired = values?.required;
          const canMinMax = isRequired && !isSelector && !isCheckbox;
          // ...
          return (
            <Box className={classes.form}>
              <Box className={classes.left}>
                <EventForm spacing={2}>
                  <EntrySelect
                    xs={12}
                    md={6}
                    label="Field type"
                    name="type"
                    options={[
                      { label: "Text", value: "text" },
                      { label: "Number", value: "number" },
                      { label: "Selector", value: "select" },
                      { label: "Checkbox", value: "checkbox" },
                    ]}
                  />
                  <EntryText
                    xs={12}
                    md={6}
                    label="Field name"
                    name="label"
                    placeholder="Eg, Full name"
                    maxLength={150}
                  />
                  {isSelector && (
                    <EntryAutocomplete
                      freeSolo
                      xs={12}
                      md={12}
                      name="settings.options"
                      label="Selector options"
                      helper="Press ENTER to add options"
                      options={[]}
                      sx={{ margin: "25px 0 0 0" }}
                    />
                  )}
                  <EntrySwitch
                    label="This field is required"
                    name="required"
                    sx={{ margin: isMobile ? "10px 0" : "15px 0" }}
                    xs={6}
                  />
                  {isSelector && (
                    <EntrySwitch
                      label="Enable multiselect"
                      name="settings.multiselect"
                      sx={{ margin: isMobile ? "10px 0" : "15px 0" }}
                      xs={6}
                    />
                  )}
                  {isSelector && isMaxed && (
                    <EntryText
                      label="Max number of allowed choices"
                      type="number"
                      name="settings.multiselectMax"
                      placeholder=""
                      xs={12}
                      md={12}
                    />
                  )}
                  <Grid item xs={12} />
                  {canMinMax && (
                    <>
                      <EntryText
                        xs={6}
                        md={6}
                        label="Min length"
                        type="number"
                        name="settings.min"
                        placeholder="5"
                      />
                      <EntryText
                        xs={6}
                        md={6}
                        label="Max length"
                        type="number"
                        name="settings.max"
                        placeholder="10"
                      />
                    </>
                  )}
                  <EntryText 
                    xs={12}
                    md={12}
                    label="Description"
                    name="settings.description"
                    placeholder="field description"
                    noMaxLength={true}
                    multiline={true}
                  />

                  <Grid
                    item
                    xs={12}
                    style={{
                      width: "100%",
                      marginTop: isMobile ? "0" : "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      style={{ width: "48%" }}
                      disabled={!helpers.values?.label}
                      onClick={() => {
                        onManageField(helpers.values);
                        helpers.resetForm({ values: emptyField });
                      }}
                    >
                      {values?.settings?.idx > -1
                        ? "Update field"
                        : "Generate field"}
                    </Button>

                    {values?.settings?.idx > -1 ? (
                      <Button
                        size="large"
                        variant="contained"
                        style={{ width: "48%" }}
                        onClick={() => {
                          helpers.resetForm({ values: emptyField });
                        }}
                      >
                        Clear
                      </Button>
                    ) : (
                      <div />
                    )}
                  </Grid>
                </EventForm>
              </Box>
              <Box className={classes.sep} />
              <Box className={classes.right}>
                {valFieldsEntries.map((data, idx) => (
                  <FieldItem
                    key={idx}
                    idx={idx}
                    data={data}
                    isCurrIdx={values?.settings?.idx === idx}
                    onEditField={(data) =>
                      helpers.resetForm({
                        values: {
                          ...data,
                          settings: { ...(data?.settings || {}), idx },
                        },
                      })
                    }
                    onDeleteField={onDeleteField}
                  />
                ))}
                {!valFieldsEntries.length && (
                  <Box className={classes.empty}>
                    <Box className="__svg">
                      <img alt="" src="/static/illustrations/emptyFields.svg" />
                    </Box>
                    <Box className="__txt">
                      No custom fileds generated, create your first on the left
                      form
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
});

export default FormBuilder;
