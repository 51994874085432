import "./style.scss";

import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ErrorIcon from "@material-ui/icons/Error";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import _ from "underscore";

import useFile from "../../hooks/useFile";
import { getDefaults } from "../../utils/defaults";
import { EntryCheckbox, EntryPhoto, EntryText } from "../Entry";
import EventButton from "../EventButton";
import EventForm from "../EventForm";
import { validationSchema } from "./entries";
const iniValues = validationSchema.cast();

const AddSpeaker = ({
  data = {},
  onAddSpeaker,
  onEditSpeaker,
  // ...
  isLoading: isLA,
  // ...
  ...props
}) => {
  const dValues = getDefaults();
  const [canNext, setCanNext] = useState({});
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  const { isLoading: isLF, onUpload } = useFile();
  const isEdit = data?.id;
  // ...
  const isLoading = isLA || isLF;
  const imgSrc = props.values?.image?.url || null;
  // ...
  const onValidate = async () => {
    const validate = await props.validateForm(props.values);
    //console.log("props.values: ", props.values)
    //console.log("validate: ", validate)
    setCanNext({ ...validate });
  };
  // ...
  const onUploadFile = async (name, data) => {
    const res = await onUpload(data);
    props.setFieldValue(name, res?.[0] || null);
  };
  // ...
  useEffect(() => {
    onValidate();
  }, [props.values]);
  // ...
  useEffect(() => {
    if (isLA) return;
    // ...
    let values = { ...iniValues, ...data };
    if (!data?.image) values.image = dValues.speakerImage;
    // ...
    props.resetForm({ values });
  }, [data]);
  // ...
  const canSave = !_.isEmpty(canNext);
  // ...
  return (
    <>
      <Grid container sx={{ margin: "0 0 40px 0" }}>
        <Grid item xs={12} md={4}>
          <EntryPhoto
            name="image"
            defaultK="speakerImage"
            src={imgSrc}
            required
            htmlFor="speaker-image"
            // ...
            isLoading={isLoading}
            isRounded
            // ...
            onUpload={onUploadFile}
            meta={{
              width: 300,
              height: 300,
              helper: "Speaker photo 300 x 300 px"
            }}
            // ...
            style={{ width: "160px", height: "160px" }}
          />
        </Grid>

        <Grid item xs={0} md={1} />
        <EventForm xs={12} md={7} spacing={1} sx={{ padding: "0", marginTop: "-20px" }}>
          <EntryText required topLabel="First name" placeholder="Speaker first name" name="firstName" md={12} />
          <EntryText required topLabel="Last name" placeholder="Speaker last name" name="lastName" md={12} />
        </EventForm>
      </Grid>
      <EventForm>
        <EntryText label="Biography" name="description" multiline minRows={2} maxRows={2} md={12} noMaxLength maxLength="9999999"/>
        <EntryText topLabel="Position" placeholder="CEO, Manager, artist, etc." name="position" maxLength="100"/>
        <EntryText topLabel="Company" placeholder="Company , organization" name="company" maxLength="100"/>
        <EntryText required topLabel="Email" placeholder="speaker@domain.com" type="email" name="email" />
        <EntryText
          // type="number"
          topLabel="Phone number"
          plaeholder="+216 22 000 000"
          name="phone"
        />
        <EntryText topLabel="Linkedin" placeholder="LinkedIn profile" name="linkedin" maxLength={250} />
        <EntryText topLabel="Facebook" placeholder="Facebook profile" name="facebook" maxLength={250} />
        <EntryText topLabel="Instagram" placeholder="Instagram profile" name="instagram" maxLength={250} />
        <EntryCheckbox
          name="isModerator"
          label="Make this speaker as agenda modarator"
          labelPlacement="end"
          sx={{ marginTop: isMobile ? "0" : "30px" }}
        />
        <EntryCheckbox  name="sendEmailNotification" label="Send email invitation to the speaker" labelPlacement="end" />
        <Grid  item xs={12} md={12}>
          <div className="EventEntityDrawer__field__explain">
          ℹ️ : One email to confirm speaker participation, and an email for each affectation in a session to get backstage link.
          </div>
        </Grid>
        
         
        
        
      </EventForm>

      <div className="DrawerRequired">
        {!canSave ? (
          <div className="DrawerRequired__info" />
        ) : (
          <div className="DrawerRequired__info">
            <ErrorIcon className="__icon" />
            <span className="__label">Fill all required details</span>
          </div>
        )}

        <EventButton
          isLoading={isLoading}
          disabled={canSave}
          handler={() => (isEdit ? onEditSpeaker(props.values) : onAddSpeaker(props.values))}>
          Save Speaker
        </EventButton>
      </div>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(AddSpeaker);
