import { Grid, TextField } from "@material-ui/core";
import { DesktopDateTimePicker } from "@material-ui/lab";
import cx from "classnames";
import { useField } from "formik";
import React from "react";

const EntryDateTime = ({
  required,
  label,
  topLabel,
  name,
  inputFormat = "dd/MM/yyyy HH:mm",
  minDate ,
  maxDate,
  // ...
  xs = 12,
  md = 6,
  // ...
  disabled
}) => {
  const [field, meta, helpers] = useField({ name });
  // ...
  return (
    <Grid item xs={xs} md={md}>
      {topLabel && (
        <label
          style={{
            display: "block",
            color: "#333",
            fontFamily: "Poppins, sans-serif",
            fontSize: "13px",
            lineHeight: "20px",
            fontWeight: "500",
            margin: "0 0 5px 0"
          }}>
          {topLabel}
        </label>
      )}
      <DesktopDateTimePicker
        label={label}
        name={name}
        value={new Date(field.value)}
        onChange={(date) => {
          helpers.setValue(date);        
        }}
        inputFormat={inputFormat}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        ampm={false}
        // ...
        renderInput={(params) => (
          <TextField
            className={cx("Entry EntryTime", { __required: required })}
            {...params}
            // ...
            error={!!meta.error}
            helperText={meta.error}
            // ...
            // helperText={null}
            fullWidth
            margin="normal"
          />
        )}
      />
    </Grid>
  );
};

export default EntryDateTime;
