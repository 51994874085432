import { IconButton, TextField, Toolbar, Tooltip } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import React from "react";

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3)
}));

const TableToolbar = ({
  searchFor,
  setSearchFor,
  // ...
  hasExport,
  exportHandler,
  children
}) => {
  // ...
  return (
    <RootStyle>
      <TextField
        placeholder="Search..."
        value={searchFor}
        onChange={(e) => setSearchFor(e.target.value)}
        sx={{ width: "35%" }}
        inputProps={{ sx: { height: "20px" } }}
      />
      {children}

      {hasExport > 0 && (
        <Tooltip title="Export">
          <IconButton onClick={() => exportHandler()}>
            <img src="/static/icons/export.svg" alt="" />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
};

export default TableToolbar;
