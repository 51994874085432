import { PATH_EVENT } from "../routes/paths";

const sidebarConfig = [
  {
    subheader: "BUILD YOUR EVENT",
    items: [
      {
        title: "Event Informations",
        href: PATH_EVENT.setup,
        icon: "/static/setup/nav/setup.svg",
      },
      {
        title: "Event Agenda ",
        href: PATH_EVENT.stages,
        icon: "/static/setup/nav/stages.svg",
      },
      {
        title: "Manage speakers",
        href: PATH_EVENT.speakers,
        icon: "/static/setup/nav/speakers.svg",
      },
      {
        title: "Sponsors & partners",
        href: PATH_EVENT.sponsors,
        icon: "/static/setup/nav/sponsors.svg",
      },
    ],
  },
  {
    subheader: "CONNECT WITH YOUR AUDIENCE",
    items: [
      {
        title: "Event Attendees",
        href: PATH_EVENT.attendees,
        icon: "/static/setup/nav/attendees.svg",
      },
      {
        title: "Email templates",
        href: PATH_EVENT.emails,
        icon: "/static/setup/nav/emails.svg",
      },
      {
        title: "Tickets & badges",
        href: PATH_EVENT.tickets,
        icon: "/static/setup/nav/tickets.svg",
      },
      {
        title: "Checkin lists",
        href: PATH_EVENT.checkin,
        icon: "/static/setup/nav/scan.svg",
      },
    ],
  },
  {
    subheader: "GET EVENT INSIGHTS",
    items: [
      {
        title: "Live insights",
        href: PATH_EVENT.live,
        icon: "/static/setup/nav/dashboard.svg",
      },
      {
        title: "Carbon footprint",
        href: PATH_EVENT.carbon,
        icon: "/static/setup/nav/carbon.svg",
      },
    ],
  },
];

export default sidebarConfig;
