import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { atom, useRecoilCallback, useRecoilStateLoadable, useRecoilValue } from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "CHECKINLIST", default: [] });

export default function useCheckinList() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const checkinLists = await HTTP.GET(`/organizations/events/${THE_ID}/getCheckinLists?start=0&limit=-1`);
      // ...
      setAtom(checkinLists || []);
    } catch (err) {
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onCreate = useRecoilCallback(() => async (title) => {
    setIsLoading(true);
    setHasError(false);
    // ...
    
    // ...
    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/createCheckinList`, {
        title
      });
      // ...
      enqueueSnackbar("Checkin list created", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  const onRemove = useRecoilCallback(() => async (ids = []) => {
    setIsLoading(true);
    setHasError(false);

    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/deleteCheckinList`, {
        ids
      });
      // ...
      enqueueSnackbar("Checkin lists deleted", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return null;
    }
  });
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onCreate,
    onRemove
  };
}
