import "./style.scss";

import React from "react";

// import PreviewBottom from "./Bottom";
import PreviewTop from "./Top";

const TicketPrintable = ({ eventData, ticketData, attendee }) => {
  // ...
  return (
    <div
      className="TicketPrintable"
      style={{
        color: ticketData?.textColor,
        backgroundColor: ticketData?.backgroundColor,
        backgroundImage: `url(${ticketData?.backgroundImage?.url})`,
        // backgroundSize: "cover",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="TicketPrintable__content">
        <div className="__group __top">
          <PreviewTop
            event={eventData}
            data={ticketData}
            attendee={attendee}
            style={{}}
          />
          <PreviewTop
            event={eventData}
            data={ticketData}
            attendee={attendee}
            style={{}}
          />
        </div>

        {/* <div className="__group __bottom">
          <PreviewBottom event={eventData} data={ticketData} style={{}} />
          <PreviewBottom event={eventData} data={ticketData} style={{}} />
        </div> */}
      </div>
    </div>
  );
};

export default TicketPrintable;
