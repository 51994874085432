import { Grid } from "@material-ui/core";
import { withFormik } from "formik";
import React, { useEffect } from "react";

import { EntryText } from "../../../components/Entry";
import EntryImage from "../../../components/Entry/EntryImageV2";
import EventActions from "../../../components/EventActions";
import EventForm from "../../../components/EventForm";
import Page from "../../../components/Page";
import useEvent from "../../../hooks/useEvent";
import useFile from "../../../hooks/useFile";
// ...
import { valSchemaBrand as validationSchema, formatEventBrand } from "../entries";
const iniValues = validationSchema.cast();

const EventBranding = ({ currTab, setCurrTabHandler, onBackHandler, ...props }) => {
  const { isLoading: isLE, data, onUpdate } = useEvent();
  const { isLoading: isLF, onUpload } = useFile();
  const isLoading = isLE || isLF;
  // ...
  const onSaveData = async () => {
    if (isLoading) return;
    // ...
    const res = await onUpdate(formatEventBrand(props.values));
    if (res.error) return;
    setCurrTabHandler(currTab + 1);
  };
  // ...
  const onUploadFile = async (name, data) => {
    if (isLoading) return;
    // ...
    const res = await onUpload(data);
    const ids = res?.map((d) => d.id);
    // ...
    await onUpdate(formatEventBrand({ ...props.values, [name]: ids[0] }));
  };
  // ...
  useEffect(() => {
    if (isLoading) return;
    // ...
    props.resetForm({
      values: {
        ...iniValues,
        ...data,
        isFree: data?.isFree ? "free" : "paid"
      }
    });
  }, [data, isLoading]);
  // ...
  return (
    <Page title="Event Branding | Evey">
      <Grid container spacing={8}>
        <EventForm item xs={12} md={6} alignContent="flex-start" alignItems="flex-start">
          <EntryImage
            required
            preview
            previewStyle={{ height: "150px" }}
            label="Small event cover 300 x 300 px"
            placeholder="Select event logo"
            name="card"
            defaultK="eventCard"
            md={12}
            sx={{ margin: "0 0 40px 0" }}
            // ...
            isLoading={isLoading}
            onUpload={onUploadFile}
            meta={{
              width: 300,
              height: 300,
              helper: "Small event cover 300 x 300 px"
            }}
          />
          <EntryImage
            required
            preview
            label="Large event cover  820 x 321 px"
            placeholder="Select event banner"
            name="cover"
            defaultK="eventCover"
            md={12}
            // ...
            isLoading={isLoading}
            onUpload={onUploadFile}
            meta={{
              width: 820,
              height: 321,
              helper: "Large event cover 820 x 321 px"
            }}
          />
        </EventForm>
        <EventForm item xs={12} md={6} spacing={0} sx={{ height: "440px" }}>
          <EntryText label="LinkedIn" name="linkedin" placeholder="Event Link on Linkedin " maxLength={250} md={12} />
          <EntryText label="Facebook" name="facebook" placeholder="Event Link on Facebook" maxLength={250} md={12} />
          <EntryText label="Youtube" name="videoCover" placeholder="Teasing video Link " maxLength={250} md={12} />
          <EntryText label="Website" name="website" placeholder="https://" maxLength={250} md={12} />
        </EventForm>
      </Grid>

      <EventActions
        save={{
          isActive: true,
          label: "Save & continue",
          isLoading,
          isDisabled: isLoading,
          handler: onSaveData
        }}
        cancel={{
          isActive: true,
          label: "Back",
          handler: onBackHandler
        }}
      />
    </Page>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(EventBranding);
