import { Box } from "@material-ui/core";
import React from "react";

const TabPanel = ({ children, value, currTab, sx }) => (
  <Box role="tabpanel" hidden={value !== currTab} id={`event-setup-${currTab}`} aria-labelledby={`event-setup-${currTab}`} sx={{ ...sx }}>
    {value === currTab && children}
  </Box>
);

export default TabPanel;
