import { Box, IconButton, Menu, MenuItem, TableCell } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import http from "../../api/http";

const TableAttendeesCellActions = ({
  label,
  idx,
  data,
  // ...
  // ...
  onDeleteHandler,
  // ...
  disabledOptions = false
}) => {
  const [open, setOpen] = useState(null);
  // ...
  const resenInvitation = async () => {
    // console.log("data: ", data)
    await http.POST(`/organizations/events/${data?.event?.id}/resendInvitation/${data.id}`);
  }
  const resenConfirmation = async () => {
    // console.log("data: ", data)
    await http.POST(`/organizations/events/${data?.event?.id}/resendConfirmation/${data.id}`);
  }
  const donwloadBadge = async () => {
    // console.log("data: ", data)
    const url = await http.POST(`/organizations/events/${data?.event?.id}/downloadBadge/${data.id}`);
    // console.log("url: ", url)
    const link = document.createElement("a");
    link.download = `badge - ${data.id}.pdf`; // <- name instead of 'name'
    link.href = url;
    link.click();
    link.remove();
  }
  // ...
  let OPTIONS = [{ label: `Delete ${label}`, handler: onDeleteHandler, isDanger: true }];
  // ...
  OPTIONS.push({
    label: `Resend invitation `, handler: resenInvitation
  });
  if(data?.registrationStatus==="registered"){
    OPTIONS.push({
      label: `Resend confirmation `, handler: resenConfirmation
    });
    OPTIONS.push({
      label: `Download badge `, handler: donwloadBadge
    });
  }
  // ...
  return (
    <TableCell align="right">
      <Box sx={{ position: "relative" }}>
        <IconButton disabled={disabledOptions} aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={(e) => setOpen(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          keepMounted
          anchorEl={open}
          onClose={() => setOpen(null)}
          open={Boolean(open)}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: "20ch"
            }
          }}>
          {OPTIONS.map((opt) => (
            <MenuItem
              key={opt.label}
              onClick={() => {
                opt.handler(data, idx);
                setOpen(null);
              }}
              sx={{ color: opt.isDanger ? "red" : "" }}>
              {opt.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </TableCell>
  );
};

export default TableAttendeesCellActions;
