import "./style.scss";

import { Box, Drawer } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

import { MIconButton } from "../@material-extend";

const DRAWER_WIDTH = "700px";

const EventEntityDrawer = ({
  open,
  closeDrawer,
  title,
  sub,
  // ...
  children,
  // ...
  width,
  innerSX = {},
  headerSX = {},
  contentSX = {}
}) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  return (
    <Drawer
      className="EventEntityDrawer"
      // ...
      open={open}
      anchor="right"
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: isMobile ? "100%" : width || DRAWER_WIDTH,
          background: "#f8F8fa",
          right: "0",
          left: "unset",
          maxWidth: "100%"
        }
      }}>
      <Box className="EventEntityDrawer__inner scrollbar" style={{ ...innerSX }}>
        <Box className="__close">
          <MIconButton onClick={closeDrawer}>
            <img src="/static/icons/close.svg" style={{ width: isMobile ? "25px" : "35px" }} />
          </MIconButton>
        </Box>
        <Box className="EventEntityDrawer__header" style={{ ...headerSX }}>
          <h2>{title}</h2>
          <p>{sub}</p>
        </Box>
        <Box className="EventEntityDrawer__content" style={{ ...contentSX }}>
          {children}
        </Box>
        <Box className="EventEntityDrawer__actions" />
      </Box>
    </Drawer>
  );
};

export default EventEntityDrawer;
