import React from "react";

export const getCategoryName = (val) => {
  let out = "";
  // ...
  if (val === "registration") out = "Registration email";
  if (val === "invitation") out = "Invitation email";
  if (val === "speaker") out = "Speaker email";
  if (val === "sponsor") out = "Sponsor email";
  if (val === "speaker_confirmation") out = "Speaker Confirmation email";
  // ...
  return out;
};

const Category = ({ val }) => {
  return <span>{getCategoryName(val)}</span>;
};

export default Category;
