import { Box, Card, Container } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";

import AddEmailSettings from "../../components/AddEmailSettings";
import AddSpeaker from "../../components/AddSpeaker";
import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventEntityDrawer from "../../components/EventEntityDrawer";
import EventEntityTable from "../../components/EventEntityTable";
import EventSectionSubTitle from "../../components/EventSectionSubTitle";
import EventSectionTitle from "../../components/EventSectionTitle";
import ModalValidation from "../../components/ModalValidate";
// ...
import Page from "../../components/Page";
import useSpeakers from "../../hooks/useSpeakers";
import SpeakerStatus from "./components/Status";
import { EXPORT_HEAD, TABLE_HEAD } from "./config";
import * as _ from "underscore";

const speakerName = (f, l) => {
  return f && l ? `${f} ${l}` : f || l;
};

const EventSpeaker = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const [delModal, setDelModal] = useState({
    open: false,
    id: null,
    label: "",
  });
  // ...
  const {
    isLoading,
    data: speakers,
    onCreate,
    onUpdate,
    onRemove,
    onResend,
  } = useSpeakers();
  // ...
  const [drawer, setDrawer] = useState({ open: false, data: {}, idx: "" });
  const [drawerEmail, setDrawerEmail] = useState(false);
  const speakersList = speakers.map((s) => ({
    ...s,
    name: speakerName(s?.firstName, s?.lastName),
    statusLabel: <SpeakerStatus val={s.status} />,
    // ...
    sessionsBase: s?.sessions,
    sessions: s?.sessions?.length || "",
  }));
  // ...
  const onAddSpeaker = async (data) => {
    await onCreate(data);
    setDrawer({ open: false, data: {}, idx: "" });
  };
  const onEditSpeaker = async (data) => {
    const newSpeakers = _.reject(speakers, s => s.id==data.id);
    newSpeakers.push(data);
    await onUpdate(newSpeakers);
    // ...
    setDrawer({ open: false, data: {}, idx: "" });
  };
  const onDeleteSpeaker = async (id) => {
    await onRemove({ ids: [id] });
    // ...
    setDelModal({ open: false, id: null, label: "" });
  };
  // ...
  return (
    <Page title="Manage Speakers">
      <Container>
        <Box>
          <EventSectionTitle
            title="Manage Speakers"
            subTitle="Add and invite speakers"
            src="/static/setup/title/speakers.svg"
          />

          {speakersList.length > 0 && (
            <EventSectionTitle
              title="View speakers list"
              subTitle="Review and invite speakers"
              sx={{ marginBottom: "30px !important" }}
            >
              <EventButton
                isLoading={isLoading}
                handler={() => setDrawer({ open: true, data: {}, idx: "" })}
              >
                {isMobile && "New"}
                {!isMobile && "New speaker"}
              </EventButton>
            </EventSectionTitle>
          )}

          {/* {speakersList.length > 0 && (
            <EventSectionSubTitle>
              <EventButton emails handler={() => setDrawerEmail(true)}>
                Email invitation settings
              </EventButton>
            </EventSectionSubTitle>
          )} */}

          {speakersList.length === 0 && (
            <EventEmptyView
              src="/static/setup/empty/speakers.svg"
              title="Manage speakers"
              desc="Create speaker profiles and send invitation"
              btnLabel="Add new speaker"
              handler={() => setDrawer({ open: true, data: {} })}
              isLoading={isLoading}
            />
          )}

          {speakersList.length > 0 && (
            <Box>
              <Card>
                <EventEntityTable
                  label="speaker"
                  head={TABLE_HEAD}
                  data={speakersList}
                  // ...
                  searchLabel="name"
                  exportName="speakers-list"
                  exportHead={EXPORT_HEAD}
                  // ...
                  onResend={onResend}
                  //...
                  onEditHandler={(data, idx) =>
                    setDrawer({ open: true, data, idx })
                  }
                  onDeleteHandler={(data) => {
                    const label = data?.firstName + " " + data?.lastName;
                    // ...
                    setDelModal({
                      open: true,
                      id: data.id,
                      label,
                    });
                  }}
                />
              </Card>
            </Box>
          )}
        </Box>

        <EventEntityDrawer
          open={drawer.open}
          closeDrawer={() => setDrawer({ open: false, data: {}, idx: "" })}
          title={drawer?.data?.id ? "Edit speaker" : "Add new speaker"}
          sub="Enter speaker details"
          width="800px"
        >
          <AddSpeaker
            data={drawer.data}
            onAddSpeaker={onAddSpeaker}
            onEditSpeaker={onEditSpeaker}
            // ...
            isLoading={isLoading}
          />
        </EventEntityDrawer>

        {/* <EventEntityDrawer
          open={drawerEmail}
          closeDrawer={() => setDrawerEmail(false)}
          title="Email invitation settings"
          sub="Configure email invitation dates and templates"
        >
          <AddEmailSettings
            open={drawerEmail}
            onClose={() => setDrawerEmail(false)}
          />
        </EventEntityDrawer> */}
      </Container>

      <ModalValidation
        open={delModal.open}
        title="Delete speaker"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteSpeaker(delModal.id),
        }}
        no={{
          label: "cancel",
          handler: () => setDelModal({ open: false, id: null, label: "" }),
        }}
      >
        Are you sure to delete {delModal?.label || "speaker"}?
      </ModalValidation>
    </Page>
  );
};

export default EventSpeaker;
