import { Box, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useRef, useState } from "react";
import Moment from "react-moment";

import DotsMenu from "../../../../components/DotsMenu";
import { EntryDateButton } from "../../../../components/Entry";
import useEvent from "../../../../hooks/useEvent";
import { dateToISO } from "../../../../utils/dates";
import EventTab from "../../components/EventTab";

const DateTab = ({ date, datesList, onEdit, onDelete }) => {
  const { data: event } = useEvent();
  const { startDate, endDate } = event || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();
  // ...
  return (
    <EntryDateButton
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      // ...
      minDate={startDate}
      maxDate={endDate}
      // ...
      value={date}
      disableDates={datesList}
      onChangeDate={(date) => {
        onEdit(dateToISO(date));
      }}>
      <div className="DateTab">
        <div className="DateTab__label" ref={ref}>
          <Moment format="D MMM YYYY">{date}</Moment>
        </div>
        <div className="DateTab__dots">
          <DotsMenu
            options={[
              {
                label: "Edit date",
                handler: () => setAnchorEl(ref.current)
              },
              {
                label: "Delete date",
                handler: () => {
                  onDelete(date);
                },
                isDanger: true
              }
            ]}
          />
        </div>
      </div>
    </EntryDateButton>
  );
};

const HeaderTabs = withStyles((theme) => ({
  // border: "1px solid red",
  // ...
  scrollButtons: {
    [theme.breakpoints.down("md")]: {
      width: "15px",
      paddingTop: "10px"
    }
  }
}))((props) => <Tabs {...props} />);

const DatesAdd = ({
  currDate,
  datesList,
  setDatesList,
  dateTab,
  setDateTab,
  // ...
  onEditSlots
}) => {
  // const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  return (
    <Box className="EventStageItem__group __dates">
      <HeaderTabs
        value={dateTab}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        // TabIndicatorProps={{ style: { display: "none" } }}
        style={{ margin: "0 0 0 5px" }}>
        {datesList.map((date, idx) => (
          <EventTab
            disableRipple
            key={idx}
            value={idx}
            onClick={() => setDateTab(idx)}
            label={
              <DateTab
                date={date}
                datesList={datesList}
                onEdit={(date) => {
                  onEditSlots(idx, date);
                }}
                onDelete={(date) => {
                  setDatesList((dates) => dates.filter((d) => d !== date));
                }}
              />
            }
          />
        ))}
      </HeaderTabs>
    </Box>
  );
};

export default DatesAdd;
