import { Box, Card, Container } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";

import AddEmailSettings from "../../components/AddEmailSettings";
import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventEntityDrawer from "../../components/EventEntityDrawer";
import EventEntityTableAttendees from "../../components/EventEntityTable/TableAttendees";
import EventPrediction from "../../components/EventPrediction";
import EventSectionSubTitle from "../../components/EventSectionSubTitle";
import EventSectionTitle from "../../components/EventSectionTitle";
import ModalValidation from "../../components/ModalValidate";
// ...
import Page from "../../components/Page";
import useAttendees from "../../hooks/useAttendees";
import useEvent from "../../hooks/useEvent";
import AddAttendee from "./components/AddAttendee";
import EstimationSet from "./components/EstimationSet";
import AttendeeInvDate from "./components/InvDate";
import AttendeeStatus from "./components/Status";
import { EXPORT_HEAD_ATTENDEES, TABLE_HEAD } from "./config";

const EventAttendee = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const [delModal, setDelModal] = useState({
    open: false,
    id: null,
    label: "",
  });
  // ...
  const { data } = useEvent();
  const { isLoading, data: attendees, count, page, pageSize, searchText, onUpdate, onRemove, onUpdatePage, onUpdatePageSize, onUpdateSearchText, onUpdateSort } = useAttendees();
  // ...
  const [openEstimation, setOpenEstimation] = useState(false);
  const [estimation, setEstimation] = useState(null);
  // ...
  const [drawer, setDrawer] = useState({ open: false, data: {}, idx: "" });
  const [drawerEmail, setDrawerEmail] = useState(false);
  const attendeesList = attendees.map((a) => ({
    ...a,
    name: a?.custom.name || a?.custom.fullName || a?.custom.firstName || a?.custom.lastName || "",
    // ...
    inviteDateLabel: <AttendeeInvDate date={a.created_at} />,
    invLabel: <AttendeeStatus value={a?.invitationStatus} />,
    remLabel: <AttendeeStatus value={a?.reminderStatus} />,
    statusLabel: <><AttendeeStatus value={a?.registrationStatus} />{!!a?.custom?._payedAmout ? "$" : ""}</>,
    // ...
    inviteDateExp: a.created_at,
    invExp: a?.invitationStatus,
    remExp: a?.reminderStatus,
    statusExp: a?.registrationStatus,
  }));
  // ...
  const onEditAttendee = async (data) => {
    await onUpdate(data);
    setDrawer({ open: false, data: {}, idx: "" });
  };
  // ...
  const onDeleteAttendee = async (id) => {
    await onRemove([id]);
    setDelModal({ open: false, id: null, label: "" });
  };
  // ...
  const estimate = (data) => {
    //console.log("data: ", data)
    setEstimation(data);
    setOpenEstimation(false);
  }
  // ...
  // ...
  // const onDeleteAll = async () => {
  //   const ids = attendees.map((a) => a.id).filter((id) => !!id);
  //   await onRemove(ids);
  // };
  // ...
  return (
    <Page title="Manage Attendees">
      <Container>
        <Box>
          <EventSectionTitle
            title="Event attendees"
            subTitle="Invite and manage people"
            src="/static/setup/title/attendees.svg"
          />

          {count > 0 && (
            <EventSectionTitle
              title="View attendees list"
              subTitle="Review and invite people "
              sx={{ marginBottom: "30px !important" }}
            >
              <EventButton
                className="EventButton__estimation"
                isLoading={isLoading} 
                handler={() => setOpenEstimation(true)}
              >
                Estimate registrations
              </EventButton>
              <EventButton
                isLoading={isLoading}
                handler={() => setDrawer({ open: true, _id: null })}
              >
                {isMobile && "Add"}
                {!isMobile && "Add participant"}
              </EventButton>
            </EventSectionTitle>
          )}

          {count > 0 && (
            <EventSectionSubTitle>
              <EventButton emails handler={() => setDrawerEmail(true)}>
                Send Email Invitations
              </EventButton>
            </EventSectionSubTitle>
          )}

          {(data && estimation) && <EventPrediction estimationSet={estimation} event={data||{}}/> }

          {count === 0 && (
            <EventEmptyView
              src="/static/setup/empty/attendees.svg"
              title="Invite people"
              desc="Send customized invitation and manage attendees lists"
              btnLabel="Invite people"
              handler={() => setDrawer({ open: true, data: {}, idx: "" })}
              isLoading={isLoading}
            />
          )}

          {count > 0 && (
            <Box>
              <Card>
                <EventEntityTableAttendees
                  noEdit
                  label="attendee"
                  head={TABLE_HEAD}
                  data={attendeesList}
                  count={count}
                  page={page}
                  pageSize={pageSize}
                  searchText={searchText}
                  // ...
                  searchLabel="email"
                  exportName="attendees-list"
                  exportHead={EXPORT_HEAD_ATTENDEES}
                  // ...
                  // onEditHandler={(data, idx) =>
                  //   setDrawer({ open: true, data, idx })
                  // }
                  onUpdatePage={onUpdatePage}
                  onUpdatePageSize={onUpdatePageSize}
                  onUpdateSearchText={onUpdateSearchText}
                  onUpdateSort={onUpdateSort}
                  onDeleteHandler={(data) => {
                    setDelModal({
                      open: true,
                      id: data.id,
                      label: data?.name,
                    });
                  }}
                />
              </Card>
            </Box>
          )}
        </Box>

        <EventEntityDrawer
          open={drawer.open}
          closeDrawer={() => setDrawer({ open: false, data: {}, idx: "" })}
          title="Add new attendee"
          sub="Enter attendee details"
          width="490px"
        >
          <AddAttendee
            idx={drawer.idx}
            data={drawer.data}
            onEditAttendee={onEditAttendee}
            // ...
            attendees={attendees?.map((a) => a?.email)}
            isLoading={isLoading}
          />
        </EventEntityDrawer>

        <EventEntityDrawer
          open={drawerEmail}
          closeDrawer={() => setDrawerEmail(false)}
          title="Email invitation settings"
          sub="Configure email invitation dates and templates"
        >
          <AddEmailSettings
            open={drawerEmail}
            onClose={() => setDrawerEmail(false)}
          />
        </EventEntityDrawer>

        <EventEntityDrawer
          open={openEstimation}
          closeDrawer={() => setOpenEstimation(false)}
          title="Estimate the number of registrations"
          sub="Estimate the event registrations number based on your communication plan"
        >
          <EstimationSet
            open={drawerEmail}
            onClose={() => setDrawerEmail(false)}
            setOpenEstimation={setOpenEstimation}
            estimate={estimate}
          />
        </EventEntityDrawer>
      </Container>

      <ModalValidation
        open={delModal.open}
        title="Delete sponsor"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteAttendee(delModal.id),
        }}
        no={{
          label: "cancel",
          handler: () => setDelModal({ open: false, id: null, label: "" }),
        }}
      >
        Are you sure to delete {delModal?.label || "attendee"}?
      </ModalValidation>
    </Page>
  );
};

export default EventAttendee;
