import { Box, Card, Container } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";

import AddSponsor from "../../components/AddSponsor";
import EventButton from "../../components/EventButton";
import EventEmptyView from "../../components/EventEmptyView";
import EventEntityDrawer from "../../components/EventEntityDrawer";
import EventEntityTable from "../../components/EventEntityTable";
import EventSectionTitle from "../../components/EventSectionTitle";
import ModalValidation from "../../components/ModalValidate";
// ...
import Page from "../../components/Page";
import useSponsors from "../../hooks/useSponsors";
import SponsorStatus from "./components/Status";
import { EXPORT_HEAD, TABLE_HEAD } from "./config";

const EventSponsor = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const [delModal, setDelModal] = useState({
    open: false,
    id: null,
    label: "",
  });
  // ...
  const { isLoading, data: sponsors, onUpdate, onRemove } = useSponsors();
  // ...
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [drawer, setDrawer] = useState({
    open: false,
    data: {},
    idx: "",
    check: true,
  });
  const sponsorsList = sponsors.map((s) => ({
    ...s,
    statusLabel: <SponsorStatus val={s.status} />,
  }));
  // ...
  const onFetchSponsor = async (s, isNew) => {
    if (!isNew) {
      let sponsor = { ...s, sponsor: s.id };
      delete sponsor.id;
      //...
      setDrawer((o) => ({
        ...o,
        data: { ...o.data, ...sponsor, type: "sponsor", level: "silver" },
        check: false,
      }));
      // ...
      setIsReadOnly(true);
    } else {
      // ...
      setDrawer((o) => ({ ...o, data: { ...s }, check: false }));
      setIsReadOnly(false);
    }
  };
  // ...
  const onEditSponsor = async (data) => {
    await onUpdate(data);
    setDrawer({ open: false, data: {}, idx: "" });
  };
  const onDeleteSponsor = async (id) => {
    await onRemove(id);
    // ...
    setDelModal({ open: false, id: null, label: "" });
  };
  // ...
  return (
    <Page title="Sponsors and partners">
      <Container>
        <Box>
          <EventSectionTitle
            title="Manage sponsors & partners"
            subTitle="Add and invite sponsors"
            src="/static/setup/title/sponsors.svg"
          />

          {sponsorsList.length > 0 && (
            <EventSectionTitle
              title="View sponsors list"
              subTitle="Review and manage organizations"
              sx={{ marginBottom: "40px !important" }}
            >
              <EventButton
                isLoading={isLoading}
                handler={() =>
                  setDrawer({ open: true, data: {}, idx: "", check: true })
                }
              >
                {isMobile && "Add"}
                {!isMobile && "Add new sponsor"}
              </EventButton>
            </EventSectionTitle>
          )}

          {sponsorsList.length === 0 && (
            <EventEmptyView
              src="/static/setup/empty/sponsors.svg"
              title="Manage sponsors"
              desc="Add and manage event sponsors "
              btnLabel="Add new sponsor"
              handler={() =>
                setDrawer({ open: true, data: {}, idx: "", check: true })
              }
              isLoading={isLoading}
            />
          )}

          {sponsorsList.length > 0 && (
            <Box>
              <Card>
                <EventEntityTable
                  head={TABLE_HEAD}
                  data={sponsorsList}
                  // ...
                  searchLabel="name"
                  exportName="sponsors-list"
                  exportHead={EXPORT_HEAD}
                  // ...
                  onEditHandler={(data, idx) =>
                    setDrawer({ open: true, data, idx, check: false })
                  }
                  onDeleteHandler={(data) => {
                    setDelModal({
                      open: true,
                      id: data.id,
                      label: data?.name,
                    });
                  }}
                />
              </Card>
            </Box>
          )}
        </Box>

        <EventEntityDrawer
          open={drawer.open}
          closeDrawer={() =>
            setDrawer({ open: false, data: {}, idx: "", check: true })
          }
          title={drawer?.data?.id ? "Edit sponsor" : "Add new sponsor"}
          sub={drawer.check ? "" : "Enter sponsor details"}
          width={drawer?.check ? "490px" : "900px"}
        >
          <AddSponsor
            idx={drawer.idx}
            data={drawer.data}
            check={drawer.check}
            // ...
            isReadOnly={isReadOnly}
            onEditSponsor={onEditSponsor}
            onFetchSponsor={onFetchSponsor}
            // ...
            isLoading={isLoading}
          />
        </EventEntityDrawer>
      </Container>

      <ModalValidation
        open={delModal.open}
        title="Delete sponsor"
        isLoading={isLoading}
        // ...
        yes={{
          label: "delete",
          handler: () => onDeleteSponsor(delModal.id),
        }}
        no={{
          label: "cancel",
          handler: () => setDelModal({ open: false, id: null, label: "" }),
        }}
      >
        Are you sure to delete {delModal?.label || "sponsor"}?
      </ModalValidation>
    </Page>
  );
};

export default EventSponsor;
