import * as yup from "yup";

export const validationSchema = yup
  .object({
    id: yup.number().required().default(0),
    plannedAt: yup.date().nullable().default(null),
    reminder: yup.date().nullable().default(null)
  })
  .default({
    id: 0,
    plannedAt: null,
    reminder: null
  });
