import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import React from "react";

const SponsorSearch = ({ search, options, handler }) => {
  // ...
  return (
    <ClickAwayListener onClickAway={() => {}}>
      <div className="SponsorSearch">
        {search?.length >= 3 && (
          <Paper className="SponsorSearch__list" elevation={2}>
            {options.map((o) => (
              <MenuItem key={o.id} className="SponsorSearch__item" onClick={() => handler(o, false)}>
                {o.email}
              </MenuItem>
            ))}
            <MenuItem className="SponsorSearch__item __new" onClick={() => handler({ name: search }, true)}>
              Add new sponsor
            </MenuItem>
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SponsorSearch;
