import * as yup from "yup";

import { dateToID } from "../../utils/dates";

export const TABLE_HEAD = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Slot name",
  },
  {
    id: "startTime",
    numeric: false,
    disablePadding: true,
    label: "Start time",
  },
  {
    id: "durationLabel",
    numeric: false,
    disablePadding: true,
    label: "Duration",
  },
  {
    id: "speakerId",
    numeric: false,
    disablePadding: true,
    label: "Speakers",
  },
];

export const stageSchema = yup.object({
  label: yup.string().required("Stage name is required").default(""),
  description: yup.string().required("Description is rquired").default(""),
  // ...
  cover: yup.array().notRequired().default([]),
  card: yup.object().notRequired().default({}),
  // ...
  requireVisio: yup.boolean().default(false),
});

export const addSlotSchema = yup
  .object({
    title: yup.string().required(),
    description: yup.string().notRequired(),
    start: yup
      .date("Start date is required")
      .required("Start date is required")
      .nullable(),
    duration: yup.string().required(),
    // ...
    speakers: yup.array().notRequired(),
    sponsors: yup.array().notRequired(),
    // ...
    disableVirtualSpace: yup.bool().nullable(),
    enableMobileNotification: yup.bool().nullable(),
  })
  .default({
    title: "",
    description: "",
    start: new Date(),
    duration: "60",
    speakers: [],
    sponsors: [],
    disableVirtualSpace: false,
    enableMobileNotification: false
  });

export const getDatesFromSlots = (slots = []) => {
  let dateSet = new Set(slots.map((s) => dateToID(s.start)));
  return Array.from(dateSet)?.sort((a, b) => (a > b ? 1 : -1)) || [];
};
