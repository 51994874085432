import "./style.scss";

import { Box, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

import EventButton from "../EventButton";
import SVG from "../Svg";

const EmptyView = ({
  src,
  title,
  desc,
  btnLabel,
  handler,
  isLoading
  // noData,
}) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  // ...
  return (
    <Box className="EmptyView">
      <Box className="EmptyView__svg">
        <SVG src={src} />
      </Box>

      <Box className="EmptyView__content">
        <Typography className="EmptyView__content__title" variant={isMobile ? "h5" : "h3"} gutterBottom>
          {title}
        </Typography>
        <Typography className="EmptyView__content__sub">{desc}</Typography>
        {btnLabel && (
          <EventButton isLoading={isLoading} handler={handler}>
            {btnLabel}
          </EventButton>
        )}
      </Box>
    </Box>
  );
};

export default EmptyView;
