import { Grid, TextField } from "@material-ui/core";
import { useField } from "formik";
import ColorPicker from "material-ui-color-picker";
import React from "react";

const EntryColor = ({
  label,
  name,
  placeholder,
  InputProps,
  // ...
  sx,
  // ...
  xs = 12,
  md = 6
}) => {
  const [field, meta, helpers] = useField({ name });
  // ...
  return (
    <Grid item xs={xs} md={md}>
      <div className="EntryColor">
        <label className="EntryColor__label">{label}</label>
        <div
          className="EntryColor__preview"
          style={{
            backgroundColor: field?.value || ""
          }}
        />
        <div className="EntryColor__value">{field?.value || "#ffffff"}</div>
        <ColorPicker
          className="Entry"
          name={name}
          placeholder={placeholder}
          // ...
          // defaultValue="#000"
          value={field?.value || "#ffffff"}
          onChange={(c) => helpers.setValue(c)}
          onBlur={field.onBlur}
          // ...
          variant="outlined"
          margin="dense"
          fullWidth
          // ...
          InputProps={InputProps}
          InputLabelProps={{ shrink: !!placeholder }}
          error={meta.touched && !!meta.error}
          helperText={meta.touched ? meta.error : ""}
          // ...
          sx={{ ...sx }}
        />
      </div>
    </Grid>
  );
};

export default EntryColor;
