import { useSnackbar } from "notistack";
import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { atom, useRecoilCallback, useRecoilStateLoadable, useRecoilValue } from "recoil";

import HTTP from "../api/http";
import useId from "./useId";

const ATOM = atom({ key: "SPEAKERS", default: [] });

const getSpeakerData = (s) => ({
  firstName: s.firstName,
  lastName: s.lastName,
  description: s.description,
  position: s.position,
  company: s.company,
  email: s.email,
  linkedin: s.linkedin,
  facebook: s.facebook,
  instagram: s.instagram,
  phone: s.phone,
  // ...
  image: s.image,
  // ...
  isModerator: s.isModerator,
  sendEmailNotification: s.sendEmailNotification,
  // ...
  status: s?.status || "pending"
});

export default function useSpeakers() {
  const THE_ID = useId();
  const [data, setData] = useState(useRecoilValue(ATOM));
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // ...
  const [{ state, contents }, setAtom] = useRecoilStateLoadable(ATOM);
  // ...
  const getAll = async () => {
    try {
      const speakers = await HTTP.GET(`/organizations/events/${THE_ID}/getSpeakers`);
      setAtom(speakers);
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Error occured", { variant: "error" });
      return { error: true };
    }
  };
  // ...
  const onCreate = useRecoilCallback(() => async (speaker) => {
    setIsLoading(true);
    setHasError(false);

    let speakerData = getSpeakerData(speaker);
    let sMsg = "Speaker added";
    if (speakerData.sendEmailNotification) sMsg += " and an email invitation is sent";

    try {
      // # Update Speaker
      await HTTP.POST(`/organizations/events/${THE_ID}/createSpeakers`, [speakerData]);
      // ...
      enqueueSnackbar(sMsg, { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Unable to add speaker", { variant: "error" });
      return { error: true };
    }
  });
  const onUpdate = useRecoilCallback(() => async (speakersIni) => {
    setIsLoading(true);
    setHasError(false);

    const speakersData = speakersIni.map((speaker) => {
      let speakerData = getSpeakerData(speaker);
      if (speaker.id) speakerData.id = speaker.id;
      return { ...speakerData };
    });

    try {
      // # Update Event
      await HTTP.POST(`/organizations/events/${THE_ID}/updateSpeakers`, speakersData);
      // ...
      enqueueSnackbar("Speaker updated", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Unable to update speaker", { variant: "error" });
      return { error: true };
    }
  });
  const onRemove = useRecoilCallback(() => async (speakerIds) => {
    setIsLoading(true);
    setHasError(false);

    try {
      // # Delete Speakers
      await HTTP.POST(`/organizations/events/${THE_ID}/deleteSpeakers`, speakerIds);
      // ...
      enqueueSnackbar("Speaker deleted", { variant: "success" });
      // ...
      getAll();
    } catch (err) {
      setIsLoading(false);
      setHasError(true);
      // ...
      enqueueSnackbar("Unable to delete speaker", { variant: "error" });
      return { error: true };
    }
  });
  // ...
  const onResend = async (data) => {
    try {
      await HTTP.POST(`/organizations/events/${THE_ID}/sendSessionBackstageSpeakerLink`, data);
      // ...
      enqueueSnackbar("Email send", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Unable to send email", { variant: "error" });
    }
  };
  // ...
  useEffect(() => {
    if (isLoading) NProgress.start();
    else NProgress.done();
  }, [isLoading]);
  // ...
  useEffect(() => {
    setIsLoading(state === "loading");
    setHasError(state === "hasError");
    // ...
    setData(contents);
  }, [state, contents]);
  // ...
  useEffect(() => {
    getAll();
  }, []);
  // ...
  return {
    isLoading,
    hasError,
    data,
    onCreate,
    onUpdate,
    onRemove,
    onResend
  };
}
